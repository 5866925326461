import React, { Component } from "react";
import { FpoManagementComp } from "../../../component/Fpomanagement/index";
import { connect } from "react-redux";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

class FpoManagementClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <FpoManagementComp history={this.props.history} />;
  }
}

export const FpoManagement = connect(null, null)(FpoManagementClass);
