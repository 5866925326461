import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { NormalButton, DeletePopup } from "component/common";
import {
  getFpoMemberList,
  getMemberById,
  downloadCsvforFpoMembers,
  deleteMember,
  getDownloadBusinessKYMMembersData,
} from "action/FPOact";
import { TableStripedWrapper, CommonPageLoader } from "component/common";
import { getDescriptionData, getSubAdmin } from "service/helperFunctions";
import moment from "moment";
import { ViewMemberPopup } from "./ViewMemberPopup";
export class MemberListClass extends Component {
  state = {
    memberList: [],
    headerDetails: [
      {
        label: "S. No",
      },
      {
        label: "Name",
      },
      {
        label: "Location",
      },
      {
        label: "Contact number",
      },
      {
        label: "Acreage",
      },
      {
        label: "Crops",
      },
      {
        label: "Action",
      },
    ],
    pageMeta: {},
    isLoading: true,
    isMemberView: false,
    isPopupDetailLoading: false,
    memberDetail: {},
    isDelete: false,
    kymModal: false,
    kymMessgae: "",
    isKYMDownloading: false,
    isMemberDownloading: false,
  };

  //get details
  _getDetails = (page) => {
    let { getFpoMemberList, id } = this.props;
    let body = {
      page: page,
    };
    this.setState({ isLoading: true });
    getFpoMemberList(id, body).then((data) => {
      this.setState({
        memberList: data.results,
        pageMeta: data.pageMeta,
        isLoading: false,
      });
    });
  };

  //life cycles
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab === "3") {
        this._getDetails(1);
      }
    }
  }

  handlePageChange = (page) => {
    // page change
    this._getDetails(page);
  };

  downloadCsv = () => {
    let { id } = this.props;
    this.setState({
      isMemberDownloading: true,
    });
    this.props
      .downloadCsvforFpoMembers(id)
      .then((data) => {
        this.downloadFunction(data, "Member");
        this.setState({
          isMemberDownloading: false,
        });
      })
      .catch(() => {
        this.setState({
          isMemberDownloading: false,
        });
      });
  };

  downloadFunction(data, fileName) {
    var a = window.document.createElement("a");
    var blob = new Blob([data], {
      type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(
        blob,
        `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`
      );
    } else {
      a.href = window.URL.createObjectURL(blob);
      // supply your own fileName here...
      a.download = `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  downloadKYMData = () => {
    let { id } = this.props;
    this.setState({
      isKYMDownloading: true,
    });
    this.props
      .getDownloadBusinessKYMMembersData(id)
      .then(({ data }) => {
        this.setState({
          kymMessgae: data,
          kymModal: true,
          isKYMDownloading: false,
        });
      })
      .catch(() => {
        this.setState({
          isKYMDownloading: false,
        });
      });
  };

  kymToggle = () => {
    this.setState({ kymModal: !this.state.kymModal });
  };

  toggle = (name, id) => {
    this.setState(
      (prevState) => ({
        [name]: !prevState[name],
      }),
      () => {
        if (id !== undefined) {
          this.setState({ isPopupDetailLoading: true });
          this.props.getMemberById({ id }).then((data) => {
            this.setState({
              isPopupDetailLoading: false,
              memberDetail: data,
            });
          });
        }
      }
    );
  };

  onDelete = () => {
    let { id } = this.props;
    this.props.deleteMember(id).then((data) => {
      this._getDetails(1);
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  render() {
    let {
      memberList,
      headerDetails,
      pageMeta,
      isLoading,
      isMemberView,
      isPopupDetailLoading,
      memberDetail,
      isDelete,
      kymModal,
      kymMessgae,
      isKYMDownloading,
      isMemberDownloading,
    } = this.state;
    let subAdminStatus = getSubAdmin();

    return (
      <div className="member-list">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h5 className="text-green-18 fs-20 line-height-25 font-weight-bold">
              Members list
            </h5>
          </div>
          <div className="d-flex align-items-center">
            {memberList.length > 0 && !subAdminStatus && (
              <NormalButton
                mainbg={true}
                className="p-2 mr-4"
                label="Delete"
                onClick={() => this.toggleDelete()}
              />
            )}
            <h5
              className="text-green-18 fs-20 line-height-25 font-weight-bold text-underline cursor-pointer mr-3"
              onClick={() => this.downloadKYMData()}
              id="csv-download"
            >
              {isKYMDownloading ? "Downloading..." : "Download KYM dump"}
            </h5>
            {memberList.length > 0 && (
              <h5
                className="text-green-18 fs-20 line-height-25 font-weight-bold text-underline cursor-pointer"
                onClick={() => this.downloadCsv()}
                id="csv-download"
              >
                {isMemberDownloading
                  ? "Downloading..."
                  : "Download Member profile"}
              </h5>
            )}
          </div>
        </div>
        {isLoading ? (
          <>
            {/* Loading */}
            <CommonPageLoader />
          </>
        ) : (
          <>
            {memberList.length !== 0 ? (
              <TableStripedWrapper
                headerDetails={headerDetails}
                pageMeta={pageMeta}
                queryHandler={this.handlePageChange}
              >
                {memberList.map((item, index) => {
                  let {
                    memberFirstName = "",
                    memberLastName = "",
                    village = "-",
                    phone,
                    memberID = "",
                    memberfarminfo = {},
                  } = item;
                  let userName = memberFirstName + " " + memberLastName;
                  return (
                    <tr key={index}>
                      <td>
                        <div className="px-3">{index + 1}</div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {userName ? getDescriptionData(userName, 20) : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {village ? village : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {phone ? phone : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {memberfarminfo && memberfarminfo.plotAcreage
                            ? memberfarminfo.plotAcreage
                            : "0"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {memberfarminfo !== null &&
                          memberfarminfo !== undefined
                            ? (memberfarminfo.rabicropmapping &&
                                memberfarminfo.rabicropmapping.length) +
                              (memberfarminfo.kharifcropmapping &&
                                memberfarminfo.kharifcropmapping.length) +
                              (memberfarminfo.livecropmapping &&
                                memberfarminfo.livecropmapping.length)
                            : 0}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          <i
                            className="icon-eye mr-3 cursor-pointer fs-20"
                            onClick={() =>
                              this.toggle("isMemberView", memberID)
                            }
                          ></i>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </TableStripedWrapper>
            ) : (
              <p className="py-5 my-5 fs-20 text-center text-black-50 line-height-23 fw-700">
                {"No records found !!!"}
              </p>
            )}
          </>
        )}
        <ViewMemberPopup
          isOpen={isMemberView}
          toggle={() => this.toggle("isMemberView")}
          isLoading={isPopupDetailLoading}
          memberDetail={memberDetail}
        />
        <DeletePopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          handleChange={this.onDelete}
        />
        <Modal
          isOpen={kymModal}
          toggle={this.kymToggle}
          className="modal-dialog-centered content-mangement"
          size="md"
        >
          <ModalBody className="p-0">
            <div className="d-flex justify-content-end">
              <i
                className="icon-close fs-16 cursor-pointer"
                onClick={this.kymToggle}
              ></i>
            </div>
            <div className="">
              <p className="text-green-18 fs-22 line-height-31 font-weight-bold text-center">
                Information
              </p>
              <div className="d-flex justify-content-center my-3">
                <div className="fs-20 text-center p-3">{kymMessgae}</div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFpoMemberList,
      getMemberById,
      downloadCsvforFpoMembers,
      deleteMember,
      getDownloadBusinessKYMMembersData,
    },
    dispatch
  );
};

let component = MemberListClass;

export const MemberList = connect(null, mapDispatchToProps)(component);
