import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getFpoCompanyKYC,
  getFpoStuatary,
  getFpodirectdoc,
  getFpofinancial,
  getFpocreditdata,
  getCompanyKycBulkdownload,
  getFinancialBulkdownload,
  getDirectdocBulkdownload,
  getcreditdataBulkdownload,
  getstatutoryBulkdownload,
} from "action/FPOact";
import { CommonPageLoader } from "component/common";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { UploadComponent } from "./UploadComponent";
import classnames from "classnames";

import pdf from "assets/svg/Documents/pdf.svg";

export class FPOdocumentsClass extends Component {
  state = {
    companyKycDetails: [],
    StautaryDetails: [],
    directerDocs: [],
    financialDocs: [],
    creditDataDocs: [],
    isLoading: false,
    documentsActiveTab: "1",
    bulkDownloadLink: "",
  };

  //get details
  _getDetails = (page) => {
    let { id } = this.props;
    let { documentsActiveTab } = this.state;
    let body = {
      page: page,
    };
    this.setState({ isLoading: true });
    if (documentsActiveTab === "1") {
      this._getFpoCompanyKYC(id, body);
    } else if (documentsActiveTab === "2") {
      this._getFpoStuatary(id, body);
    } else if (documentsActiveTab === "3") {
      this._getFpodirectdoc(id, body);
    } else if (documentsActiveTab === "4") {
      this._getFpofinancial(id, body);
    } else if (documentsActiveTab === "5") {
      this._getFpocreditdata(id, body);
    }
  };

  _getFpoCompanyKYC = (id, body) => {
    let { getFpoCompanyKYC } = this.props;
    getFpoCompanyKYC(id, body).then((data) => {
      this.setState({
        companyKycDetails: data,
        isLoading: false,
      });
    });
  };

  _getFpoStuatary = (id, body) => {
    let { getFpoStuatary } = this.props;
    getFpoStuatary(id, body).then((data) => {
      this.setState({
        StautaryDetails: data,
        isLoading: false,
      });
    });
  };

  _getFpodirectdoc = (id, body) => {
    let { getFpodirectdoc } = this.props;
    getFpodirectdoc(id, body).then((data) => {
      this.setState({
        directerDocs: data,
        isLoading: false,
      });
    });
  };

  _getFpofinancial = (id, body) => {
    let { getFpofinancial } = this.props;
    getFpofinancial(id, body).then((data) => {
      this.setState({
        financialDocs: data,
        isLoading: false,
      });
    });
  };

  _getFpocreditdata = (id, body) => {
    let { getFpocreditdata } = this.props;
    getFpocreditdata(id, body).then((data) => {
      this.setState({
        creditDataDocs: data,
        isLoading: false,
      });
    });
  };

  //life cycles
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab === "2") {
        this._getDetails(1);
      }
    }
  }

  toggle = (tab) => {
    let { documentsActiveTab } = this.state;
    if (documentsActiveTab !== tab) {
      this.setState(
        {
          documentsActiveTab: tab,
        },
        () => {
          this._getDetails(1);
        }
      );
    }
  };

  getCsvUrl = () => {
    let { id } = this.props;
    let { documentsActiveTab } = this.state;
    let { getDownloadAction } = "";
    if (documentsActiveTab == 1) {
      getDownloadAction = this.props.getCompanyKycBulkdownload;
    } else if (documentsActiveTab == 2) {
      getDownloadAction = this.props.getstatutoryBulkdownload;
    } else if (documentsActiveTab == 3) {
      getDownloadAction = this.props.getDirectdocBulkdownload;
    } else if (documentsActiveTab == 4) {
      getDownloadAction = this.props.getFinancialBulkdownload;
    } else {
      getDownloadAction = this.props.getcreditdataBulkdownload;
    }

    let body = {
      fpoId: id,
      docId: "",
    };

    if (getDownloadAction !== "") {
      getDownloadAction(body).then((data) => {
        this.setState(
          {
            bulkDownloadLink:
              "https://preprod.fpogateway.com/fpomgmt/api/v1/download/zip?zipFileName=" +
              data.zipFileName,
          },
          () => {
            document.getElementById("csv-download").click();
            this.setState({ bulkDownloadLink: "" });
          }
        );
      });
    }
  };

  render() {
    let {
      companyKycDetails,
      isLoading,
      documentsActiveTab,
      StautaryDetails,
      directerDocs,
      financialDocs,
      creditDataDocs,
      bulkDownloadLink,
    } = this.state;

    let { id } = this.props;

    return (
      <div className="document-list">
        <h5 className="text-green-18 fs-20 line-height-25 font-weight-bold mb-4">
          Documents
        </h5>
        <div className="document-navlink">
          <div className="filled-tabs">
            <div className="tabs-block mb-5">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: documentsActiveTab === "1",
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Company KYC
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: documentsActiveTab === "2",
                    })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    Statutory lincense
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: documentsActiveTab === "3",
                    })}
                    onClick={() => {
                      this.toggle("3");
                    }}
                  >
                    Director & Managemnet KYC
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: documentsActiveTab === "4",
                    })}
                    onClick={() => {
                      this.toggle("4");
                    }}
                  >
                    Financials
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: documentsActiveTab === "5",
                    })}
                    onClick={() => {
                      this.toggle("5");
                    }}
                  >
                    Credit data
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="d-flex justify-content-end">
              {bulkDownloadLink === "" ? (
                <span
                  className="text-black-50 font-weight-bold fs-18 text-underline cursor-pointer mb-4"
                  onClick={() => this.getCsvUrl()}
                >
                  {"Generate zip"}
                </span>
              ) : (
                <a
                  id="csv-download"
                  className="text-black-50 font-weight-bold fs-18 text-underline cursor-pointer mb-4"
                  href={bulkDownloadLink}
                  download
                >
                  {"Generate zip"}
                </a>
              )}
            </div>
            {isLoading ? (
              <>
                {/* Loading */}
                <CommonPageLoader />
              </>
            ) : (
              <>
                <div className="">
                  <TabContent activeTab={documentsActiveTab}>
                    <TabPane tabId="1">
                      <div className="row">
                        {companyKycDetails.map((documentDetail) => (
                          <div className="col-4 mb-5">
                            <div className="document-card-margin">
                              <div className="d-flex align-items-center mb-2">
                                <img className="mr-3" src={pdf} alt="pdf.svg" />
                                <div className="document-title">
                                  {documentDetail.documentName}
                                </div>
                              </div>
                              <div className="document-body mb-3">
                                {documentDetail.description}
                              </div>
                              <div>
                                <UploadComponent
                                  documentDetail={documentDetail}
                                  documentTitle={documentDetail.documentName}
                                  id={documentDetail.companyKycId}
                                  documents={documentDetail.companyKYCs}
                                  activeTab={documentsActiveTab}
                                  fpoId={id}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row">
                        {StautaryDetails.map((documentDetail) => (
                          <div className="col-4 mb-5">
                            <div className="document-card-margin">
                              <div className="d-flex align-items-center mb-2">
                                <img className="mr-3" src={pdf} alt="pdf.svg" />
                                <div className="document-title">
                                  {documentDetail.documentName}
                                </div>
                              </div>
                              <div className="document-body mb-3">
                                {documentDetail.description}
                              </div>
                              <div>
                                <UploadComponent
                                  documentDetail={documentDetail}
                                  documentTitle={documentDetail.documentName}
                                  id={documentDetail.statutoryDocumentId}
                                  documents={documentDetail.statutoryLicenses}
                                  activeTab={documentsActiveTab}
                                  fpoId={id}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div className="row">
                        {directerDocs.map((documentDetail) => (
                          <div className="col-4 mb-5">
                            <div className="document-card-margin">
                              <div className="d-flex align-items-center mb-2">
                                <img className="mr-3" src={pdf} alt="pdf.svg" />
                                <div className="document-title">
                                  {documentDetail.documentName}
                                </div>
                              </div>
                              <div className="document-body mb-3">
                                {documentDetail.description}
                              </div>
                              <div>
                                <UploadComponent
                                  documentDetail={documentDetail}
                                  documentTitle={documentDetail.documentName}
                                  id={documentDetail.directManagementDocId}
                                  documents={documentDetail.directManagements}
                                  activeTab={documentsActiveTab}
                                  fpoId={id}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div className="row">
                        {financialDocs.map((documentDetail) => (
                          <div className="col-4 mb-5">
                            <div className="document-card-margin">
                              <div className="d-flex align-items-center mb-2">
                                <img className="mr-3" src={pdf} alt="pdf.svg" />
                                <div className="document-title">
                                  {documentDetail.documentName}
                                </div>
                              </div>
                              <div className="document-body mb-3">
                                {documentDetail.description}
                              </div>
                              <div>
                                <UploadComponent
                                  documentDetail={documentDetail}
                                  documentTitle={documentDetail.documentName}
                                  id={documentDetail.financialDocId}
                                  documents={documentDetail.financials}
                                  activeTab={documentsActiveTab}
                                  fpoId={id}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div className="row">
                        {creditDataDocs.map((documentDetail) => (
                          <div className="col-4 mb-5">
                            <div className="document-card-margin">
                              <div className="d-flex align-items-center mb-2">
                                <img className="mr-3" src={pdf} alt="pdf.svg" />
                                <div className="document-title">
                                  {documentDetail.documentName}
                                </div>
                              </div>
                              <div className="document-body mb-3">
                                {documentDetail.description}
                              </div>
                              <div>
                                <UploadComponent
                                  documentDetail={documentDetail}
                                  documentTitle={documentDetail.documentName}
                                  fpoId={id}
                                  id={documentDetail.creditDocId}
                                  documents={documentDetail.creditData}
                                  activeTab={documentsActiveTab}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFpoCompanyKYC,
      getFpoStuatary,
      getFpodirectdoc,
      getFpofinancial,
      getFpocreditdata,
      getCompanyKycBulkdownload,
      getFinancialBulkdownload,
      getDirectdocBulkdownload,
      getcreditdataBulkdownload,
      getstatutoryBulkdownload,
    },
    dispatch
  );
};

let component = FPOdocumentsClass;

export const FPOdocuments = connect(null, mapDispatchToProps)(component);
