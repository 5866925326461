import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import "./style.scss"
import moment from "moment"
import { bindActionCreators } from 'redux';
import {
    getCategoryList,
} from 'action/CourseAct';
import VideoPlayer from 'react-video-js-player';

export class VideoPreviewPopupClass extends Component {

    state = {
        categoryOptions: []
    }

    onPlayerReady(player, duration = 5) {
        player.currentTime(duration)
        this.player = player;
    }

    _getCategoryDetails = () => {
        this.props.getCategoryList().then((data) => {
            let categoryList = [];
            data.map((list) => {
                categoryList.push({
                    label: list.categoryName,
                    value: list.courseCategoryId,
                });
            });
            this.setState({ categoryOptions: categoryList });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.isOpen) {
                this._getCategoryDetails()
            }
        }
    }

    render() {
        let {
            className = 'modal-dialog-centered video-popup',
            isOpen = true,
            toggle,
            formFields
        } = this.props;

        let {
            title = '',
            description = '',
            video = '',
            durationSeconds = '',
            durationHours = '',
            durationMinutes = '',
            category = '',
            trendingvideoFileUrl = '',
            thumbnailfile = '',
            publishDate = ''
        } = formFields

        let {
            categoryOptions
        } = this.state

        let categoryNameArray = [], categoryName = '';

        categoryNameArray = categoryOptions.length !== 0 ? categoryOptions.filter(s => s.value == category) : []

        categoryName = categoryNameArray.length !== 0 ? categoryNameArray[0].label : ''

        return (
            <>
                <Modal isOpen={isOpen} toggle={toggle} className={className} size="lg">
                    <ModalBody className='p-0'>
                        <div className='d-flex justify-content-beween w-100'>
                            <div className="w-100">
                                <h5 className="text-green-18 fs-22 fw-700 line-height-28">Trending video details</h5>
                            </div>
                            <i
                                className='icon-close fs-16 cursor-pointer'
                                onClick={toggle}
                            ></i>
                        </div>
                        <div className="trending-video-content">
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="fs-20 fw-700 line-height-34 text-black-1c">{title}</h5>
                                    <h5 className="fs-16 line-height-27 text-black-50"
                                        dangerouslySetInnerHTML={{ __html: description }}
                                    ></h5>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <VideoPlayer
                                        controls={true}
                                        poster={thumbnailfile}
                                        src={video ? video : trendingvideoFileUrl}
                                        onReady={this.onPlayerReady.bind(this)}
                                        className="w-100"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="fs-20 fw-700 line-height-34 text-green-18">{categoryName}</h5>
                                </div>
                            </div>
                            <div className="row d-flex align-items-center">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-5 my-auto">
                                            <p className="text-black-50 fs-18 fw-600 mb-0">
                                                {'Publish date :'}
                                            </p>
                                        </div>
                                        <div className="col-7 my-auto">
                                            <p className="fs-16 fw-500 text-black-50 mb-0">
                                                {moment(publishDate).format('DD/MM/YYYY')}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-5 my-auto">
                                            <p className="text-black-50 fs-18 fw-600 mb-0">
                                                {'Duration :'}
                                            </p>
                                        </div>
                                        <div className="col-7 my-auto">
                                            <p className="fs-16 fw-500 text-black-50 mb-0">
                                                {durationHours} hr {durationMinutes} min {durationSeconds} sec
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal >
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getCategoryList,
        },
        dispatch
    );
};

let component = VideoPreviewPopupClass;

export const VideoPreviewPopup = connect(null, mapDispatchToProps)(component);