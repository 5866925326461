import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { TableWrapper, CommonPageLoader, DeletePopup } from 'component/common';

import moment from "moment"

import { getTrendingVideoList, deleteVideosById } from 'action/CourseAct';
import { VideoPreviewPopup } from "../VideoPreviewPopup"
import { getDescriptionData } from 'service/helperFunctions';
export class TrendingVideoListClass extends Component {
   state = {
      headerDetails: [
         {
            label: 'Video id ',
         },
         {
            label: 'Video image',
         },
         {
            label: 'Video title',
         },
         {
            label: 'Category',
         },
         {
            label: 'Duration',
         },
         {
            label: 'Publication date',
         },
         {
            label: 'Action',
         },
      ],
      pageMeta: {},
      trendingVideoList: [],
      isloading: false,
      isDeletePopup: false,
      trendingVideoId: '',
      ispreviewPopup: false,
      formFields: {}
   };

   //Lifecycles
   componentDidMount = () => {
      this._getTrendingVideoList(1);
   };

   componentDidUpdate(prevProps) {
      if (this.props.activeTab == 2) {
         this.props.filter !== prevProps.filter &&
            this._getTrendingVideoList(1);
         this.props.searchValue !== prevProps.searchValue &&
            this._getTrendingVideoList(1);
         this.props.sortBy !== prevProps.sortBy &&
            this._getTrendingVideoList(1);
      }
   }

   //get trending video list
   _getTrendingVideoList = (page) => {
      let { filter, filterType, searchValue, sortBy } = this.props;
      this.setState({ isloading: true });
      this.props
         .getTrendingVideoList({
            page,
            filter,
            filterType,
            search: searchValue,
            sortBy,
         })
         .then((data) => {
            let results = data.results;
            this.setState({
               pageMeta: data.pageMeta,
               trendingVideoList: results,
               isloading: false,
            });
         });
   };

   handlePageChange = (page) => {
      //page change
      this._getTrendingVideoList(page);
   };

   //handle delete
   handleDelete = () => {
      let { trendingVideoId } = this.state
      this.props.deleteVideosById({ videoId: parseInt(trendingVideoId) }).then(() => {
         this.setState({ trendingVideoId: '' })
         this._getTrendingVideoList(1)
         this.toggleDelete('isDeletePopup')
      });
   };

   toggleDelete = (name, id) => {
      this.setState((prevState) => ({
         [name]: !prevState[name],
         trendingVideoId: id
      }));
   };

   togglePreview = (name, formFields = {}) => {
      this.setState((prevState) => ({
         [name]: !prevState[name],
         formFields
      }));
   };

   render() {
      const {
         headerDetails,
         pageMeta = {},
         trendingVideoList,
         isloading,
         isDeletePopup,
         ispreviewPopup,
         formFields
      } = this.state;

      return (
         <>
            {isloading ? (
               <>
                  {/* Loader */}
                  <CommonPageLoader />
               </>
            ) : (
                  <div className='table-container w-100 mt-4 pt-1'>
                     {trendingVideoList.length !== 0 ? (
                        <TableWrapper
                           headerDetails={headerDetails}
                           queryHandler={this.handlePageChange}
                           pageMeta={pageMeta}
                        >
                           {trendingVideoList.map((item, index) => {
                              let {
                                 title,
                                 category,
                                 durationHours,
                                 durationMinutes,
                                 durationSeconds,
                                 thumbnailImageUrl,
                                 trendingvideoId,
                                 publishDate
                              } = item;

                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {trendingvideoId}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <img
                                          src={thumbnailImageUrl}
                                          alt=""
                                          height="75"
                                          width="125"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {getDescriptionData(title, 75)}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {category.categoryName}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {durationHours} hr {durationMinutes} min{" "}
                                        {durationSeconds} sec
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {moment(publishDate).format(
                                          "DD MMM YYYY"
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <i
                                          className="icon-eye fs-20 cursor-pointer"
                                          onClick={() =>
                                            this.togglePreview(
                                              "ispreviewPopup",
                                              item
                                            )
                                          }
                                        ></i>
                                        <i
                                          className="icon-edit fs-20 pl-3 cursor-pointer"
                                          onClick={() => {
                                            this.props.history.push({
                                              pathname: `addCourse/addVideo/${trendingvideoId}`,
                                            });
                                          }}
                                        ></i>
                                        <i
                                          className="icon-Delete fs-20 pl-3 cursor-pointer"
                                          onClick={() => {
                                            this.toggleDelete(
                                              "isDeletePopup",
                                              trendingvideoId
                                            );
                                          }}
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="trans-bg">
                                    <td
                                      className="empty-height"
                                      height="12"
                                    ></td>
                                  </tr>
                                </React.Fragment>
                              );
                           })}
                        </TableWrapper>
                     ) : (
                           <div className='text-center fs-18 text-black py-5'>
                              No records found !!!
                           </div>
                        )}
                  </div>
               )}
            <DeletePopup
               isOpen={isDeletePopup}
               toggle={() => this.toggleDelete('isDeletePopup')}
               handleChange={() => this.handleDelete()}
            />
            <VideoPreviewPopup
               isOpen={ispreviewPopup}
               toggle={() => this.togglePreview('ispreviewPopup')}
               formFields={formFields}
            />
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         getTrendingVideoList,
         deleteVideosById,
      },
      dispatch
   );
};

let component = TrendingVideoListClass;

export const TrendingVideoList = connect(null, mapDispatchToProps)(component);
