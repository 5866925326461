import React from "react";
import "./style.scss";

import PropTypes from "prop-types";

export const InputSearch = ({
  inputClassName = "form-control fs-16",
  className = "",
  onChange,
  placeholder = "Search",
  value,
  name,
}) => {
  return (
    <div className={`search-box position-relative ${className}`}>
      <input
        className={inputClassName}
        placeholder={placeholder}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        value={value}
        name={name}
      />
      <div className="search-icon">
        <i className="icon-search d-flex align-items-center h-100 justify-content-center"></i>
      </div>
    </div>
  );
};

InputSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
