import { authType } from "../service/actionType";

import { authApi } from "../service/apiVariables";

// common login
export const login =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.adminLogin, body })
        .then(({ message, data }) => {
          localStorage.setItem("AuthToken", data.token);
          localStorage.setItem("userRoleId", data.userRoles.userRoleId);
          localStorage.setItem(
            "isSubAdmin",
            data.isSubAdmin ? data.isSubAdmin : false
          );
          resolve(data);
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//forgot password link
export const forgotPasswordLink =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.forgotPasswordLink, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//forgot password validation
export const forgotPasswordTokeValidation =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.forgotPasswordTokeValidation, isfortgotPass: true })
        .then(({ status, message }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(status);
        })
        .catch(({ message, status }) => {
          resolve(status);
          Toast({ type: "error", message });
        });
    });
  };

//change Password
export const changePassword =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.changePassword, body, isfortgotPass: true })
        .then(({ data, message }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message, status }) => {
          resolve(status);
          Toast({ type: "error", message });
        });
    });
  };

//change Password After Login
export const changePasswordAfterLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.changePasswordAfterLogin, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message, status }) => {
          resolve(status);
          Toast({ type: "error", message });
        });
    });
  };
