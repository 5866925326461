import React, { Component } from 'react';
import { NormalInput, PasswordStrengthMeter, NormalButton } from "component/common"
import { connect } from "react-redux";
import SimpleReactValidator from 'simple-react-validator';
import { history } from "service/helpers"
import { encryptData } from "service/helperFunctions"
import { changePasswordAfterLogin } from "action/AuthAct"
import { bindActionCreators } from 'redux';
import "./style.scss"
class ChangePasswordPostLoginClass extends Component {

    state = {
        newPassword: '',
        currentPassword: '',
        reenterPassword: ''
    }

    //validation
    validator = new SimpleReactValidator({
        element: (message) => (
            <span className='error-message text-danger fs-14'>{message}</span>
        ),
        autoForceUpdate: this,
        validators: {
            password: {
                message: "The :attribute must be a valid format.",
                rule: (val, params, validator) => {
                    return (
                        validator.helpers.testRegex(
                            val,
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
                        ) && params.indexOf(val) === -1
                    );
                },
                messageReplace: (message, params) =>
                    message.replace("", this.helpers.toSentence(params)),
                required: true,
            },
            checkPassword: {
                message: 'Given :attribute does not match',
                rule: function (val, params) {
                    return val === params[0];
                },
            },
        },
    });

    handleInput = ({ target: { name, value } }) => {
        this.setState({
            [name]: value,
        });
    };

    handleSubmit = () => {
        if (this.validator.allValid()) {

            let { changePasswordAfterLogin } = this.props

            let {
                newPassword,
                currentPassword,
                reenterPassword
            } = this.state

            let body = {
                "currentPassword": encryptData(currentPassword),
                "newPassword": encryptData(newPassword),
                "confirmNewPassword": encryptData(reenterPassword)
            }

            changePasswordAfterLogin(body).then(() => {
                this.validator.hideMessages()
                this.setState({
                    newPassword: '',
                    currentPassword: '',
                    reenterPassword: ''
                })
            })

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        let {
            currentPassword,
            newPassword,
            reenterPassword
        } = this.state
        return (
            <div className="change-password">
                <div className='d-flex cursor-pointer pt-2 mt-1 mb-4 back-area'
                    onClick={() => history.goBack()}
                >
                    <i className='icon-arrow-left fs-24'></i>
                    <h5 className='heading-20 pl-4'>Change password</h5>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <NormalInput
                            label='Current password'
                            type='text'
                            onChange={this.handleInput}
                            value={currentPassword}
                            placeholder='Enter here'
                            name='currentPassword'
                            required={true}
                        />
                        {this.validator.message(
                            'current password',
                            currentPassword,
                            'required|max:20'
                        )}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <NormalInput
                            label='New password'
                            type='text'
                            onChange={this.handleInput}
                            value={newPassword}
                            placeholder='Enter here'
                            name='newPassword'
                            required={true}
                        />
                        {this.validator.message(
                            'password',
                            newPassword,
                            'required|min:7|max:15|password'
                        )}
                    </div>
                    <div className='col-6'>
                        <NormalInput
                            label='Re-enter new password'
                            type='text'
                            onChange={this.handleInput}
                            value={reenterPassword}
                            placeholder='Enter here'
                            name='reenterPassword'
                            required={true}
                        />
                        {this.validator.message(
                            'password',
                            reenterPassword,
                            `required|checkPassword:${newPassword}`
                        )}
                        <PasswordStrengthMeter data={reenterPassword} />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-2 mr-auto">
                        <NormalButton
                            label="Submit"
                            mainbg={true}
                            className="px-3 py-2 w-100 mx-auto fs-14 line-height-26"
                            onClick={() => this.handleSubmit()}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        changePasswordAfterLogin
    }, dispatch);
};
export const ChangePasswordPostLogin = connect(null, mapDispatchToProps)(ChangePasswordPostLoginClass);
