import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { getDashboardDetails } from "action/dashboard";
import { allState, getDistrictApiwithState } from "action/FPOact";
import ReactDatamaps from "react-india-states-map";
import {
  NormalSelect,
  NormalDate,
  CommonPageLoader,
  NormalButton,
} from "component/common";
import { MultiSelect } from "react-multi-select-component";
import {
  numberFormatChange,
  numDifferentiation,
} from "service/helperFunctions";

const programLists = [
  { label: "LHFPO", value: "LHFPO" },
  { label: "BMGF", value: "BMGF" },
  { label: "FPOnEXT", value: "FPOnEXT" },
  { label: "FPO 360", value: "FPO_360" },
  { label: "FPO Bazaar", value: "FPO_Bazaar" },
  // { label: "Sam IPL", value: "Sam_IPL" },
];

const programListsOne = [
  { label: "LHFPO", value: "LHFPO" },
  { label: "BMGF", value: "BMGF" },
  { label: "FPOnEXT", value: "FPOnEXT" },
  { label: "FPO 360", value: "FPO_360" },
];

const DashboardHomeClass = (props) => {
  const {
    getDashboardDetailsApi,
    allStateApi,
    getDistrictApiwithStateApi,
    homeActiveTab,
  } = props;
  const [dashboardData, setDashboardData] = useState();
  const [stateOptions, setStateOptions] = useState([]);
  const [stateCode, setStateCode] = useState();
  const [districeOptions, setDistrictOptions] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [loading, setloading] = useState(false);
  const [loggedInID, setloggedInID] = useState("");
  const [selected, setSelected] = useState([]);
  const [activeState, setactiveState] = useState({
    value: selectedState,
    name: selectedState,
  });
  const [sortingData, setSortingData] = useState({
    program: "",
    state: "",
    district: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    let id = localStorage.getItem("userRoleId");
    setloggedInID(id);
  }, []);

  const stateOnClick = useCallback(
    (value, name) => {
      for (let i = 0; i < stateOptions?.length; i++) {
        if (name === stateOptions[i].label) {
          sortingData.state = stateOptions[i].value.toString();
          activeState.value = stateOptions[i].label;
          activeState.name = stateOptions[i].label;
          setSelectedState(stateOptions[i].label);
          setStateCode(stateOptions[i].value);
        }
      }
      setactiveState({ value, name });
      sortingData.district = "";
    },
    [sortingData.state, stateOptions, activeState, sortingData.district]
  );

  const stateApiCall = useCallback(() => {
    allStateApi().then((data) => {
      let stateData = [{ label: "All", value: "All" }];
      data.forEach((list) => {
        stateData.push({
          label: list.stateName,
          value: list.stateId,
        });
        setStateOptions([...stateData]);
      });
    });
  }, [allStateApi]);

  useEffect(() => {
    if (homeActiveTab === "Summary") {
      stateApiCall();
    }
  }, [stateApiCall, homeActiveTab]);

  useEffect(() => {
    for (let i = 0; i < stateOptions?.length; i++) {
      if (parseInt(sortingData?.state) === stateOptions[i].value) {
        activeState.value = stateOptions[i].label;
        activeState.name = stateOptions[i].label;
        setSelectedState(stateOptions[i].label);
      }
    }
  }, [stateOptions, sortingData, activeState]);

  const handleInput = (e) => {
    const { name, value } = e.target || e || {};
    if (name === "state") {
      sortingData.district = "";
      setSortingData(
        { ...sortingData, [name]: value },
        getDistrictApiwithStateMethod(value)
      );
    } else if (name === "district") {
      setSortingData({ ...sortingData, [name]: value });
    }
    setSortingData({ ...sortingData, [name]: value });
  };

  const getDistrictApiwithStateMethod = useCallback(
    (value) => {
      getDistrictApiwithStateApi({ id: value }).then((data) => {
        let districeData = [{ label: "All", value: "All" }];
        data.forEach((list) => {
          districeData.push({
            label: list.districtName,
            value: list.districtId,
          });
          setDistrictOptions([...districeData]);
        });
      });
    },
    [getDistrictApiwithStateApi]
  );

  useEffect(() => {
    if (stateCode && homeActiveTab === "Summary") {
      getDistrictApiwithStateMethod(stateCode);
    }
  }, [getDistrictApiwithStateMethod, homeActiveTab, stateCode]);

  useEffect(() => {
    if (
      sortingData?.startDate === null ||
      sortingData?.startDate === "" ||
      sortingData?.startDate === undefined
    ) {
      sortingData.startDate = "";
      sortingData.endDate = "";
    }
  }, [sortingData.startDate, sortingData.endDate]);

  useEffect(() => {
    if (sortingData?.endDate === null) {
      sortingData.endDate = "";
    }
  }, [sortingData.endDate]);

  const dashboardApiCall = useCallback(() => {
    setloading(true);
    if (loggedInID) {
      let body = {
        program:
          loggedInID === "13"
            ? JSON.stringify(["FPO_Bazaar"])
            : sortingData?.program !== ""
            ? sortingData?.program
            : "",
        state: sortingData?.state !== "" ? sortingData?.state : "",
        district: sortingData?.state !== "" ? sortingData?.district : "",
        startDate:
          sortingData?.startDate !== ""
            ? moment(sortingData?.startDate).format("YYYY-MM-DD")
            : "",
        endDate:
          sortingData?.endDate !== ""
            ? moment(sortingData?.endDate).format("YYYY-MM-DD")
            : "",
      };
      if (sortingData?.program === "All") {
        body.program = "";
      }
      if (sortingData?.state === "All") {
        body.state = "";
      }
      if (sortingData?.district === "All") {
        body.district = "";
      }
      if (loggedInID !== "13") {
        if (sortingData?.program?.length > 0) {
          body.program = JSON.stringify(body.program);
        } else {
          body.program = "";
        }
      }
      getDashboardDetailsApi(body)
        .then((data) => {
          setDashboardData(data?.data);
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    }
  }, [getDashboardDetailsApi, sortingData, sortingData?.state, loggedInID]);

  useEffect(() => {
    if (homeActiveTab === "Summary") {
      dashboardApiCall();
    }
  }, [dashboardApiCall, homeActiveTab]);

  useEffect(() => {
    if (sortingData?.state === "All") {
      setactiveState({ value: undefined, name: undefined });
    }
  }, [sortingData]);

  const resetFilters = () => {
    setSortingData({
      program: "",
      state: "",
      district: "",
      startDate: "",
      endDate: "",
    });
    setSelected([]);
    setDistrictOptions([]);
    setactiveState({ value: undefined, name: undefined });
  };

  useEffect(() => {
    let prodramValue = [];
    selected.forEach((val) => prodramValue.push(val.value));
    setSortingData((prevState) => {
      return {
        ...prevState,
        program: prodramValue,
      };
    });
  }, [selected]);

  const averageFIG =
    dashboardData?.figMembers?.totalFig / dashboardData?.figMembers?.totalFpo;

  return (
    <React.Fragment>
      <div className="dashboard-home">
        <div className="d-flex align-items-start w-100 justify-content-between mt-5 mb-5 flex-wrap">
          {loggedInID === "13" ? null : (
            <div className="d-flex flex-column">
              <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">
                Program
              </div>
              <MultiSelect
                options={loggedInID === "14" ? programListsOne : programLists}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select",
                  allItemsAreSelected: "All",
                }}
              />
            </div>
          )}
          <NormalSelect
            label="State"
            placeholder="Select"
            name="state"
            value={sortingData?.state}
            options={stateOptions}
            handleChange={handleInput}
          />
          <NormalSelect
            label="District"
            placeholder="Select"
            name="district"
            value={sortingData?.district}
            options={districeOptions}
            handleChange={handleInput}
          />
          <NormalDate
            name="startDate"
            placeholder="YYYY-MM-DD"
            mindate={false}
            maxdate={false}
            timeInput={false}
            label={"Date Range"}
            value={sortingData?.startDate}
            onChange={handleInput}
            isIcon={true}
          />
          <NormalDate
            name="endDate"
            placeholder="YYYY-MM-DD"
            mindate={false}
            maxdate={false}
            timeInput={false}
            value={sortingData?.endDate}
            onChange={handleInput}
            isIcon={true}
            disabled={
              sortingData?.startDate === "" ||
              sortingData?.startDate === undefined
            }
            className="date-class form-control w-100 mt-32"
          />
        </div>
        <div className="reset-button">
          <NormalButton
            label="Reset"
            mainbg={true}
            onClick={() => {
              resetFilters();
            }}
          />
        </div>
        {loading ? (
          <CommonPageLoader />
        ) : (
          <>
            <h1>Gateway</h1>
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>Number of FPOs</h2>
                      </div>
                      <div className="sub-title">
                        <h4>{numberFormatChange(dashboardData?.noOfFpos)}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>Membership Base</h2>
                      </div>
                      <div className="sub-title">
                        <h3>
                          {numberFormatChange(
                            dashboardData?.memberBase?.totalFpo
                          )}{" "}
                          FPOs
                        </h3>
                        <h4>
                          {numberFormatChange(
                            dashboardData?.memberBase?.totalMemberBase
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>Member Data uploaded</h2>
                      </div>
                      <div className="sub-title">
                        <h3>
                          {numberFormatChange(
                            dashboardData?.memberDataUploaded?.totalFpo
                          )}{" "}
                          FPOs
                        </h3>
                        <h4>
                          {numberFormatChange(
                            dashboardData?.memberDataUploaded?.totalMember
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>Members With Name, Village, Phone & Farm</h2>
                      </div>
                      <div className="sub-title">
                        <h3>
                          {numberFormatChange(
                            dashboardData?.memberWithNameAndVilage?.totalFpo
                          )}{" "}
                          FPOs
                        </h3>
                        <h4>
                          {numberFormatChange(
                            dashboardData?.memberWithNameAndVilage?.totalMember
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>Number of FIGs</h2>
                      </div>
                      <div className="sub-title">
                        <h3>Average FIGs - {numberFormatChange(averageFIG)}</h3>
                        <h3>
                          {numberFormatChange(
                            dashboardData?.figMembers?.totalFpo
                          )}{" "}
                          FPOs
                        </h3>
                        <h4>
                          {numberFormatChange(
                            dashboardData?.figMembers?.totalFig
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>KYM Old</h2>
                      </div>
                      <div className="sub-title">
                        <h3>
                          {numberFormatChange(dashboardData?.kymOld?.totalFpo)}{" "}
                          FPOs
                        </h3>
                        <h4>
                          {numberFormatChange(
                            dashboardData?.kymOld?.totalKymOld
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>KYM New</h2>
                      </div>
                      <div className="sub-title">
                        <h3>
                          {numberFormatChange(dashboardData?.kymNew?.totalFpo)}{" "}
                          FPOs
                        </h3>
                        <h4>
                          {numberFormatChange(
                            dashboardData?.kymNew?.totalKymNew
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>Robocalls</h2>
                      </div>
                      <div className="sub-title">
                        <h3>{dashboardData?.robocall?.totalFpo} FPOs</h3>
                        <h4>{dashboardData?.robocall?.totalLaunchRobocall}</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>SMS</h2>
                      </div>
                      <div className="sub-title">
                        <h3>{dashboardData?.sms?.totalFpo} FPOs</h3>
                        <h4>{dashboardData?.sms?.totalLaunchSms}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>Grading</h2>
                      </div>
                      <div className="sub-title">
                        <h4>{numberFormatChange(dashboardData?.grading)}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>Engagement</h2>
                      </div>
                      <div className="sub-title">
                        <h4>{numberFormatChange(dashboardData?.engagement)}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mb-4">
                    <div className="gateway-card">
                      <div className="title">
                        <h2>Sam IPL</h2>
                      </div>
                      <div className="sub-title">
                        <h4>{numberFormatChange(dashboardData?.Sam_IPL)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 bg-white map mb-4">
                <ReactDatamaps
                  mapLayout={{
                    startColor: "#306D4C",
                    endColor: "#306D4C",
                    hoverTitle: "Count",
                    noDataColor: "#D5F3E5",
                    borderColor: "#8D8D8D",
                    hoverBorderColor: "#8D8D8D",
                    hoverColor: "#306D4C",
                  }}
                  hoverComponent={(value) => {
                    return (
                      <div>
                        <p className="text-green-18">{value?.value?.name}</p>
                        {value?.value?.name === selectedState && (
                          <>
                            <div className="d-flex justify-content-between">
                              <p className="mr-2">Number of FPOs</p>
                              <p>{dashboardData?.noOfFpos}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mr-2">Membership Base</p>
                              <p>
                                {dashboardData?.memberBase?.totalMemberBase}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="mr-2">Member Data uploaded</p>
                              <p>
                                {dashboardData?.memberDataUploaded?.totalMember}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  }}
                  onClick={stateOnClick}
                  activeState={activeState}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <h1>BMS</h1>
                <div className="bms-container">
                  <div className="row">
                    <div className="col-3 mb-4">
                      <div className="gateway-card">
                        <div className="title">
                          <h2>BMS Activated</h2>
                        </div>
                        <div className="sub-title">
                          <h4>
                            {numberFormatChange(
                              dashboardData?.bms?.activatedFPOs
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-4">
                      <div className="gateway-card">
                        <div className="title">
                          <h2>Number of Farmers</h2>
                        </div>
                        <div className="sub-title">
                          <h4>
                            {numberFormatChange(
                              dashboardData?.bms?.totalFarmersCount
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-4">
                      <div className="gateway-card">
                        <div className="title">
                          <h2>Number of Sales</h2>
                        </div>
                        <div className="sub-title">
                          <h3>
                            {numberFormatChange(
                              dashboardData?.bms?.totalSalesFPOCount
                            )}{" "}
                            FPOs
                          </h3>
                          <h4>
                            {numberFormatChange(
                              dashboardData?.bms?.totalSalesCount
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-4">
                      <div className="gateway-card">
                        <div className="title">
                          <h2>Sales Amount</h2>
                        </div>
                        <div className="sub-title">
                          <h3>
                            {numberFormatChange(
                              dashboardData?.bms?.totalSalesFPOCount
                            )}{" "}
                            FPOs
                          </h3>
                          <h4>
                            {numDifferentiation(
                              Math.round(dashboardData?.bms?.totalSalesAmount)
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-4">
                      <div className="gateway-card">
                        <div className="title">
                          <h2>Number of Purchases</h2>
                        </div>
                        <div className="sub-title">
                          <h3>
                            {numberFormatChange(
                              dashboardData?.bms?.totalPurchasesFPOCount
                            )}{" "}
                            FPOs
                          </h3>
                          <h4>
                            {numberFormatChange(
                              dashboardData?.bms?.totalPurchasesCount
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-4">
                      <div className="gateway-card">
                        <div className="title">
                          <h2>Purchase Amount</h2>
                        </div>
                        <div className="sub-title">
                          <h3>
                            {numberFormatChange(
                              dashboardData?.bms?.totalPurchasesFPOCount
                            )}{" "}
                            FPOs
                          </h3>
                          <h4>
                            {numDifferentiation(
                              Math.round(
                                dashboardData?.bms?.totalPurchasesAmount
                              )
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-4">
                      <div className="gateway-card">
                        <div className="title">
                          <h2>Number of Other transactions</h2>
                        </div>
                        <div className="sub-title">
                          <h3>
                            {numberFormatChange(
                              dashboardData?.bms?.totalOtherTransactionsFPOCount
                            )}{" "}
                            FPOs
                          </h3>
                          <h4>
                            {numberFormatChange(
                              dashboardData?.bms?.totalOtherTransactionsCount
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-4">
                      <div className="gateway-card">
                        <div className="title">
                          <h2>Other transactions Amount</h2>
                        </div>
                        <div className="sub-title">
                          <h3>
                            {numberFormatChange(
                              dashboardData?.bms?.totalOtherTransactionsFPOCount
                            )}{" "}
                            FPOs
                          </h3>
                          <h4>
                            {numDifferentiation(
                              Math.round(
                                dashboardData?.bms?.totalOtherTransactionsAmount
                              )
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDashboardDetailsApi: getDashboardDetails,
      allStateApi: allState,
      getDistrictApiwithStateApi: getDistrictApiwithState,
    },
    dispatch
  );
};

let component = DashboardHomeClass;
export const DashboardHome = connect(
  null,
  mapDispatchToProps
)(withRouter(component));
