import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAllTestimonials,
  getTestimonialsById,
  deleteTestimonialsById,
} from "action/ContentAct";
import moment from "moment";
import profile_image from "assets/images/default-profile-img.png";
import { TableWrapper, CommonPageLoader, DeletePopup } from "component/common";

import { history } from "service/helpers";

import { DetailPopup } from "component/contentManagement/Modal";

import { getDescriptionData } from "service/helperFunctions";

export class TestimonialsListClass extends Component {
  state = {
    headerDetails: [
      {
        label: "Photo",
      },
      {
        label: "Name",
      },
      {
        label: "Title",
      },
      {
        label: "Profession/Organization",
      },
      {
        label: "Publication date",
      },
      {
        label: "Action",
      },
    ],
    pageMeta: {},
    contentManagement: [],
    isloading: false,
    testimonialView: false,
    testimmonialDetials: {
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non commodo quam venenatis, libero libero. Quisque Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non commodo quam venenatis, libero mauris odio. Leo placerat fermentum sagittis, libero. Quisque ",
      subtitles: [{ title: "Publication date", content: "15/09/2020" }],
      title: "Loreum ipsum dolor sit amet consecuter do amet :",
      profileDetails: {
        name: "Charles Doe",
        designation: "Student",
      },
    },
    isDeletePopup: false,
    testimonialId: "",
  };

  toggle = (name, id) => {
    this.state.testimonialView
      ? this.setState((prevState) => ({
          testimonialView: false,
        }))
      : this.props.getTestimonialsById(id).then((data) => {
          let {
            title,
            name,
            publishOn,
            description,
            uploadImage,
            designation,
          } = data;
          this.setState((prevState) => ({
            testimmonialDetials: {
              description: description,
              subtitles: [
                {
                  title: "Publication date",
                  content: moment(publishOn).format("DD-MM-YYYY"),
                },
              ],
              title: title,
              profileDetails: {
                uploadImage: uploadImage,
                name: name,
                designation: designation,
              },
            },
            testimonialView: true,
          }));
        });
  };
  handleAllTestimonials = (page) => {
    this.setState({ isloading: true });
    this.props.getAllTestimonials({ limit: 10, page: page }).then((data) => {
      this.setState({
        contentManagement: data.results,
        pageMeta: data.pageMeta,
        isloading: false,
      });
    });
  };

  handleDelete = (id) => {
    this.props.deleteTestimonialsById(id).then(() => {
      this.handleAllTestimonials({ page: 1 });
    });
  };
  //handle delete
  handleDelete = () => {
    let { testimonialId } = this.state;
    this.props.deleteTestimonialsById(testimonialId).then(() => {
      this.setState({ testimonialId: "" });
      this.handleAllTestimonials(1);
      this.toggleDelete("isDeletePopup");
    });
  };

  toggleDelete = (name, id) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
      testimonialId: id,
    }));
  };

  //lifecycles
  componentDidMount() {
    this.setState({ isloading: true });
    this.handleAllTestimonials({ page: 1 });
  }

  render() {
    const {
      headerDetails,
      pageMeta = {},
      contentManagement,
      testimonialView,
      testimmonialDetials,
      isloading,
      isDeletePopup,
    } = this.state;
    return (
      <>
        {isloading ? (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        ) : (
          <div className="table-container w-100 mt-4 pt-1">
            {contentManagement.length != 0 ? (
              <TableWrapper
                headerDetails={headerDetails}
                queryHandler={this.handleAllTestimonials}
                pageMeta={pageMeta}
              >
                {contentManagement.map((item, index) => {
                  let {
                    title,
                    name,
                    designation,
                    publishOn,
                    uploadImage,
                    testimonialId,
                  } = item;

                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            <img
                              className="profile-picture-testimonials"
                              src={
                                uploadImage !== null
                                  ? uploadImage
                                  : profile_image
                              }
                              alt="video"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {getDescriptionData(title, 30)}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {getDescriptionData(name, 30)}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {designation}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {moment(publishOn).format("DD/MM/YYYY")}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            <i
                              className="icon-eye mr-3 cursor-pointer fs-20"
                              onClick={() => {
                                this.toggle("testimonialView", testimonialId);
                              }}
                            ></i>
                            <i
                              className="icon-edit mr-3 fs-20 cursor-pointer"
                              onClick={() => {
                                history.push(
                                  `edittestimonial/${testimonialId}`
                                );
                              }}
                            ></i>
                            <i
                              className="icon-Delete fs-20 cursor-pointer"
                              onClick={() => {
                                this.toggleDelete(
                                  "isDeletePopup",
                                  testimonialId
                                );
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                      <tr className="trans-bg">
                        <td className="empty-height" height="12"></td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </TableWrapper>
            ) : (
              <div className="text-center fs-18 text-black py-5">
                No records found !!!
              </div>
            )}
          </div>
        )}
        <DetailPopup
          title="Testimonials details"
          details={testimmonialDetials}
          isOpen={testimonialView}
          toggle={() => this.toggle("testimonialView")}
        />
        <DeletePopup
          isOpen={isDeletePopup}
          toggle={() => this.toggleDelete("isDeletePopup")}
          handleChange={() => this.handleDelete()}
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllTestimonials,
      getTestimonialsById,
      deleteTestimonialsById,
    },
    dispatch
  );
};

let component = TestimonialsListClass;

export const TestimonialsList = connect(null, mapDispatchToProps)(component);
