import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NormalButton } from "../../../component/common";
import { ForumsList } from "../../../component/pages";
import { history } from "../../../service/helpers";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

export class ForumsClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return (
      <div className="forums-list-component">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="">
            <h5 className="heading-20">Forums</h5>
          </div>
          <div className="">
            <NormalButton
              className="btn cursor-pointer mainbg-btn"
              label="Create forum"
              onClick={() => history.push("/admin/forums/create")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ForumsList />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

let component = ForumsClass;

export const Forums = connect(null, mapDispatchToProps)(component);
