import React, { Component } from 'react';

import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';

import { TableWrapper, CommonPageLoader, DeletePopup } from 'component/common';

import { BlogPreviewPopup } from "../BlogPreviewPopup"

import moment from 'moment';

import { getBlogList, deleteblogById } from 'action/CourseAct';

import { getDescriptionData } from 'service/helperFunctions';

export class BlogListClass extends Component {
   state = {
      headerDetails: [
         {
            label: 'Blog id',
         },
         {
            label: 'Blog image',
         },
         {
            label: 'Blog title',
         },
         {
            label: 'Category',
         },
         {
            label: 'Author',
         },
         {
            label: 'Publication date',
         },
         {
            label: 'Action',
         },
      ],
      pageMeta: {},
      blogList: [],
      isloading: false,
      isDeletePopup: false,
      blogId: '',
      formFields: {},
      ispreviewPopup: false
   };

   componentDidMount = () => {
      this._getBlogList(1);
   };

   componentDidUpdate(prevProps) {
      if (this.props.activeTab == 3) {
         this.props.filter !== prevProps.filter && this._getBlogList(1);
         this.props.searchValue !== prevProps.searchValue &&
            this._getBlogList(1);
         this.props.sortBy !== prevProps.sortBy && this._getBlogList(1);
      }
   }

   _getBlogList = (page) => {
      let { filter, filterType, searchValue, sortBy } = this.props;
      this.setState({ isloading: true });
      this.props
         .getBlogList({ page, filter, filterType, search: searchValue, sortBy })
         .then((data) => {
            let results = data.results;
            this.setState({
               pageMeta: data.pageMeta,
               blogList: results,
               isloading: false,
            });
         });
   };

   handlePageChange = (page) => {
      //page change
      this._getBlogList(page);
   };

   //handle delete
   handleDelete = () => {
      let { blogId } = this.state
      this.props.deleteblogById({ blogId: parseInt(blogId) }).then(() => {
         this.setState({ blogId: '' })
         this._getBlogList(1)
         this.toggleDelete('isDeletePopup')
      });
   };

   toggleDelete = (name, id) => {
      this.setState((prevState) => ({
         [name]: !prevState[name],
         blogId: id
      }));
   };

   togglePreview = (name, formFields = {}) => {
      this.setState((prevState) => ({
         [name]: !prevState[name],
         formFields
      }));
   };

   render() {
      const { headerDetails, pageMeta = {}, blogList, isloading, isDeletePopup, formFields, ispreviewPopup } = this.state;

      return (
         <>
            {isloading ? (
               <>
                  {/* Loader */}
                  <CommonPageLoader />
               </>
            ) : (
                  <div className='table-container w-100 mt-4 pt-1'>
                     {blogList.length != 0 ? (
                        <TableWrapper
                           headerDetails={headerDetails}
                           queryHandler={this.handlePageChange}
                           pageMeta={pageMeta}
                        >
                           {blogList.map((item, index) => {
                              let {
                                 title,
                                 category,
                                 publishDate,
                                 author,
                                 blogId,
                                 blogImageFileUrl,
                              } = item;

                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {blogId}
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src={blogImageFileUrl}
                                        alt="video"
                                        className="image-preview"
                                      />
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <p className="mb-0">
                                          {getDescriptionData(title, 70)}
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {category.categoryName}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {author}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {moment(publishDate).format(
                                          "DD MMM YYYY"
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <i
                                          className="icon-eye fs-20 pr-3 cursor-pointer"
                                          onClick={() =>
                                            this.togglePreview(
                                              "ispreviewPopup",
                                              item
                                            )
                                          }
                                        ></i>
                                        <i
                                          className="icon-edit fs-20 cursor-pointer"
                                          onClick={() => {
                                            this.props.history.push({
                                              pathname: `addCourse/addBlog/${blogId}`,
                                            });
                                          }}
                                        ></i>
                                        <i
                                          className="icon-Delete fs-20 pl-3 cursor-pointer"
                                          onClick={() => {
                                            this.toggleDelete(
                                              "isDeletePopup",
                                              blogId
                                            );
                                          }}
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="trans-bg">
                                    <td
                                      className="empty-height"
                                      height="12"
                                    ></td>
                                  </tr>
                                </React.Fragment>
                              );
                           })}
                        </TableWrapper>
                     ) : (
                           <div className='text-center fs-18 text-black py-5'>
                              No records found !!!
                           </div>
                        )}
                  </div>
               )}
            <DeletePopup
               isOpen={isDeletePopup}
               toggle={() => this.toggleDelete('isDeletePopup')}
               handleChange={() => this.handleDelete()}
            />
            <BlogPreviewPopup
               isOpen={ispreviewPopup}
               toggle={() => this.togglePreview('ispreviewPopup')}
               formFields={formFields}
               isListPage={true}
            />
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         getBlogList,
         deleteblogById,
      },
      dispatch
   );
};

let component = BlogListClass;

export const BlogList = connect(null, mapDispatchToProps)(component);
