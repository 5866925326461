import React, { Component } from "react";

import "./allcontent.scss";

import { connect } from "react-redux";

import { NormalButton } from "component/common";

import { history } from "service/helpers";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import {
  EventsList,
  NewsList,
  SuccessStoriesList,
  TestimonialsList,
  MastersList,
} from "../index";

import classnames from "classnames";
import { ReportList } from "../ReportList";

export class AllContentClass extends Component {
  state = {
    activeTab: "1",
  };

  toggle = (tab) => {
    let { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  componentDidMount() {
    this.setState({ activeTab: this.props.contentManagementTab.toString() });
  }

  render() {
    let { activeTab } = this.state;

    console.log(activeTab);

    return (
      <>
        <div className="content-management">
          <div className="d-flex justify-content-between align-items-center content-management-heading">
            <div className="">
              <h5 className="heading-20">Content management</h5>
            </div>
            <div className="">
              {activeTab == 2 ? (
                <NormalButton
                  label="Add articles"
                  mainbg={true}
                  onClick={() => {
                    history.push("addContent");
                  }}
                />
              ) : activeTab == 3 ? (
                <NormalButton
                  label="Add event"
                  mainbg={true}
                  onClick={() => {
                    history.push("addEvent");
                  }}
                />
              ) : activeTab == 4 ? (
                <NormalButton
                  label="Add story"
                  mainbg={true}
                  onClick={() => {
                    history.push("addstories");
                  }}
                />
              ) : activeTab == 5 ? (
                <NormalButton
                  label="Add testimonial"
                  mainbg={true}
                  onClick={() => {
                    history.push("addtestimonial");
                  }}
                />
              ) : activeTab == 1 ? (
                <NormalButton
                  label="Add Report"
                  mainbg={true}
                  onClick={() => {
                    history.push("addReport");
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="common-navlink">
            <div className="filled-tabs">
              <div className="tabs-block">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Report
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Articles
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      Events
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        this.toggle("4");
                      }}
                    >
                      Success stories
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "5" })}
                      onClick={() => {
                        this.toggle("5");
                      }}
                    >
                      Testimonials
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "6" })}
                      onClick={() => {
                        this.toggle("6");
                      }}
                    >
                      Masters
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {activeTab === "1" ? <ReportList /> : ""}
                </TabPane>
                <TabPane tabId="2">
                  {activeTab === "2" ? <NewsList /> : ""}
                </TabPane>
                <TabPane tabId="3">
                  {activeTab === "3" ? <EventsList /> : ""}
                </TabPane>
                <TabPane tabId="4">
                  {activeTab === "4" ? <SuccessStoriesList /> : ""}
                </TabPane>
                <TabPane tabId="5">
                  {activeTab === "5" ? <TestimonialsList /> : ""}
                </TabPane>
                <TabPane tabId="6">
                  {activeTab === "6" ? <MastersList /> : ""}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  contentManagementTab: state.contentManagement.tab,
});

let component = AllContentClass;

export const AllContent = connect(mapStateToProps, null)(component);
