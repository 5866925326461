import React, { Component } from 'react';
import { Pagination } from '../index';
import PropTypes from 'prop-types';
import { scrollTop } from 'service/helperFunctions';
import './table.scss';

export class TableStripedWrapper extends Component {
    state = {
        currentSortKeyDetails: null,
    };

    componentWillMount() {
        let { field = '', orderBy = '' } = this.props;
        if (field && orderBy) {
            this.setState({
                currentSortKeyDetails: { sortKey: field, orderBy },
            });
        }
    }

    getPlaceHolder = () => {
        //   let { placeHolder = 'No data found' } = this.props;
        //   if (typeof placeHolder === 'function') {
        //     return placeHolder();
        //   }

        //   return <p className="text-center">{placeHolder}</p>;
    };

    handlePagination = page => {
        let { queryHandler, scrollProps = '' } = this.props;
        queryHandler({ page })
            .then(() => {
                scrollTop(...scrollProps);
            })
            .catch(err => {
                console.log('Error:::' + err);
            });
    };

    handleFilter = sortKey => {
        let { queryAppend = true } = this.props;
        if (!sortKey) {
            return '';
        }
        let currentSortKeyDetails = Object.assign({}, this.state.currentSortKeyDetails);
        if (!currentSortKeyDetails || currentSortKeyDetails.sortKey !== sortKey) {
            currentSortKeyDetails = {
                sortKey,
                orderBy: 'asc',
            };
        } else {
            if (currentSortKeyDetails.orderBy !== 'desc') {
                currentSortKeyDetails.orderBy = 'desc';
            } else {
                currentSortKeyDetails = null;
            }
        }

        let { sortKey: key = '', orderBy = '' } = currentSortKeyDetails || {};
        if (queryAppend) {
            //   appendQuery([
            //     { label: "field", value: key },
            //     { label: "orderBy", value: orderBy }
            //   ]);
        }

        this.setState(
            {
                currentSortKeyDetails,
            },
            () => !queryAppend && this.handleFilterAPI(),
        );
    };

    handleFilterAPI = () => {
        let { sortKey = null, orderBy = null } = this.state.currentSortKeyDetails || {};
        let { queryHandler } = this.props;
        if (queryHandler) {
            let sort = {
                field: sortKey,
                orderBy,
            };

            queryHandler({ sort });
        }
    };

    render() {
        let { headerDetails, children, pageMeta, isEmpty = false, className = '', overFlow = true } = this.props;
        let { sortKey: currentSortKey, orderBy = '' } = this.state.currentSortKeyDetails || {};

        return (
            <div className="maintableStriped table-container">
                <div className={`maintable-content ${overFlow ? 'table-responsive' : ''} ${className}`}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                {headerDetails.map(({ label, className, divClass = '', sortKey = '', element, isSort = false }, index) => {
                                    return (
                                        <th className={className} key={index}>
                                            <div
                                                className={`d-flex align-items-center justify-content-start text-center fs-16 ${sortKey &&
                                                    'cursor-pointer'} ${divClass}`}
                                                onClick={e => this.handleFilter(sortKey)}
                                            >
                                                {label}
                                                {element && element()}
                                                {isSort ? (
                                                    <div
                                                        className={`d-flex table-filter align-items-center ml-2 ${currentSortKey === sortKey &&
                                                            'active-filter'}`}
                                                    >
                                                        <span
                                                            className={`icon-sort-up ${currentSortKey === sortKey && orderBy === 'asc' && 'active'}`}
                                                        />
                                                        <span
                                                            className={`icon-sort-down ${currentSortKey === sortKey &&
                                                                orderBy === 'desc' &&
                                                                'active'}`}
                                                        />
                                                    </div>
                                                ) : (
                                                        ''
                                                    )}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>{children}</tbody>
                    </table>
                    {!isEmpty ? this.getPlaceHolder() : ''}
                </div>
                {pageMeta && <Pagination handleChange={this.handlePagination} pageMeta={pageMeta} />}
            </div>
        );
    }
}

TableStripedWrapper.propTypes = {
    placeHolder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    isEmpty: PropTypes.bool,
    headerDetails: PropTypes.array.isRequired,
    pageMeta: PropTypes.object,
};
