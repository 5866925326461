import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ViewFpo } from "component/Fpo"

export class ViewFPOClass extends Component {

    render() {
        return (
            <section className="fpolist">
                <ViewFpo
                    id={this.props.match.params.id}
                />
            </section>
        );
    }
}

export const ViewFPO = connect(null, null)(ViewFPOClass)
