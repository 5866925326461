import React, { Fragment, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fpoBazaar from "assets/images/fpobazaar.png";
import { getFpoList } from "action/FPOact";
import { getRMList, rmFPOMapping } from "action/RoleMagementAct";
import {
  TableWrapper,
  CommonPageLoader,
  InputSearch,
  NormalSelect,
  NormalCheckbox,
  NormalButton,
} from "component/common";
import { debounceFunction } from "service/debounce";
import { Toast } from "service/toast";
import "./../style.scss";

const headerDetails = [
  { label: "FPO ID" },
  { label: "FPO Name" },
  { label: "State" },
  { label: "Registered by" },
  { label: "Select" },
];

const MapFPO = (props) => {
  const { getRMListApi, getFpoListApi, rmFPOMappingApi } = props;
  const [loading, setLoading] = useState(false);
  const [rmData, setRMData] = useState([]);
  const [fpoList, setFPOList] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [selectedRM, setSelectedRM] = useState("");
  const [searchData, setSearchData] = useState("");
  const getId = JSON.parse(localStorage.getItem("mappingId")) || [];

  useEffect(() => {
    getRMListApi().then(({ data }) => {
      let rmData = data.map((list) => ({
        label: list.rmEmail,
        value: list.userId,
      }));
      setRMData([...rmData]);
    });
  }, [getRMListApi]);

  const getRMFPOListData = useCallback(
    (page = 1, search = "") => {
      setLoading(true);
      let body = {
        page: page,
        search: search,
        sortBy: "ASC",
      };
      getFpoListApi(body)
        .then(({ results, pageMeta }) => {
          setFPOList(results);
          setPageMeta(pageMeta);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    },
    [getFpoListApi]
  );

  useEffect(() => {
    getRMFPOListData(1, "");
  }, [getRMFPOListData]);

  const handleCheckbox = (e, id) => {
    const { value } = e.target;
    let arr = getId ? [...getId] : [];
    const newList = fpoList?.map((object) => {
      if (object?.fpoId === id) {
        if (value) {
          arr.push(object?.fpoId);
        } else {
          let index = arr
            .map(function (item) {
              return item;
            })
            .indexOf(id);
          arr.splice(index, 1);
        }
        return { ...object, selected: value };
      }
      return object;
    });
    const resultArr = arr?.filter((data, index) => {
      return arr?.indexOf(data) === index;
    });
    localStorage.setItem("mappingId", JSON.stringify(resultArr));
    setFPOList(newList);
  };

  const handlePageChange = ({ page }) => {
    getRMFPOListData(page, "");
  };

  const handleSearch = ({ target: { value } }) => {
    setSearchData(value);
    debounceFunction(() => getRMFPOListData(1, value), 1000);
  };

  const onMapping = () => {
    if (selectedRM === "") {
      Toast({ type: "error", message: "Please select any RM" });
      return null;
    }
    const resultArr = getId?.filter((data, index) => {
      return getId?.indexOf(data) === index;
    });
    if (resultArr?.length === 0) {
      Toast({ type: "error", message: "Please select any FPO" });
      return null;
    }
    let body = { userId: selectedRM, fpoIds: resultArr };
    body.fpoIds = JSON.stringify(body.fpoIds);
    rmFPOMappingApi(body).then(({ message }) => {
      Toast({ type: "success", message });
      localStorage.removeItem("mappingId");
      setSelectedRM("");
      getRMFPOListData(1, "");
    });
  };

  return (
    <div className="role-list">
      <div className="row" id="rmFpoMobileScreen">
        <div className="col-12 pb-3">
          <NormalSelect
            arrow={true}
            value={selectedRM}
            options={rmData}
            name="selectedRM"
            handleChange={(e) => setSelectedRM(e.target.value)}
            label="RM User Email"
            placeholder="Select"
            className="form-control-mobile"
          />
        </div>
        <div className="col-12 pb-3">
          <InputSearch
            className="mr-4 rmFPOSearchBox"
            placeholder={"Search by FPO Name/FPO Id"}
            value={searchData}
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <div className="col-12">
          <NormalButton label="Add Mapping" mainbg={true} onClick={onMapping} />
        </div>
      </div>
      <div id="rmFpoDesktopScreen">
        <div className="d-flex justify-content-between align-items-end">
          <NormalSelect
            arrow={true}
            value={selectedRM}
            options={rmData}
            name="selectedRM"
            handleChange={(e) => setSelectedRM(e.target.value)}
            label="RM User Email"
            placeholder="Select"
          />
          <div className="d-flex">
            <InputSearch
              className="mr-4 rmFPOSearchBox"
              placeholder={"Search by FPO Name/FPO Id"}
              value={searchData}
              onChange={(e) => handleSearch(e)}
            />
            <NormalButton
              label="Add Mapping"
              mainbg={true}
              onClick={onMapping}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <CommonPageLoader />
      ) : (
        <div className="table-container w-100 mt-4 pt-1">
          {fpoList?.length !== 0 ? (
            <TableWrapper
              headerDetails={headerDetails}
              queryHandler={handlePageChange}
              pageMeta={pageMeta}
            >
              {fpoList.map(
                (
                  { fpoId, fpoName, isFederation, selected, stateMaster },
                  index
                ) => {
                  return (
                    <Fragment key={index}>
                      <tr className="new-user-bg">
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {fpoId}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {fpoName}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {stateMaster?.stateName}
                          </div>
                        </td>
                        <td>
                          {isFederation === 1 ? (
                            <img
                              src={fpoBazaar}
                              height="60px"
                              width="60px"
                              alt="fpoBazaarIcon"
                            />
                          ) : (
                            "Farmer Network"
                          )}
                        </td>
                        <td>
                          <NormalCheckbox
                            value=""
                            name="selected"
                            checked={selected || getId?.includes(fpoId)}
                            onChange={(e) => handleCheckbox(e, fpoId)}
                          />
                        </td>
                      </tr>
                      <tr className="trans-bg">
                        <td className="empty-height" height="12"></td>
                      </tr>
                    </Fragment>
                  );
                }
              )}
            </TableWrapper>
          ) : (
            <div className="text-center fs-18 text-black py-5">
              No records found !!!
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRMListApi: getRMList,
      getFpoListApi: getFpoList,
      rmFPOMappingApi: rmFPOMapping,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(MapFPO);
