import React, { Component } from "react";
import { getDescriptionData } from "service/helperFunctions";
import { connect } from "react-redux";
import { getFilePathDownload } from "service/api";
import "./style.scss";

export class PracticeFileCardClass extends Component {
  state = {
    link: "",
  };

  handleDownload = (practiceFileUrl) => {
    let { link } = this.state;
    link = getFilePathDownload + practiceFileUrl;
    this.setState({ link }, () => {
      document.getElementById("csv-download").click();
      this.setState({ link: "" });
    });
  };

  render() {
    let { practiceFile } = this.props;

    let { title = "", description = "", practiceFileUrl = "" } = practiceFile;
    let { link } = this.state;
    return (
      <div className="row w-100 mx-1">
        <div className="col-12">
          <div>
            <p className="practice-file-title">{title}</p>
            <div
              className="practice-file-description"
              dangerouslySetInnerHTML={{
                __html: getDescriptionData(description, 100),
              }}
            ></div>
          </div>
        </div>
        {/* <div className="col-3 d-flex align-items-center px-0">
                    {link === '' ?
                        <button className="btn btn-download"
                            onClick={() => {
                                this.handleDownload(practiceFileName)
                            }}
                        >
                            Get Practice File
                            <i className="icon-arrow-down fs-16 text-white pl-2"></i>
                        </button >
                        :
                        <a
                            id="csv-download"
                            className="btn btn-download"
                            href={link}
                            download
                        >Get Practice File
                            <i className="icon-arrow-down fs-16 text-white pl-2"></i>
                        </a>
                    }
                </div> */}
      </div>
    );
  }
}

export const PracticeFileCard = connect(null, null)(PracticeFileCardClass);
