import React from "react";
import { Modal, ModalBody } from "reactstrap";

export const SuccessPopUp = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} centered>
      <ModalBody>
        <div className="fpo-popup">
          <i className="fas fa-times" onClick={() => toggle()}></i>
          <b>
            Your FPO has been successfully registered. You will be able to login
            after Admin approval.
          </b>
        </div>
      </ModalBody>
    </Modal>
  );
};
