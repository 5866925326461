import React, { Component, Fragment } from "react";
import { getRoboCallHistory } from "action/FPOact";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CommonPageLoader, TableWrapper } from "component/common";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";
import moment from "moment-timezone";
import { getFilePathDownload } from "service/api";

export class CallHistoryClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callList: [],
      headerDetails: [
        {
          label: "Scheduled Date",
        },
        {
          label: "Scheduled Time",
        },
        // {
        //   label: "FPO ID",
        // },
        {
          label: "Program Name",
        },
        {
          label: "Alert Name",
        },
        {
          label: "Total Launched",
        },
        {
          label: "Calls Received",
        },
        {
          label: "User Type",
        },
        {
          label: "Action",
        },
      ],
      pageMeta: {},
      pageNo: 1,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.roboCallList(this.state.pageNo);
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }

  roboCallList = (pageNo) => {
    const { getRoboCallHistory } = this.props;
    getRoboCallHistory({ page: pageNo })
      .then(({ data: { pageMeta, results } }) => {
        this.setState({
          callList: results,
          pageMeta: pageMeta,
          pageNo: pageNo,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleRequestFormList = ({ page }) => {
    this.roboCallList(page);
  };

  downloadCsv = (fileUrl) => {
    window.open(getFilePathDownload + fileUrl);
  };

  render() {
    const { callList, pageMeta, headerDetails, isLoading } = this.state;

    return (
      <Fragment>
        {isLoading ? (
          <CommonPageLoader />
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="table-containermt-4 pt-1">
                {callList.length > 0 ? (
                  <TableWrapper
                    headerDetails={headerDetails}
                    queryHandler={this.handleRequestFormList}
                    pageMeta={pageMeta}
                  >
                    {callList.map(
                      (
                        {
                          alertName,
                          // fpoId,
                          programName,
                          totalLaunched,
                          callReceived,
                          robocallTrackRecords: { fileUrl },
                          userType,
                          scheduleDate,
                          scheduleTime,
                        },
                        index
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {scheduleDate
                                  ? moment(scheduleDate)
                                      .tz("Asia/Kolkata")
                                      .format("DD-MM-YYYY")
                                  : "-"}
                              </td>
                              <td align="center">
                                {scheduleTime
                                  ? moment(scheduleTime)
                                      .tz("Asia/Kolkata")
                                      .format("hh:mm a")
                                  : "-"}
                              </td>
                              {/* <td align="center">{fpoId}</td> */}
                              <td>{programName || "-"}</td>
                              <td>{alertName}</td>
                              <td align="center">{totalLaunched}</td>
                              <td align="center">{callReceived}</td>
                              <td align="center" className="text-capitalize">
                                {userType}
                              </td>
                              <td className="robocall-download">
                                <button
                                  onClick={() => this.downloadCsv(fileUrl)}
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                            <tr className="trans-bg">
                              <td className="empty-height" height="12"></td>
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )}
                  </TableWrapper>
                ) : (
                  <div className="text-center fs-18 text-black py-5">
                    {"No records found !!!"}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRoboCallHistory,
    },
    dispatch
  );
};

let component = CallHistoryClass;

export const CallHistory = connect(null, mapDispatchToProps)(component);
