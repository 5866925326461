import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { OtpLogin } from "../../component/auth/login/index";
import "../../component/auth/button.scss";
const Logo = require("../../assets/svg/logo.svg");

export class LoginClass extends Component {
  render() {
    return (
      <div className=" d-flex align-items-center flex-column">
        <img className="w-75 mb-4 " src={Logo} alt="Logo" height="75" />
        <OtpLogin />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginClass);
