import React, { Component } from "react";

import { connect } from "react-redux";

import "./requestForms.scss";
import { history } from "service/helpers";
export class RequestFormsClass extends Component {
  state = {};

  render() {
    let { userDetails, roboCallRequest } = this.props;

    let {
      externaluser = 0,
      fpoUser = 0,
      individualUser = 0,
      sammunatiUser = 0,
    } = userDetails;

    return (
      <>
        <div className="card requestForms">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="card-title fs-24 font-Gilroy-Bold line-height-28 text-green-18">
                Request forms
              </h5>
              <h6
                className="card-title fs-16 font-Gilroy-Bold line-height-28 text-green-18 cursor-pointer"
                onClick={() => history.push("/admin/requestForm")}
              >
                View
              </h6>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                Sammunati <span className="float-right">{sammunatiUser}</span>
              </li>
              <li className="list-group-item">
                FPO <span className="float-right">{fpoUser}</span>
              </li>
              <li className="list-group-item">
                External organization{" "}
                <span className="float-right">{externaluser}</span>
              </li>
              <li className="list-group-item">
                Individual <span className="float-right">{individualUser}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="card requestForms mt-4">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="card-title fs-24 font-Gilroy-Bold line-height-28 text-green-18">
                RoboCall Request
              </h5>
              <h6
                className="card-title fs-16 font-Gilroy-Bold line-height-28 text-green-18 cursor-pointer"
                onClick={() => history.push("/admin/robocall-sms")}
              >
                View
              </h6>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                Total Request{" "}
                <span className="float-right">{roboCallRequest}</span>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}
export const RequestForms = connect(null, null)(RequestFormsClass);
