import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
   Button,
   Modal,
   ModalHeader,
   ModalBody,
   ModalFooter,
   Form,
} from 'reactstrap';
import './feedbackDetails.scss';
export class FeedbackDetailsClass extends Component {

   render() {
      let { show, detailModalToggle, feedbackData } = this.props;
      let {
         name,
         designation,
         feedbackTitle,
         feedbackContent,
         sentOn,
      } = feedbackData;
      return (
         <Modal
            isOpen={show}
            className='feedback-detail modal-dialog-centered'
            size='lg'
         >
            <div className='modal-header'>
               <h5 className='modal-title'>Feedback details</h5>
               <button
                  type='button'
                  className='close'
                  onClick={detailModalToggle}
               >
                  <span aria-hidden='true'>&times;</span>
               </button>
            </div>
            <ModalBody className=' pb-5'>
               <div className='content-name'>{name}</div>
               <div className='content-designation'>{designation}</div>
               <div className='feedback-title'>{feedbackTitle}</div>
               <div className='feedback-content'>{feedbackContent}</div>
               <div className='row'>
                  <div className='col-3 feedback-sub-title'>Sent on</div>
                  <div className='col-4 feedback-sub-content'>{sentOn}</div>
               </div>
            </ModalBody>
         </Modal>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators({}, dispatch);
};

let component = FeedbackDetailsClass;

export const FeedbackDetails = connect(null, mapDispatchToProps)(component);
