import React, { Component } from "react";
import { FeedbackList } from "component/Feedback";
import { connect } from "react-redux";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";
class FeedbackClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <FeedbackList history={this.props.history} />;
  }
}

export const Feedback = connect(null, null)(FeedbackClass);
