import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./forumList.scss";
import { TableWrapper, CommonPageLoader, DeletePopup } from "component/common";
import { ForumsDetail } from "../forumsDetails";
import { getForumList, deleteForumById, getForumById } from "action/forumAct";
import { history } from "service/helpers";
import { Toast } from "service/toast";
import moment from "moment";
import { getDescriptionData } from "service/helperFunctions";
export class ForumsListClass extends Component {
  state = {
    headerDetails: [
      {
        label: "Date & Time",
      },
      {
        label: "Forum title",
      },
      {
        label: "Created by",
      },
      {
        label: "Likes",
      },
      {
        label: "Comments",
      },
      {
        label: "Action",
      },
    ],
    pageMeta: {},
    forumList: [],
    viewData: {},
    isShowDetalModal: false,
    isLoading: false,
    isDeletePopup: false,
    forumId: "",
  };

  //Lifecycles
  componentDidMount() {
    this._getForumList(1);
  }

  //get forum list
  _getForumList = (page) => {
    let { getForumList } = this.props;
    this.setState({ isLoading: true });
    getForumList({ page }).then((data) => {
      this.setState({
        forumList: data.results,
        pageMeta: data.pageMeta,
        isLoading: false,
      });
    });
  };

  //page change
  handlePageChange = (page) => {
    this._getForumList(page);
  };

  //handle forums get details
  handleForumsDetails = (id) => {
    if (this.state.isShowDetalModal) {
      this.setState({ isShowDetalModal: !this.state.isShowDetalModal });
    } else {
      this.props.getForumById(id).then((data) => {
        this.setState({
          viewData: data,
        });
        this.setState({ isShowDetalModal: !this.state.isShowDetalModal });
      });
    }
  };

  //handle delete
  handleDelete = () => {
    let { forumId } = this.state;
    this.props.deleteForumById(forumId).then(() => {
      this.setState({ forumId: "" });
      this._getForumList(1);
      Toast({ type: "success", message: "Forum deleted successfully" });
      this.toggleDelete("isDeletePopup");
    });
  };

  toggleDelete = (name, id) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
      forumId: id,
    }));
  };

  render() {
    let {
      headerDetails,
      forumList,
      isShowDetalModal,
      pageMeta,
      isLoading,
      viewData,
      isDeletePopup,
    } = this.state;
    return (
      <div className="row">
        {isLoading ? (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        ) : (
          <div className="col-md-12">
            <div className="table-containermt-4 pt-1">
              {forumList.length != 0 ? (
                <TableWrapper
                  headerDetails={headerDetails}
                  pageMeta={pageMeta}
                  queryHandler={this.handlePageChange}
                >
                  {forumList.map(
                    (
                      {
                        createdAt,
                        title,
                        userDetails,
                        likesCount,
                        commentsCount,
                        id,
                        isCreatedByAdmin = false,
                        createdBy,
                      },
                      index
                    ) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              {" "}
                              {moment(createdAt).format(
                                "DD/MM/YYYY  |  k:mm"
                              )}{" "}
                            </td>
                            <td>
                              {title ? getDescriptionData(title, 50) : ""}
                            </td>
                            <td>
                              {userDetails &&
                              userDetails !== null &&
                              userDetails.userName !== null
                                ? userDetails.userName
                                : createdBy
                                ? createdBy
                                : "-"}
                            </td>
                            <td>{likesCount}</td>
                            <td>{commentsCount}</td>
                            <td>
                              <div className="d-flex align-items-center h-100">
                                <i
                                  className="icon-eye mr-3 cursor-pointer fs-20"
                                  onClick={() => this.handleForumsDetails(id)}
                                ></i>
                                {isCreatedByAdmin && (
                                  <i
                                    className="icon-edit fs-20 mr-3 cursor-pointer"
                                    onClick={() =>
                                      history.push(`/admin/forums/edit/${id}`)
                                    }
                                  ></i>
                                )}
                                <i
                                  className="icon-Delete fs-20 cursor-pointer"
                                  onClick={() =>
                                    this.toggleDelete("isDeletePopup", id)
                                  }
                                ></i>
                              </div>
                            </td>
                          </tr>
                          <tr className="trans-bg">
                            <td className="empty-height" height="12"></td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                  )}
                </TableWrapper>
              ) : (
                <div className="text-center fs-18 text-black py-5">
                  No records found !!!
                </div>
              )}
            </div>
          </div>
        )}
        {isShowDetalModal && (
          <ForumsDetail
            show={isShowDetalModal}
            detailModalToggle={this.handleForumsDetails}
            forumData={viewData}
          />
        )}
        <DeletePopup
          isOpen={isDeletePopup}
          toggle={() => this.toggleDelete("isDeletePopup")}
          handleChange={() => this.handleDelete()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getForumList,
      deleteForumById,
      getForumById,
    },
    dispatch
  );
};

let component = ForumsListClass;

export const ForumsList = connect(null, mapDispatchToProps)(component);
