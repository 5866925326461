import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import { BasicDetailsofFPO } from "./BasicDetailsofFPO";
import { MemberList } from "./MemberList";
import { BodList } from "./BodList";
import { StaffList } from "./StaffList";
import { FPOdocuments } from "./FPOdocuments"
import {

} from "action/FPOact"
import "./style.scss"
import { history } from "service/helpers";
import { SubscribeAlerts } from "./SubscribeAlerts";

export class ViewFpoClass extends Component {
    state = {
        activeTab: '1'
    };

    //toggle tab
    toggle = (tab) => {
        let { activeTab } = this.state
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        let { activeTab } = this.state
        let { id } = this.props
        return (
            <div className='fpo-view'>
                <div
                    className='d-flex cursor-pointer pt-2 mt-1 mb-4 back-area'
                    onClick={() => history.push('/admin/fpoList')}
                >
                    <i className='icon-arrow-left fs-24'></i>
                    <h5 className='heading-20 pl-4'>FPO details</h5>
                </div>
                <div className="common-navlink">
                    <div className="filled-tabs">
                        <div className="tabs-block mb-4">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}
                                    >Basic details</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}
                                    >FPO documents</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}
                                    >Members</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '4' })}
                                        onClick={() => { this.toggle('4'); }}
                                    >BOD</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '5' })}
                                        onClick={() => { this.toggle('5'); }}
                                    >Staffs</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "6" })}
                                        onClick={() => {
                                        this.toggle("6");
                                        }}
                                    >
                                        Subscribe Alerts
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <div className="view-fpo-details">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <BasicDetailsofFPO
                                        activeTab={activeTab}
                                        id={id}
                                    />
                                </TabPane>
                                <TabPane tabId="2">
                                    <FPOdocuments
                                        activeTab={activeTab}
                                        id={id}
                                    />
                                </TabPane>
                                <TabPane tabId="3">
                                    <MemberList
                                        activeTab={activeTab}
                                        id={id}
                                    />
                                </TabPane>
                                <TabPane tabId="4">
                                    <BodList
                                        activeTab={activeTab}
                                        id={id}
                                    />
                                </TabPane>
                                <TabPane tabId="5">
                                    <StaffList
                                        activeTab={activeTab}
                                        id={id}
                                    />
                                </TabPane>
                                <TabPane tabId="6">
                                    <SubscribeAlerts activeTab={activeTab} id={id} />
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

let component = ViewFpoClass;

export const ViewFpo = connect(null, null)(component);
