import React, { Component } from "react";
import { getDashboardDetailsforAcadamy } from "action/dashboard";
import { AcademyContents } from "component/AcademyDashboard";
import { NormalSelect } from "component/common";

import { CommonPageLoader } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Highcharts from "highcharts/highstock";

import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";
export class AcademyDashboardClass extends Component {
  state = {
    acadamyDetail: {},
    isLoading: false,
    dropdownType: "course",
    dropdownTypeOptions: [
      { label: "Course", value: "course" },
      { label: "Infomative videos", value: "informativeVideo" },
      { label: "Blog", value: "blog" },
    ],
    options: {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: [],
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "Views",
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Viewers",
          color: "#D0962B",
          data: [],
        },
      ],
      credits: {
        enabled: false,
      },
    },
  };

  handleInput = ({ target: { name, value } }) => {
    //handle inputs
    this.setState(
      {
        [name]: value,
      },
      () => {
        this._getDashboardDetails();
      }
    );
  };

  componentDidMount() {
    this._getDashboardDetails();
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }

  _getDashboardDetails = () => {
    let { getDashboardDetailsforAcadamy } = this.props;
    let { dropdownType, options } = this.state;
    this.setState({ isLoading: true });

    let payload = {
      type: dropdownType,
    };

    getDashboardDetailsforAcadamy(payload).then(({ data }) => {
      this.setState({
        acadamyDetail: data,
        isLoading: false,
      });
      let optionsData = data.academyGraph;
      let { xAxis = {}, series = [] } = options;
      xAxis.categories = [];
      series[0].data = [];
      optionsData.map((list) => {
        xAxis.categories.push(list.title);
        series[0].data.push(list.count);
      });

      Highcharts.chart("container", options);
    });
  };

  render() {
    let { isLoading, acadamyDetail, dropdownType, dropdownTypeOptions } =
      this.state;

    return (
      <div className="academy-dashboard">
        {isLoading ? (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <AcademyContents acadamyDetail={acadamyDetail} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-9">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <div className="card topPerfomance">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <h5 className="card-title fs-24 font-Gilroy-Bold line-height-28 text-green-18">
                              Most viewed{" "}
                              {dropdownType === "course"
                                ? "courses"
                                : dropdownType === "blog"
                                ? "blog"
                                : "infomative videos"}
                            </h5>
                          </div>
                          <div>
                            <NormalSelect
                              placeholder="select"
                              arrow={true}
                              value={dropdownType}
                              options={dropdownTypeOptions}
                              name="dropdownType"
                              handleChange={this.handleInput}
                            />
                          </div>
                        </div>
                        <div id="container"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDashboardDetailsforAcadamy,
    },
    dispatch
  );
};

let component = AcademyDashboardClass;

export const AcademyDashboard = connect(null, mapDispatchToProps)(component);
