import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getFpoStaffList,
  getstaffById,
  downloadCsvforFpoStaff,
} from "action/FPOact";
import { TableStripedWrapper, CommonPageLoader } from "component/common";
import { ViewStaffPopup } from "./ViewStaffPopup";
import { getDescriptionData } from "service/helperFunctions";
import moment from "moment";

export class StaffListClass extends Component {
  state = {
    staffList: [],
    headerDetails: [
      {
        label: "S. No",
      },
      {
        label: "Name",
      },
      {
        label: "Education",
      },
      {
        label: "Experience",
      },
      {
        label: "Date of Appointment",
      },
      {
        label: "Action",
      },
    ],
    pageMeta: {},
    isLoading: true,
    isStaffView: false,
    isPopupDetailLoading: false,
    staffDetail: {},
  };

  //get details
  _getDetails = (page) => {
    let { getFpoStaffList, id } = this.props;
    let body = {
      page: page,
    };
    this.setState({ isLoading: true });
    getFpoStaffList(id, body).then((data) => {
      this.setState({
        staffList: data.results,
        pageMeta: data.pageMeta,
        isLoading: false,
      });
    });
  };

  //life cycles
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab === "5") {
        this._getDetails(1);
      }
    }
  }

  handlePageChange = (page) => {
    // page change
    this._getDetails(page);
  };

  toggle = (name, id) => {
    this.setState(
      (prevState) => ({
        [name]: !prevState[name],
      }),
      () => {
        if (id !== undefined) {
          this.setState({ isPopupDetailLoading: true });
          this.props.getstaffById({ id }).then((data) => {
            this.setState({
              isPopupDetailLoading: false,
              staffDetail: data,
            });
          });
        }
      }
    );
  };

  downloadCsv = () => {
    let { id } = this.props;
    this.props.downloadCsvforFpoStaff(id).then((data) => {
      this.downloadFunction(data, "Staff");
    });
  };

  downloadFunction(data, fileName) {
    var a = window.document.createElement("a");
    var blob = new Blob([data], {
      type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(
        blob,
        `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`
      );
    } else {
      a.href = window.URL.createObjectURL(blob);
      // supply your own fileName here...
      a.download = `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  render() {
    let {
      staffList,
      headerDetails,
      pageMeta,
      isLoading,
      isStaffView,
      staffDetail,
      isPopupDetailLoading,
    } = this.state;

    return (
      <div className="bod-list">
        <div className="d-flex justify-content-between mb-4">
          <div>
            <h5 className="text-green-18 fs-20 line-height-25 font-weight-bold">
              Staff list
            </h5>
          </div>
          <div>
            <h5
              className="text-green-18 fs-20 line-height-25 font-weight-bold text-underline cursor-pointer"
              onClick={() => this.downloadCsv()}
              id="csv-download"
            >
              Download CSV
            </h5>
          </div>
        </div>
        {isLoading ? (
          <>
            {/* Loading */}
            <CommonPageLoader />
          </>
        ) : (
          <>
            {staffList.length !== 0 ? (
              <TableStripedWrapper
                headerDetails={headerDetails}
                pageMeta={pageMeta}
                queryHandler={this.handlePageChange}
              >
                {staffList.map((item, index) => {
                  let {
                    firstName = "",
                    lastName = "",
                    educationmaster = {},
                    experience = "-",
                    dateOfJoining = "",
                    staffId = "",
                  } = item;
                  let userName = firstName + " " + lastName;
                  return (
                    <tr key={index}>
                      <td>
                        <div className="px-3">{index + 1}</div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {userName ? getDescriptionData(userName, 20) : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {educationmaster && educationmaster.qualificationType
                            ? getDescriptionData(
                                educationmaster.qualificationType,
                                25
                              )
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {experience ? experience + " Yrs" : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {dateOfJoining
                            ? moment(dateOfJoining).format("DD/MM/YYYY")
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          <i
                            className="icon-eye mr-3 cursor-pointer fs-20"
                            onClick={() => this.toggle("isStaffView", staffId)}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </TableStripedWrapper>
            ) : (
              <p className="py-5 my-5 fs-20 text-center text-black-50 line-height-23 fw-700">
                No records found !!!
              </p>
            )}
          </>
        )}
        <ViewStaffPopup
          isOpen={isStaffView}
          toggle={() => this.toggle("isStaffView")}
          isLoading={isPopupDetailLoading}
          staffDetail={staffDetail}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFpoStaffList,
      getstaffById,
      downloadCsvforFpoStaff,
    },
    dispatch
  );
};

let component = StaffListClass;

export const StaffList = connect(null, mapDispatchToProps)(component);
