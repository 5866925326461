import { FPO } from "service/apiVariables";

import { addQuery } from "service/helperFunctions";

// Get state list
export const allState =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.allState })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get crop Business Infra
export const getcropBusinessInfra =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.getcropBusinessInfra })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Pincode Api with State District
export const getPincodeApiwithStateDistrict =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { getPincodeApiwithStateDistrict } = FPO;
    getPincodeApiwithStateDistrict.id = body.id;
    return new Promise((resolve, reject) => {
      api({ ...FPO.getPincodeApiwithStateDistrict })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get District Api with State
export const getDistrictApiwithState =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { getDistrictApiwithState } = FPO;
    getDistrictApiwithState.id = body.id;
    return new Promise((resolve, reject) => {
      api({ ...FPO.getDistrictApiwithState })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Fpo List
export const getFpoList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, FPO.getFpoList);
      api({ ...FPO.getFpoList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//add FPO
export const addFPO =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.addFPO, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Fpo Basic Details By Id
export const getFpoBasicDetailsById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFpoBasicDetailsById } = FPO;
    getFpoBasicDetailsById.id = id;
    return new Promise((resolve, reject) => {
      api({ ...FPO.getFpoBasicDetailsById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Fpo Member List
export const getFpoMemberList =
  (id, query) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFpoMemberList } = FPO;
    getFpoMemberList.id = id;
    return new Promise((resolve, reject) => {
      addQuery(query, FPO.getFpoMemberList);
      api({ ...FPO.getFpoMemberList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Delete Member
export const deleteMember =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { deleteMember } = FPO;

    deleteMember.id = id;

    return new Promise((resolve, reject) => {
      api({ ...FPO.deleteMember })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getAllFinancialYearDropdown =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.getAllFinancialYearDropdown })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Fpo BOD List
export const getFpoBodList =
  (id, query) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFpoBodList } = FPO;
    getFpoBodList.id = id;
    return new Promise((resolve, reject) => {
      addQuery(query, FPO.getFpoBodList);
      api({ ...FPO.getFpoBodList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Fpo Staff List
export const getFpoStaffList =
  (id, query) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFpoStaffList } = FPO;
    getFpoStaffList.id = id;
    return new Promise((resolve, reject) => {
      addQuery(query, FPO.getFpoStaffList);
      api({ ...FPO.getFpoStaffList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Fpo Company KYC
export const getFpoCompanyKYC =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFpoCompanyKYC } = FPO;
    getFpoCompanyKYC.id = id;
    return new Promise((resolve, reject) => {
      api({ ...FPO.getFpoCompanyKYC })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
//get Fpo Company KYC
export const getFpoStuatary =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFpoStuatary } = FPO;
    getFpoStuatary.id = id;
    return new Promise((resolve, reject) => {
      api({ ...FPO.getFpoStuatary })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
//get Fpo Company KYC
export const getFpodirectdoc =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFpodirectdoc } = FPO;
    getFpodirectdoc.id = id;
    return new Promise((resolve, reject) => {
      api({ ...FPO.getFpodirectdoc })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
//get Fpo Company KYC
export const getFpofinancial =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFpofinancial } = FPO;
    getFpofinancial.id = id;
    return new Promise((resolve, reject) => {
      api({ ...FPO.getFpofinancial })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
//get Fpo Company KYC
export const getFpocreditdata =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFpocreditdata } = FPO;
    getFpocreditdata.id = id;
    return new Promise((resolve, reject) => {
      api({ ...FPO.getFpocreditdata })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Company Kyc Bulk download
export const getCompanyKycBulkdownload =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, FPO.getCompanyKycBulkdownload);
      api({ ...FPO.getCompanyKycBulkdownload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Financial Bulk download
export const getFinancialBulkdownload =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, FPO.getFinancialBulkdownload);
      api({ ...FPO.getFinancialBulkdownload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//ge tDirectdoc Bulk download
export const getDirectdocBulkdownload =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, FPO.getDirectdocBulkdownload);
      api({ ...FPO.getDirectdocBulkdownload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get creditdata Bulk download
export const getcreditdataBulkdownload =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, FPO.getcreditdataBulkdownload);
      api({ ...FPO.getcreditdataBulkdownload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get statutory Bulk download
export const getstatutoryBulkdownload =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, FPO.getstatutoryBulkdownload);
      api({ ...FPO.getstatutoryBulkdownload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Body By Id
export const getBodById =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { getBodById } = FPO;

    getBodById.id = body.id;

    return new Promise((resolve, reject) => {
      api({ ...FPO.getBodById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get staff By Id
export const getstaffById =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { getstaffById } = FPO;

    getstaffById.id = body.id;

    return new Promise((resolve, reject) => {
      api({ ...FPO.getstaffById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const getMemberById =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { getMemberById } = FPO;

    getMemberById.id = body.id;

    return new Promise((resolve, reject) => {
      api({ ...FPO.getMemberById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//download Csv for Fpo Members
export const downloadCsvforFpoMembers =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { downloadCsvforFpoMembers } = FPO;

    downloadCsvforFpoMembers.id = id;

    return new Promise((resolve, reject) => {
      api({ ...FPO.downloadCsvforFpoMembers })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDownloadBusinessKYMMembersData =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getDownloadBusinessKYMMembersData } = FPO;
    getDownloadBusinessKYMMembersData.id = id;
    return new Promise((resolve, reject) => {
      api({ ...FPO.getDownloadBusinessKYMMembersData })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//download Csv for Fpo BOD
export const downloadCsvforFpoBod =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { downloadCsvforFpoBod } = FPO;

    downloadCsvforFpoBod.id = id;

    return new Promise((resolve, reject) => {
      api({ ...FPO.downloadCsvforFpoBod })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//download Csv for Fpo Staff
export const downloadCsvforFpoStaff =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { downloadCsvforFpoStaff } = FPO;

    downloadCsvforFpoStaff.id = id;

    return new Promise((resolve, reject) => {
      api({ ...FPO.downloadCsvforFpoStaff })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//delete Fpo By Id
export const deleteFpoById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { deleteFpoById } = FPO;

    deleteFpoById.id = id;

    return new Promise((resolve, reject) => {
      api({ ...FPO.deleteFpoById })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updateFederationType =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.federationType, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//download Grading Tool Excel
export const downloadGradingToolExcel =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.downloadGradingToolExcel })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//upload Grading Tool Excel
export const uploadGradingToolExcel =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.uploadGradingToolExcel, body })
        .then((data) => {
          resolve(data);
          Toast({ type: "success", message: "Uploaded successfully" });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

// Edit FPO
export const editAccessFPO =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.editFPO, body })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

// getFarmerCrop
export const getFarmerCrop =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      let farmerCrop = FPO.farmerCrop;
      farmerCrop.api += `?name=` + body.name;
      api({ ...farmerCrop, body })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

// getSupportedLanguage
export const getSupportedLanguage =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.supportedLanguages, body })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//
export const getAllSubscribeAlerts =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      let { getSubscribeAlerts } = FPO;
      getSubscribeAlerts.id = body.id;
      addQuery(body, FPO.getSubscribeAlerts);
      api({ ...FPO.getSubscribeAlerts, body })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

// postSubscribeAlerts
export const postSubscribeAlerts =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      let { subscribeAlerts } = FPO;
      subscribeAlerts.id = body.id;
      api({ ...FPO.subscribeAlerts, body })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getRoboCallHistory =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, FPO.getRoboCallHistory);

    return new Promise((resolve, reject) => {
      api({ ...FPO.getRoboCallHistory })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const roboCallReport =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.roboCallReport })
        .then((res) => {
          resolve(res);
          Toast({ type: "success", message: "Downloaded" });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getSmsHistory =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, FPO.getSmsHistory);

    return new Promise((resolve, reject) => {
      api({ ...FPO.getSmsHistory })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const smsReport =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.smsReport })
        .then((res) => {
          resolve(res);
          Toast({ type: "success", message: "Downloaded" });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const smsIndividualReport =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.smsIndividualReport })
        .then((res) => {
          resolve(res);
          Toast({ type: "success", message: "Downloaded" });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getSignUpEntityType =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.signUpEntityType })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const emailAndNumberValidation =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...FPO.emailAndNumberValidation, body })
        .then(({ message }) => {
          resolve(message);
          // Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(message);
          // reject(Toast({ type: "error", message }));
        });
    });
  };
