

import { Addreport } from "component/contentManagement/Addreport/Addreport";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

export class AddReportClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <Addreport match={this.props.match}/>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

let component = AddReportClass;

export const AddReport = connect(null, mapDispatchToProps)(component);
