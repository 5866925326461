import React, { Component } from 'react';
import {
   letterAvatar,
} from '../../../../service/helperFunctions';
import moment from "moment"
export class CommentCard extends Component {
   state = {
      subCommentsIndex: 0,
   };
   handlePagination = (operation) => {
      switch (operation) {
         case '+': {
            if (
               this.state.subCommentsIndex + 1 <
               this.props.comment?.subComments?.length
            ) {
               this.setState({
                  subCommentsIndex: this.state.subCommentsIndex + 1,
               });
            }
            break;
         }
         case '-': {
            if (this.state.subCommentsIndex > 0) {
               this.setState({
                  subCommentsIndex: this.state.subCommentsIndex - 1,
               });
            }
            break;
         }
         default: {
            break;
         }
      }
   };
   render() {
      const { comment } = this.props;
      const { subComments } = comment;
      const { subCommentsIndex } = this.state;
      return (
         <div className='comments-section'>
            <div className='row '>
               <div className='col-md-12 user-profile mb-1'>
                  <div className='media'>
                     <img
                        className='mr-3'
                        src={letterAvatar(comment.userDetails.userName, 30)}
                        alt=''
                     />
                     <div className='media-body'>
                        <h5 className='mt-0'>
                           {comment.userDetails.userName} . 1h ago
                        </h5>
                     </div>
                  </div>
               </div>
               {comment.attachment && (
                  <div className='col-md-10 ml-5 mt-2'>
                     <img
                        className='card-img comment-img'
                        src={comment.attachment && comment.attachment}
                     />
                  </div>
               )}
               <div className='col-md-12 comments-text'>
                  <label className='content-sub'>{comment.content}</label>
               </div>
               <div className='col-md-12 actions-section'>
                  <ul>
                     <li>
                        <img src='/icon/like.svg' alt='' />{' '}
                        {comment.commentLikesCounts}
                     </li>
                     <li>
                        <img src='/icon/chat.svg' alt='' />{' '}
                        {comment.commentCounts}
                     </li>
                  </ul>
               </div>
               {subComments.length > 0 && (
                  <div className='col-md-12 comments-text'>
                     <div className='row '>
                        <div className='col-md-12 user-profile mb-1'>
                           <div className='media'>
                              <img
                                 className='mr-3'
                                 src={letterAvatar(
                                    subComments[subCommentsIndex].userDetails
                                       .userName,
                                    30
                                 )}
                                 alt=''
                              />
                              <div className='media-body'>
                                 <h5 className='mt-0'>
                                    {
                                       subComments[subCommentsIndex].userDetails
                                          .userName
                                    }{' '}
                                    .{' '}
                                    {`${moment(subComments[subCommentsIndex]?.createdAt, "YYYY-MM-DD HH:mm:ss").toNow(true)} ago`}{' '}
                                    ago
                                 </h5>
                              </div>
                           </div>
                        </div>
                        {subComments[subCommentsIndex]?.attachment && (
                           <div className='col-md-10 ml-5'>
                              <img
                                 className='card-img comment-img'
                                 src={
                                    subComments[subCommentsIndex]?.attachment &&
                                    subComments[subCommentsIndex]?.attachment
                                 }
                              />
                           </div>
                        )}
                        <div className='col-md-10 comments-text'>
                           <label className='content-sub'>
                              {subComments[subCommentsIndex]?.content}
                           </label>
                        </div>
                        {/* <div className='col-md-12 actions-section'>
                                                      <ul>
                                                         <li>
                                                            <img
                                                               src='/icon/like.svg'
                                                               alt=''
                                                            />{' '}
                                                            1220
                                                         </li>
                                                         <li>
                                                            <img
                                                               src='/icon/chat.svg'
                                                               alt=''
                                                            />{' '}
                                                            12
                                                         </li>
                                                         <li>
                                                            <img
                                                               src='/icon/corner-up-left.svg'
                                                               alt=''
                                                            />{' '}
                                                            12
                                                         </li>
                                                      </ul>
                                                   </div> */}
                        {subComments.length > 1 && (
                           <div className='d-flex flex-column align-items-center comment-pagination'>
                              <span
                                 class={`icon-page-right fs-15 rotate-90 cursor-pointer ${
                                    subCommentsIndex === 0 ? 'disabled' : ''
                                    }`}
                                 onClick={() => this.handlePagination('-')}
                              ></span>
                              <div className='fw-600'>
                                 {subComments.length - (subCommentsIndex + 1)}
                              </div>
                              <span
                                 class={`icon-page-left fs-15 rotate-90 cursor-pointer ${
                                    subCommentsIndex + 1 === subComments.length
                                       ? 'disabled'
                                       : ''
                                    }`}
                                 onClick={() => this.handlePagination('+')}
                              ></span>
                           </div>
                        )}
                     </div>
                  </div>
               )}
            </div>
         </div>
      );
   }
}
