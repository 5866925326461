import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Addtestimonial } from "component/contentManagement/Addtestimonial/Addtestimonial";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

export class AddTestimonialClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }

  render() {
    return <Addtestimonial match={this.props.match} />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

let component = AddTestimonialClass;

export const AddTestimonial = connect(null, mapDispatchToProps)(component);
