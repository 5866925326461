import React, { Component } from "react";
import { Addvideo } from "../../../component/courseManagement/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";
class AddVideoClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <Addvideo match={this.props.match} />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const AddVideo = connect(null, mapDispatchToProps)(AddVideoClass);
