import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { Pagination } from "../index";
import PropTypes from "prop-types";
import { scrollTop } from "service/helperFunctions";
import infoIcon from "assets/svg/dashboard/dashboard-infoicon.svg";
import "./table.scss";

export class TableWrapper extends Component {
  state = {
    currentSortKeyDetails: null,
  };

  // componentWillMount() {
  //     let { field = '', orderBy = '' } = this.props;
  //     if (field && orderBy) {
  //         this.setState({
  //             currentSortKeyDetails: { sortKey: field, orderBy },
  //         });
  //     }
  // }

  getPlaceHolder = () => {
    //   let { placeHolder = 'No data found' } = this.props;
    //   if (typeof placeHolder === 'function') {
    //     return placeHolder();
    //   }
    //   return <p className="text-center">{placeHolder}</p>;
  };

  handlePagination = (page) => {
    let { queryHandler, scrollProps = "" } = this.props;
    queryHandler({ page });
    // .then(() => {
    //     scrollTop(...scrollProps);
    // })
    // .catch(err => {
    //     console.log('Error:::' + err);
    // });
  };

  render() {
    let {
      headerDetails,
      children,
      pageMeta,
      isEmpty = false,
      className = "",
      overFlow = true,
      toolTip = false,
    } = this.props;
    let { sortKey: currentSortKey, orderBy = "" } =
      this.state.currentSortKeyDetails || {};

    return (
      <div className="maintable table-container">
        <div
          className={`maintable-content ${
            overFlow ? "table-responsive" : ""
          } ${className}`}
        >
          <table className="table">
            <thead>
              <tr>
                {headerDetails.map(
                  (
                    {
                      label,
                      className,
                      divClass = "",
                      sortKey = "",
                      element,
                      isSort = false,
                    },
                    index
                  ) => {
                    return (
                      <th className={className} key={index}>
                        <div
                          data-tip={
                            label === "Member%"
                              ? "Members uploaded / Membership Base"
                              : label === "FIG%"
                              ? "Members Mapped / Membership Base"
                              : label === "KYM%"
                              ? "Total Uploaded fields / Total Required fields"
                              : ""
                          }
                          className={`d-flex  align-items-center justify-content-start text-center fs-16 ${
                            sortKey && "cursor-pointer"
                          }  ${toolTip && "flex-column"} ${divClass}`}
                        >
                          {label}
                          {toolTip && (
                            <>
                              <br />
                              <p>
                                {label === "Member%" ||
                                label === "FIG%" ||
                                label === "KYM%" ? (
                                  <img
                                    src={infoIcon}
                                    alt="infoIcon"
                                    height="20"
                                    width="20"
                                    className="cursor-pointer"
                                  />
                                ) : (
                                  ""
                                )}
                              </p>
                            </>
                          )}
                          {element && element()}
                          {isSort ? (
                            <div
                              className={`d-flex table-filter align-items-center ml-2
                                                            'active-filter'}`}
                            ></div>
                          ) : (
                            ""
                          )}
                        </div>
                        {toolTip && <ReactTooltip />}
                      </th>
                    );
                  }
                )}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
          {!isEmpty ? this.getPlaceHolder() : ""}
        </div>
        {pageMeta && (
          <Pagination
            handleChange={this.handlePagination}
            pageMeta={pageMeta}
          />
        )}
      </div>
    );
  }
}

TableWrapper.propTypes = {
  placeHolder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isEmpty: PropTypes.bool,
  headerDetails: PropTypes.array.isRequired,
  pageMeta: PropTypes.object,
};
