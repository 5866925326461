import { faqApi } from "service/apiVariables";

import { addQuery } from "service/helperFunctions";

// Get list of Faq
export const getFaqList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, faqApi.getFaqList);

    return new Promise((resolve, reject) => {
      api({ ...faqApi.getFaqList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

// Add Faq
export const addFaq =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...faqApi.addFaq, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Delete FAQ
export const deleteFaq =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { deleteFaq } = faqApi;

    deleteFaq.id = id;

    return new Promise((resolve, reject) => {
      api({ ...faqApi.deleteFaq })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Update FAQ
export const updateFaq =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...faqApi.updateFaq, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Get FAQ by Id
export const getFaqById =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFaqById } = faqApi;

    getFaqById.id = body.id;

    return new Promise((resolve, reject) => {
      api({ ...faqApi.getFaqById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
