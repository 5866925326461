import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { NormalButton } from "component/common";
import "./style.scss";

export class DeletePopupClass extends Component {
  render() {
    let {
      className = "modal-dialog-centered delete-popup",
      isOpen = true,
      toggle,
      handleChange,
    } = this.props;

    return (
      <>
        <Modal isOpen={isOpen} toggle={toggle} className={className} size="md">
          <ModalBody className="p-0">
            <div className="d-flex justify-content-end">
              <i
                className="icon-close fs-16 cursor-pointer"
                onClick={toggle}
              ></i>
            </div>
            <div className="">
              <p className="text-green-18 fs-22 line-height-31 font-weight-bold text-center">
                Are sure you want to delete ?
              </p>
              <div className="d-flex justify-content-center my-3">
                <NormalButton
                  label="No"
                  outline={true}
                  className="mr-3"
                  onClick={() => toggle()}
                />
                <NormalButton
                  label="Yes"
                  mainbg={true}
                  onClick={() => handleChange()}
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

let component = DeletePopupClass;

export const DeletePopup = connect(null, null)(component);
