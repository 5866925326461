import React, { Component, Fragment } from "react";
import { getSmsHistory, smsReport, smsIndividualReport } from "action/FPOact";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CommonPageLoader, TableWrapper } from "component/common";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";
import moment from "moment-timezone";
import { getFilePathDownload } from "service/api";
export class SmsHistoryClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smsList: [],
      headerDetails: [
        {
          label: "Date",
        },
        {
          label: "Time stamp",
        },
        {
          label: "FPO ID",
        },
        {
          label: "FPO Name",
        },
        {
          label: "Total Launched",
        },
        {
          label: "User Type",
        },
        {
          label: "SMS Type",
        },
        {
          label: "Action",
        },
      ],
      pageMeta: {},
      pageNo: 1,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getSmsList(this.state.pageNo);
    let status = getSubAdmin();
    if (status) {
      logout();
    }
    this.setState({
      isLoading: false,
    });
  }

  getSmsList = (pageNo) => {
    const { getSmsHistory } = this.props;
    getSmsHistory({ page: pageNo }).then(({ data: { pageMeta, results } }) => {
      this.setState({
        smsList: results,
        pageMeta: pageMeta,
        pageNo: pageNo,
        isLoading: false,
      });
    });
  };

  downloadReport = () => {
    let { smsReport } = this.props;
    smsReport().then((data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sms-report.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  handleRequestFormList = ({ page }) => {
    this.getSmsList(page);
  };

  downloadCsv = (fpoId, fileUrl) => {
    // let { smsIndividualReport } = this.props;
    // let body = {
    //   id: fpoId,
    // };
    // smsIndividualReport(body).then((data) => {
    //   const url = window.URL.createObjectURL(new Blob([data]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "sms-report.csv");
    //   document.body.appendChild(link);
    //   link.click();
    // });
    window.open(getFilePathDownload + fileUrl);
  };

  render() {
    const { smsList, pageMeta, headerDetails, isLoading } = this.state;

    return (
      <Fragment>
        {isLoading ? (
          <CommonPageLoader />
        ) : (
          <Fragment>
            <div className="robocall-download d-flex justify-content-end mt-4">
              <button onClick={this.downloadReport}>Download Report</button>
            </div>
            <div className="row pt-4">
              <div className="col-md-12">
                <div className="table-containermt-4 pt-1">
                  {smsList.length > 0 ? (
                    <TableWrapper
                      headerDetails={headerDetails}
                      queryHandler={this.handleRequestFormList}
                      pageMeta={pageMeta}
                    >
                      {smsList.map(
                        (
                          {
                            fpoId,
                            fpoName,
                            totalLaunched,
                            createdAt,
                            smsTrackRecords: { fileUrl },
                            userType,
                            smsType,
                          },
                          index
                        ) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  {moment(createdAt)
                                    .tz("Asia/Kolkata")
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  {moment(createdAt)
                                    .tz("Asia/Kolkata")
                                    .format("hh:mm a")}
                                </td>
                                <td>{fpoId}</td>
                                <td>{fpoName}</td>
                                <td>{totalLaunched}</td>
                                <td>{userType}</td>
                                <td>{smsType}</td>
                                <td className="robocall-download">
                                  <button
                                    onClick={() =>
                                      this.downloadCsv(fpoId, fileUrl)
                                    }
                                  >
                                    Download
                                  </button>
                                </td>
                              </tr>
                              <tr className="trans-bg">
                                <td className="empty-height" height="12"></td>
                              </tr>
                            </React.Fragment>
                          );
                        }
                      )}
                    </TableWrapper>
                  ) : (
                    <div className="text-center fs-18 text-black py-5">
                      {"No records found !!!"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSmsHistory,
      smsReport,
      smsIndividualReport,
    },
    dispatch
  );
};

let component = SmsHistoryClass;

export const SmsHistory = connect(null, mapDispatchToProps)(component);
