import React, { Component, Fragment } from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  getRoboCallRequestList,
  rejectRoboCall,
  acceptRoboCall,
} from "action/dashboard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CommonPageLoader, TableWrapper } from "component/common";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";
import moment from "moment-timezone";

import "pages/Admin/requestFormList/requestFormList.scss";

export class RoboCallRequestClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      headerDetails: [
        {
          label: "Scheduled Date",
        },
        {
          label: "Scheduled Time",
        },
        // {
        //   label: "FPO ID",
        // },
        {
          label: "Program Name",
        },
        {
          label: "Alert Name",
        },
        {
          label: "Member Count",
        },
        {
          label: "Audio",
        },
        {
          label: "User Type",
        },
        {
          label: "Action",
        },
      ],
      pageMeta: {},
      pageNo: 1,
      isOpen: false,
      roboCallDetail: {
        responseUrls: [],
        audioUrl: "",
        alertName: "",
      },
      isLoading: true,
    };
  }

  componentDidMount() {
    this.roboCallList(this.state.pageNo);
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }

  roboCallList = (pageNo) => {
    const { getRoboCallRequestList } = this.props;
    getRoboCallRequestList({ page: pageNo }).then(
      ({ data: { pageMeta, results } }) => {
        this.setState({
          requestList: results,
          pageMeta: pageMeta,
          pageNo: pageNo,
          isLoading: false,
        });
      }
    );
  };

  updateRoboCall = (isApproved, id) => {
    const { acceptRoboCall, rejectRoboCall } = this.props;
    const { pageNo } = this.state;

    if (isApproved) {
      acceptRoboCall({ requestId: id }).then(() => {
        this.roboCallList(pageNo);
      });
    } else {
      rejectRoboCall({ requestId: id }).then(() => {
        this.roboCallList(pageNo);
      });
    }
  };

  viewRoboCall = (data) => {
    this.setState(
      {
        roboCallDetail: data,
      },
      () => {
        this.toggle();
      }
    );
  };

  closePopUp = () => {
    this.setState(
      {
        roboCallDetail: {
          alertName: "",
          responseUrls: [],
          audioUrl: "",
        },
      },
      () => {
        this.toggle();
      }
    );
  };

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  handlePlay = async (event) => {
    event.persist();
    const audio = document.querySelectorAll("audio");
    const { id } = event.target;
    await audio.forEach((list) => {
      if (list.id !== id) {
        list.currentTime = 0;
        list.pause();
      }
    });
    document.getElementById(id).play();
  };

  handleRequestFormList = ({ page }) => {
    this.roboCallList(page);
  };

  render() {
    const {
      requestList,
      pageMeta,
      headerDetails,
      isOpen,
      isLoading,
      roboCallDetail: { audioUrl, responseUrls, alertName },
    } = this.state;

    return (
      <Fragment>
        {isLoading ? (
          <CommonPageLoader />
        ) : (
          <Fragment>
            <div className="requestFormList">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-containermt-4 pt-1">
                    {requestList?.length > 0 ? (
                      <TableWrapper
                        headerDetails={headerDetails}
                        queryHandler={this.handleRequestFormList}
                        pageMeta={pageMeta}
                      >
                        {requestList?.map(
                          (
                            {
                              programName,
                              // fpoId,
                              totalMembers,
                              requestId,
                              responseUrls,
                              audioUrl,
                              alertName,
                              userType,
                              scheduleDate,
                              scheduleTime,
                            },
                            index
                          ) => {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    {scheduleDate
                                      ? moment(scheduleDate)
                                          .tz("Asia/Kolkata")
                                          .format("DD-MM-YYYY")
                                      : "-"}
                                  </td>
                                  <td>
                                    {scheduleTime
                                      ? moment(scheduleTime)
                                          .tz("Asia/Kolkata")
                                          .format("hh:mm a")
                                      : "-"}
                                  </td>
                                  {/* <td>{fpoId}</td> */}
                                  <td>{programName || "-"}</td>
                                  <td>{alertName}</td>
                                  <td align="center">{totalMembers}</td>
                                  <td align="center">
                                    <i
                                      className="far fa-file-audio"
                                      style={{
                                        fontSize: "24px",
                                        fontWeight: "normal",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.viewRoboCall({
                                          alertName,
                                          audioUrl,
                                          responseUrls,
                                        })
                                      }
                                    ></i>
                                  </td>
                                  <td
                                    align="center"
                                    className="text-capitalize"
                                  >
                                    {userType}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center h-100">
                                      <span
                                        className=" mr-3 cursor-pointer approve-text"
                                        onClick={() =>
                                          this.updateRoboCall(true, requestId)
                                        }
                                      >
                                        Approve
                                      </span>
                                      <span
                                        className=" mr-3 cursor-pointer approve-text text-danger"
                                        onClick={() =>
                                          this.updateRoboCall(false, requestId)
                                        }
                                      >
                                        Reject
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="trans-bg">
                                  <td className="empty-height" height="12"></td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                      </TableWrapper>
                    ) : (
                      <div className="text-center fs-18 text-black py-5">
                        {"No records found !!!"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={isOpen} toggle={() => this.closePopUp()}>
              <ModalBody>
                <div className="robocall-popup">
                  <div className="robocall-title">
                    <b>RoboCall Details</b>
                    <i
                      className="fas fa-times"
                      onClick={() => this.closePopUp()}
                    ></i>
                  </div>
                  <div className="robocall-alert">
                    <b>Alert Name </b>
                    <span>{alertName}</span>
                  </div>
                  <div className="main-audio">
                    <b>Main Message</b>
                    <audio controls onPlay={this.handlePlay} id="mainMsg">
                      <source src={audioUrl}></source>
                    </audio>
                  </div>
                  <div>
                    <div className="response-audio">
                      {responseUrls?.map((list, index) => {
                        return (
                          <div className="response-audio-card" key={index}>
                            <div>
                              <b>Press {index + 1} Response</b>
                            </div>
                            <div>
                              <audio
                                controls
                                onPlay={this.handlePlay}
                                id={`response-${index + 1}`}
                              >
                                <source src={list}></source>
                              </audio>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRoboCallRequestList,
      acceptRoboCall,
      rejectRoboCall,
    },
    dispatch
  );
};

let component = RoboCallRequestClass;

export const RoboCallRequest = connect(null, mapDispatchToProps)(component);
