import { complianceCalender } from 'service/apiVariables';

import { addQuery } from 'service/helperFunctions';

// Get list of compliance list
export const getcomplianceCalenderList = (query) => (dispatch, getState, { api, Toast }) => {
   addQuery(query, complianceCalender.complianceCalenderList);

   return new Promise((resolve, reject) => {
      api({ ...complianceCalender.complianceCalenderList })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Compliance Task By Id
export const getComplianceTaskById = (id) => (dispatch, getState, { api, Toast }) => {

   let { getComplianceTaskById } = complianceCalender

   getComplianceTaskById.id = id

   return new Promise((resolve, reject) => {
      api({ ...complianceCalender.getComplianceTaskById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//add Fpo Task
export const addFpoTask = (body) => (dispatch, getState, { api, Toast }) => {

   return new Promise((resolve, reject) => {
      api({ ...complianceCalender.addFpoTask, body })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message })
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//update Fpo Task
export const updateFpoTask = (body) => (dispatch, getState, { api, Toast }) => {

   return new Promise((resolve, reject) => {
      api({ ...complianceCalender.updateFpoTask, body })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message })
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};