import React, { Component } from "react";
import { connect } from "react-redux";
import { CommonPageLoader } from "component/common";
import { Modal, ModalBody, Collapse, Row, Col } from "reactstrap";
import moment from "moment";
import { getFilePathDownload } from "service/api";
import { getDescriptionData } from "service/helperFunctions";

const pdf = require("assets/svg/Documents/pdf.svg");
const download = require("assets/svg/Documents/download.svg");
const pdfRed = require("assets/svg/member_view/pdfRed.svg");
export class ViewMemberPopupClass extends Component {
  state = {
    isBasicDetails: true,
    isHouseHold: false,
    isFarm: false,
    isAssets: false,
    isLiveStock: false,
    isBank: false,
  };

  toggle = (name) => {
    switch (name) {
      case "isBasicDetails": {
        this.setState({
          isBasicDetails: !this.state[name],
          isHouseHold: false,
          isFarm: false,
          isAssets: false,
          isLiveStock: false,
          isBank: false,
        });
        break;
      }
      case "isHouseHold": {
        this.setState({
          isBasicDetails: false,
          isHouseHold: !this.state[name],
          isFarm: false,
          isAssets: false,
          isLiveStock: false,
          isBank: false,
        });
        break;
      }
      case "isFarm": {
        this.setState({
          isBasicDetails: false,
          isHouseHold: false,
          isFarm: !this.state[name],
          isAssets: false,
          isLiveStock: false,
          isBank: false,
        });
        break;
      }
      case "isAssets": {
        this.setState({
          isBasicDetails: false,
          isHouseHold: false,
          isFarm: false,
          isAssets: !this.state[name],
          isLiveStock: false,
          isBank: false,
        });
        break;
      }
      case "isLiveStock": {
        this.setState({
          isBasicDetails: false,
          isHouseHold: false,
          isFarm: false,
          isAssets: false,
          isLiveStock: !this.state[name],
          isBank: false,
        });
        break;
      }
      case "isBank": {
        this.setState({
          isBasicDetails: false,
          isHouseHold: false,
          isFarm: false,
          isAssets: false,
          isLiveStock: false,
          isBank: !this.state[name],
        });
        break;
      }
      default: {
        this.setState({
          [name]: !this.state[name],
        });
      }
    }
  };
  handleModelToggle = () => {
    this.toggle("isBasicDetails");
    this.props.toggle();
  };
  render() {
    let {
      className = "modal-dialog-centered fpo-popups",
      isOpen = true,
      toggle,
      isLoading = false,
      memberDetail,
    } = this.props;

    let {
      memberFirstName = "",
      contact = "-",
      memberLastName = "",
      phone = "-",
      gender = "-",
      memberDOB = "-",
      address = "-",
      village = "-",
      household = [{}],
      memberfarminfo = {},
      memberasset = [{}],
      memberLiveStockInfo = [{}],
      memberBankAccount = [{}],
      addressProofName = "",
      addressProofDocumentUrl = "",
      identityProofDocumentUrl = "",
      identityProofName = "",
      districtNames = {},
      stateMaster = {},
      pinCodes = {},
    } = memberDetail;

    let { maleMembers = 0, femaleMembers = 0 } =
      household.length > 0
        ? household?.[0]
        : [{ maleMembers: 0, femaleMembers: 0 }];
    let {
      plotAcreage = 0,
      rabicropmapping = [],
      kharifcropmapping = [],
      livecropmapping = [],
      plotGeolocation = "",
      plotGeolocationName = "",
    } = memberfarminfo === null ? {} : memberfarminfo;

    let {
      acountNumber = "-",
      bankName = "-",
      bankBranch = "-",
      IFSC = "-",
      accountType = "-",
      chequeImageName = null,
      chequeImageUrl = "-",
    } = memberBankAccount.length > 0 ? memberBankAccount?.[0] : {};

    const {
      isBasicDetails,
      isHouseHold,
      isFarm,
      isAssets,
      isLiveStock,
      isBank,
    } = this.state;
    return (
      <>
        <Modal
          isOpen={isOpen}
          toggle={this.handleModelToggle}
          className={className}
          size="lg"
        >
          <ModalBody className="p-0">
            <div className="d-flex justify-content-between pb-2">
              <h5 className="px-0 pb-1 text-green-18 fs-26 font-Gilroy-Bold">
                Member details
              </h5>
              <i
                className="icon-close fs-20 cursor-pointer"
                onClick={toggle}
              ></i>
            </div>
            {!isLoading ? (
              <div className="px-1 py-4 member-view">
                {/* Basic Details */}
                <div
                  className="d-flex justify-content-between align-items-center toggle-bar cursor-pointer"
                  onClick={() => this.toggle("isBasicDetails")}
                >
                  <span className="fs-20 fw-600">Basic details</span>
                  <span class="icon-down fs-10 text-black"></span>
                </div>
                <Collapse isOpen={isBasicDetails}>
                  <div className="px-4 py-4 ">
                    <div className="fs-20 fw-600 mb-4">Basic profile</div>
                    <Row>
                      <Col sm="6" className="mb-4">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            First name
                          </Col>
                          <Col sm="6" className="content">
                            {memberFirstName !== null ? memberFirstName : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Contact
                          </Col>
                          <Col sm="6" className="content">
                            {contact !== null ? contact : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6" className="mb-4">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Last name
                          </Col>
                          <Col sm="6" className="content">
                            {memberLastName !== null ? memberLastName : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Phone number
                          </Col>
                          <Col sm="6" className="content">
                            {phone !== null ? phone : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6" className="mb-4">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Gender
                          </Col>
                          <Col sm="6" className="content">
                            {gender !== null ? gender : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            DOB
                          </Col>
                          <Col sm="6" className="content">
                            {memberDOB !== null
                              ? moment(memberDOB).format("DD/MM/YYYY")
                              : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6" className="mb-4 title">
                        <Row>
                          <Col sm="6" className="fw-600">
                            Address
                          </Col>
                          <Col sm="6" className="content">
                            {address !== null ? address : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 mb-4 title">
                            Village
                          </Col>
                          <Col sm="6" className="content">
                            {village !== null ? village : "-"}
                          </Col>
                          <Col sm="6" className="fw-600 mb-4 title">
                            District
                          </Col>
                          <Col sm="6" className="content">
                            {districtNames !== null
                              ? districtNames?.districtName
                              : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6" className="mb-4">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Pincode
                          </Col>
                          <Col sm="6" className="content">
                            {pinCodes !== null ? pinCodes?.pincode : "-"}
                            {pinCodes?.pincode}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            State
                          </Col>
                          <Col sm="6" className="content">
                            {stateMaster !== null
                              ? stateMaster?.stateName
                              : "-"}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="fs-20 fw-600 mb-2 title">Proof</div>
                    <Row>
                      <Col sm="6">
                        <div className="doc-title mb-4">{"Address proof"}</div>
                        {addressProofName ? (
                          <>
                            <div className="fs-18 fw-800 d-flex align-items-center">
                              <img src={pdfRed} className="mr-3" />
                              {getDescriptionData(addressProofName, 30)}
                            </div>
                            <div className="upload-component p-3 d-flex justify-content-center align-items-center mt-3">
                              <div className="w-100 h-100 rounded d-flex justify-content-between">
                                <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                                  <div>
                                    <div className="d-flex justify-content-center">
                                      {addressProofName &&
                                      addressProofName.includes("pdf") ? (
                                        <img
                                          className="uploaded-img-area"
                                          src={pdf}
                                          alt="pdf"
                                        />
                                      ) : (
                                        <img
                                          className="uploaded-img-area rounded"
                                          src={addressProofDocumentUrl}
                                          alt="file"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between align-items-end h-100">
                                <div className="d-flex flex-column">
                                  <a
                                    href={
                                      addressProofName &&
                                      getFilePathDownload +
                                        addressProofDocumentUrl
                                    }
                                    download
                                  >
                                    <img
                                      className="mb-3"
                                      src={download}
                                      alt="download"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </Col>
                      <Col sm="6">
                        <div className="doc-title mb-4">Identity proof</div>
                        {identityProofName ? (
                          <>
                            <div className="fs-18 fw-800 d-flex align-items-center">
                              <img src={pdfRed} className="mr-3" />
                              {getDescriptionData(identityProofName, 30)}
                            </div>
                            <div className="upload-component p-3 d-flex justify-content-center align-items-center mt-3">
                              <div className="w-100 h-100 rounded d-flex justify-content-between">
                                <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                                  <div>
                                    <div className="d-flex justify-content-center">
                                      {identityProofName &&
                                      identityProofName.includes("pdf") ? (
                                        <img
                                          className="uploaded-img-area"
                                          src={pdf}
                                          alt="pdf"
                                        />
                                      ) : (
                                        <img
                                          className="uploaded-img-area rounded"
                                          src={identityProofDocumentUrl}
                                          alt="file"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-between align-items-end h-100">
                                <div className="d-flex flex-column">
                                  <a
                                    href={
                                      identityProofName &&
                                      getFilePathDownload +
                                        identityProofDocumentUrl
                                    }
                                    download
                                  >
                                    <img
                                      className="mb-3"
                                      src={download}
                                      alt="download"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </Col>
                    </Row>
                  </div>
                </Collapse>
                {/* House hold */}
                <div
                  className="d-flex justify-content-between align-items-center toggle-bar cursor-pointer mt-3"
                  onClick={() => this.toggle("isHouseHold")}
                >
                  <span className="fs-20 fw-600">House hold</span>
                  <span class="icon-down fs-10 text-black"></span>
                </div>
                <Collapse isOpen={isHouseHold}>
                  <div className="px-4 py-4 ">
                    <div className="fs-20 fw-600 mb-4">House hold</div>
                    <Row>
                      <Col sm="12" className="mb-4">
                        <Row>
                          <Col sm="3" className="fw-600 title">
                            Member
                          </Col>
                          <Col sm="3" className="content">
                            {memberFirstName ? memberFirstName : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Male members
                          </Col>
                          <Col sm="6" className="content">
                            {maleMembers}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Female members
                          </Col>
                          <Col sm="6" className="content">
                            {femaleMembers}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
                {/* Farm */}
                <div
                  className="d-flex justify-content-between align-items-center toggle-bar cursor-pointer mt-3"
                  onClick={() => this.toggle("isFarm")}
                >
                  <span className="fs-20 fw-600">Farm</span>
                  <span class="icon-down fs-10 text-black"></span>
                </div>
                <Collapse isOpen={isFarm}>
                  <div className="px-4 py-4 ">
                    <div className="fs-20 fw-600 mb-4">Farm</div>
                    <Row>
                      <Col sm="6" className="mb-4">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Member
                          </Col>
                          <Col sm="6">
                            {memberFirstName ? memberFirstName : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Plot acreage
                          </Col>
                          <Col sm="6">{plotAcreage ? plotAcreage : "-"}</Col>
                        </Row>
                      </Col>
                      <Col sm="12" className="mb-4">
                        <Row>
                          <Col sm="12" className="fw-600 mb-2">
                            Uploads
                          </Col>
                          <Col sm="12" className="doc-title mb-4">
                            Plot geolocation file
                          </Col>
                          <Col>
                            {plotGeolocationName ? (
                              <>
                                <div className="fs-18 fw-800 d-flex align-items-center">
                                  <img src={pdfRed} className="mr-3" />
                                  {getDescriptionData(plotGeolocationName, 30)}
                                </div>
                                <div className="upload-component p-3 d-flex justify-content-center align-items-center mt-3">
                                  <div className="w-100 h-100 rounded d-flex justify-content-between">
                                    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                                      <div>
                                        <div className="d-flex justify-content-center">
                                          {plotGeolocationName &&
                                          plotGeolocationName.includes(
                                            "pdf"
                                          ) ? (
                                            <img
                                              className="uploaded-img-area"
                                              src={pdf}
                                              alt="pdf"
                                            />
                                          ) : (
                                            <img
                                              className="uploaded-img-area rounded"
                                              src={plotGeolocation}
                                              alt="file"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column justify-content-between align-items-end h-100">
                                    <div className="d-flex flex-column">
                                      <a
                                        href={
                                          plotGeolocation &&
                                          getFilePathDownload + plotGeolocation
                                        }
                                        download
                                      >
                                        <img
                                          className="mb-3"
                                          src={download}
                                          alt="download"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              "-"
                            )}
                          </Col>
                        </Row>
                      </Col>
                      {rabicropmapping?.length > 0 ? (
                        <div className="col-12 mb-3">
                          <div className="row">
                            <div className="col-12">
                              <p className="mb-2 fw-700 text-black-50 fs-18">
                                rabi crops (October - March)
                              </p>
                              <div className="flex-wrap d-flex">
                                {rabicropmapping.map(
                                  ({ cropsDetails }, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`d-flex flex-column crops-container-selected mr-2 mb-3`}
                                      >
                                        <img
                                          className="crops-container-img"
                                          src={cropsDetails.cropIconUrl}
                                          alt="crops"
                                        />
                                        <p className="mb-0 fs-10 text-green-18">
                                          {cropsDetails.cropName}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {kharifcropmapping?.length > 0 ? (
                        <div className="col-12 mb-3">
                          <div className="row">
                            <div className="col-12">
                              <p className="mb-2 fw-700 text-black-50 fs-18">
                                kharif crops (July - October)
                              </p>
                              <div className="flex-wrap d-flex">
                                {kharifcropmapping.map(
                                  ({ cropsDetails }, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`d-flex flex-column crops-container-selected mr-2 mb-3`}
                                      >
                                        <img
                                          className="crops-container-img"
                                          src={cropsDetails.cropIconUrl}
                                          alt="crops"
                                        />
                                        <p className="mb-0 fs-10 text-green-18">
                                          {cropsDetails.cropName}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {livecropmapping?.length > 0 ? (
                        <div className="col-12 mb-3">
                          <div className="row">
                            <div className="col-12">
                              <p className="mb-2 fw-700 text-black-50 fs-18">
                                Livestock crops
                              </p>
                              <div className="flex-wrap d-flex">
                                {livecropmapping.map(
                                  ({ cropsDetails }, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`d-flex flex-column crops-container-selected mr-2`}
                                      >
                                        <img
                                          className="crops-container-img"
                                          src={cropsDetails.cropIconUrl}
                                          alt="crops"
                                        />
                                        <p className="mb-0 fs-10 text-green-18">
                                          {cropsDetails.cropName}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Row>
                  </div>
                </Collapse>
                {/* Assets */}
                <div
                  className="d-flex justify-content-between align-items-center toggle-bar cursor-pointer mt-3"
                  onClick={() => this.toggle("isAssets")}
                >
                  <span className="fs-20 fw-600">Assets</span>
                  <span class="icon-down fs-10 text-black"></span>
                </div>
                <Collapse isOpen={isAssets}>
                  <div className="px-4 py-4 ">
                    <div className="fs-20 fw-600 mb-4">Assets</div>
                    <Row>
                      <Col sm="12" className="mb-3">
                        <Row>
                          <Col sm="3" className="fw-600 title">
                            Member
                          </Col>
                          <Col sm="3" className="content">
                            {memberFirstName ? memberFirstName : "-"}
                          </Col>
                        </Row>
                      </Col>
                      {memberasset.length > 0 ? (
                        memberasset.map(
                          (
                            {
                              assetmaster,
                              quantity,
                              AcquiredOn,
                              description,
                              assetStatus,
                              area,
                              location,
                              customHireAvailability,
                            },
                            index
                          ) => {
                            return (
                              <>
                                <hr className="w-100"></hr>
                                <Col
                                  sm="12"
                                  className="font-weight-bold fs-20 mb-2"
                                >
                                  {index + 1}. Asset
                                </Col>
                                <Col sm="6" className="mb-2">
                                  <Row>
                                    <Col sm="6" className="fw-600 title">
                                      Asset type
                                    </Col>
                                    <Col sm="6" className="content">
                                      {assetmaster
                                        ? assetmaster?.assetName
                                        : "-"}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm="6" className="mb-2">
                                  <Row>
                                    <Col sm="6" className="fw-600 mb-2 title">
                                      Asset quantity
                                    </Col>
                                    <Col sm="6" className="content">
                                      {quantity !== null ? quantity : "0"}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm="6" className="mb-2">
                                  <Row>
                                    <Col sm="6" className="fw-600 mb-2 title">
                                      Acquired on
                                    </Col>
                                    <Col sm="6" className="content">
                                      {AcquiredOn !== null
                                        ? moment(AcquiredOn).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                    </Col>
                                    <Col sm="6" className="fw-600 title">
                                      Asset status
                                    </Col>
                                    <Col sm="6" className="content">
                                      {assetStatus ? assetStatus : "-"}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm="6" className="mb-2">
                                  <Row>
                                    <Col sm="6" className="fw-600 title">
                                      Description
                                    </Col>
                                    <Col sm="6" className="content">
                                      {description !== null ? description : "-"}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm="12" className="mb-2">
                                  <Row>
                                    <Col sm="3" className="fw-600 title">
                                      Area
                                    </Col>
                                    <Col sm="3" className="content">
                                      {area ? area : "-"}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm="6" className="mb-2">
                                  <Row>
                                    <Col sm="6" className="fw-600 title">
                                      Location
                                    </Col>
                                    <Col sm="6" className="content">
                                      {location !== null ? location : "-"}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm="6" className="mb-2">
                                  <Row>
                                    <Col sm="6" className="fw-600 title">
                                      Custom hire availability
                                    </Col>
                                    <Col sm="6" className="content">
                                      {customHireAvailability
                                        ? customHireAvailability
                                        : "-"}
                                    </Col>
                                  </Row>
                                </Col>
                              </>
                            );
                          }
                        )
                      ) : (
                        <div className="content text-center w-100">
                          {"No assets"}
                        </div>
                      )}
                    </Row>
                  </div>
                </Collapse>
                {/* Live stock */}
                <div
                  className="d-flex justify-content-between align-items-center toggle-bar cursor-pointer mt-3"
                  onClick={() => this.toggle("isLiveStock")}
                >
                  <span className="fs-20 fw-600">Live stock</span>
                  <span class="icon-down fs-10 text-black"></span>
                </div>
                <Collapse isOpen={isLiveStock}>
                  <div className="px-4 py-4 ">
                    <div className="fs-20 fw-600 mb-4">Live stock</div>
                    <Row>
                      <Col sm="12" className="mb-4">
                        <Row>
                          <Col sm="3" className="fw-600 title">
                            Member
                          </Col>
                          <Col sm="3" className="content">
                            {memberFirstName ? memberFirstName : "-"}
                          </Col>
                        </Row>
                      </Col>
                      {memberLiveStockInfo.length > 0 ? (
                        memberLiveStockInfo.map(
                          (
                            {
                              liveStockMaster,
                              liveStockBreed,
                              liveStockCount,
                              liveStockAvgAge,
                              liveStockInsurance,
                            },
                            index
                          ) => {
                            return (
                              <>
                                <hr className="w-100"></hr>
                                <Col
                                  sm="12"
                                  className="font-weight-bold fs-20 mb-2"
                                >
                                  {index + 1}. Live stock
                                </Col>
                                <Col sm="12" className="mb-4">
                                  <Row>
                                    <Col sm="12">
                                      <Row>
                                        <Col
                                          sm="3"
                                          className="fw-600 mb-2 title"
                                        >
                                          Livestock
                                        </Col>
                                        <Col sm="3" className="content">
                                          {liveStockMaster
                                            ? liveStockMaster?.liveStockName
                                            : "-"}
                                        </Col>
                                      </Row>
                                    </Col>

                                    <Col sm="6" className="mb-2">
                                      <Row>
                                        <Col sm="6" className="fw-600 title">
                                          Livestock breed
                                        </Col>
                                        <Col sm="6" className="content">
                                          {liveStockBreed
                                            ? liveStockBreed
                                            : "-"}
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col sm="6" className="mb-2">
                                      <Row>
                                        <Col sm="6" className="fw-600 title">
                                          Livestock count
                                        </Col>
                                        <Col sm="6" className="content">
                                          {liveStockCount
                                            ? liveStockCount
                                            : "0"}
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col sm="6" className="mb-2">
                                      <Row>
                                        <Col sm="6" className="fw-600 title">
                                          Livestock average age
                                        </Col>
                                        <Col sm="6" className="content">
                                          {liveStockAvgAge
                                            ? liveStockAvgAge
                                            : "0"}
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col sm="6" className="mb-2">
                                      <Row>
                                        <Col sm="6" className="fw-600 title">
                                          Livestock insurance
                                        </Col>
                                        <Col sm="6" className="content">
                                          {liveStockInsurance
                                            ? liveStockInsurance
                                            : "-"}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </>
                            );
                          }
                        )
                      ) : (
                        <div className="content text-center w-100">
                          {"No live stock"}
                        </div>
                      )}
                    </Row>
                  </div>
                </Collapse>
                {/* Bank */}
                <div
                  className="d-flex justify-content-between align-items-center toggle-bar cursor-pointer mt-3"
                  onClick={() => this.toggle("isBank")}
                >
                  <span className="fs-20 fw-600">Bank</span>
                  <span class="icon-down fs-10 text-black"></span>
                </div>
                <Collapse isOpen={isBank}>
                  <div className="px-4 py-4 ">
                    <div className="fs-20 fw-600 mb-4">Bank</div>
                    <Row>
                      <Col sm="6" className="mb-3">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Member
                          </Col>
                          <Col sm="6" className="content">
                            {memberFirstName}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Account number
                          </Col>
                          <Col sm="6" className="content">
                            {acountNumber ? acountNumber : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6" className="mb-3">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Bank name
                          </Col>
                          <Col sm="6" className="content">
                            {bankName !== null ? bankName : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Bank branch
                          </Col>
                          <Col sm="6" className="content">
                            {bankBranch ? bankBranch : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6" className="mb-3">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            IFSC
                          </Col>
                          <Col sm="6" className="content">
                            {IFSC !== null ? IFSC : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="6">
                        <Row>
                          <Col sm="6" className="fw-600 title">
                            Account type
                          </Col>
                          <Col sm="6" className="content">
                            {accountType ? accountType : "-"}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="12" className="mb-4">
                        <Row>
                          <Col sm="12" className="fw-600 mb-2">
                            Uploads
                          </Col>
                          <Col sm="12" className="doc-title mb-4">
                            Check/Passbook image
                          </Col>
                          <Col>
                            {chequeImageName ? (
                              <>
                                <div className="fs-18 fw-800 d-flex align-items-center">
                                  <img src={pdfRed} className="mr-3" />
                                  {getDescriptionData(chequeImageName, 30)}
                                </div>
                                <div className="upload-component p-3 d-flex justify-content-center align-items-center mt-3">
                                  <div className="w-100 h-100 rounded d-flex justify-content-between">
                                    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                                      <div>
                                        <div className="d-flex justify-content-center">
                                          {chequeImageName.includes("pdf") ? (
                                            <img
                                              className="uploaded-img-area"
                                              src={pdf}
                                              alt="pdf"
                                            />
                                          ) : (
                                            <img
                                              className="uploaded-img-area rounded"
                                              src={chequeImageUrl}
                                              alt="file"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column justify-content-between align-items-end h-100">
                                    <div className="d-flex flex-column">
                                      <a
                                        href={
                                          getFilePathDownload + chequeImageUrl
                                        }
                                        download
                                      >
                                        <img
                                          className="mb-3"
                                          src={download}
                                          alt="download"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              "-"
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </div>
            ) : (
              <>
                {/* Loader */}
                <CommonPageLoader />
              </>
            )}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

let component = ViewMemberPopupClass;

export const ViewMemberPopup = connect(null, null)(component);
