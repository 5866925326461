import React, { Component } from "react";

import "./AddCourse.scss";

import { connect } from "react-redux";

import {
  NormalInput,
  NormalButton,
  DragFileUpload,
  NormalEditor,
} from "component/common";
import odpImage from "assets/images/odp.png";
import pdfImage from "assets/images/pdf.png";

import { history } from "service/helpers";

import uploaderVideo from "assets/images/video-upload.png";

import { bindActionCreators } from "redux";

import SimpleReactValidator from "simple-react-validator";

import { addSections, fileUpload, updateSections } from "action/CourseAct";

import { Toast } from "service/toast";

export class SectionsClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        sectionArray: [
          {
            sectionTitle: "",
            sectionDescriptions: "",
            chapter: [
              {
                chapterTitle: "",
                video: [],
                videoArray: [],
                fileName: "",
                fileType: "",
                fileTypeRequest: "video",
                isImageUploading: false,
              },
            ],
          },
        ],
      },
      isNewlyAddedData: false,
    };
    //validation
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="error-message text-danger fs-16 pb-3">{message}</span>
      ),
      autoForceUpdate: this,
    });
  }

  componentDidMount() {
    let { isEdit, courseDetail } = this.props;
    if (isEdit) {
      let { sections = [] } = courseDetail;
      this.updateData(sections);
    }
  }

  updateData = async (sections) => {
    // description
    let sectionArrayList = [];

    sections.map((data, index) => {
      let { chapters } = data;

      let sectionDetail = {
        sectionId: data.sectionId,
        sectionTitle: data.title,
        sectionDescriptions: data.objectiveSection,
        sectionisDeleted: false,
        isAdded: false,
        isUpdate: true,
        chapter: [],
      };

      chapters.map((dataChapter, indexChapter) => {
        let chaptersDetail = {
          video: [dataChapter.chapterFileUrl],
          videoArray: [{}],
          fileName: dataChapter.chapterFileName,
          fileType: dataChapter.fileType,
          fileTypeRequest: dataChapter.fileType,
          isImageUploading: false,
          isDeleted: false,
          isAdded: false,
          chapterId: dataChapter.chapterId,
          chapterTitle: dataChapter.title,
          isUpdate: true,
        };

        sectionDetail.chapter.push(chaptersDetail);

        if (
          sections.length === index + 1 &&
          chapters.length === indexChapter + 1
        ) {
        }
      });

      sectionArrayList.push(sectionDetail);
    });

    let formFields = {};

    if (sections.length != 0) {
      formFields = { sectionArray: sectionArrayList };
      this.setState({ isNewlyAddedData: false });
    } else {
      formFields = {
        sectionArray: [
          {
            sectionTitle: "",
            sectionDescriptions: "",
            chapter: [
              {
                chapterTitle: "",
                video: [],
                videoArray: [],
                fileName: "",
                fileType: "",
                fileTypeRequest: "video",
                isImageUploading: false,
              },
            ],
          },
        ],
      };
      this.setState({ isNewlyAddedData: true });
    }

    this.setState({ formFields });
  };

  handleInput = ({ target: { name, value } }, index, chapterIndex) => {
    //handle inputs

    let { formFields } = this.state;

    if (name === "sectionDescriptions" || name === "sectionTitle") {
      formFields["sectionArray"][index][name] = value;
    } else {
      formFields["sectionArray"][index]["chapter"][chapterIndex][name] = value;
    }

    this.setState({
      formFields,
    });
  };

  handleFileInput = ({ target: { name, value } }, index, chapterIndex) => {
    //handle inputs file
    let { formFields } = this.state;

    if (value !== undefined) {
      formFields["sectionArray"][index]["chapter"][chapterIndex][
        "videoArray"
      ][0] = value;
      formFields["sectionArray"][index]["chapter"][chapterIndex][
        "isImageUploading"
      ] = true;
      formFields["sectionArray"][index]["chapter"][chapterIndex]["fileType"] =
        value.type;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            `video-upload-${index}-chapter-${chapterIndex}`
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", value);

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          if (
            formFields["sectionArray"][index]["chapter"][chapterIndex][
              "fileType"
            ].includes("video")
          ) {
            formFields["sectionArray"][index]["chapter"][chapterIndex][
              "video"
            ][0] = data[0].fullUrl;
          } else {
            formFields["sectionArray"][index]["chapter"][chapterIndex][
              "video"
            ][0] = data[0].fileUrl;
          }
          formFields["sectionArray"][index]["chapter"][chapterIndex][
            "fileName"
          ] = data[0].fileName;
          formFields["sectionArray"][index]["chapter"][chapterIndex][
            "isImageUploading"
          ] = false;

          this.setState({
            formFields,
          });
        } else {
          formFields["sectionArray"][index]["chapter"][chapterIndex][
            "video"
          ] = [];
          formFields["sectionArray"][index]["chapter"][chapterIndex][
            "fileName"
          ] = "";
          formFields["sectionArray"][index]["chapter"][chapterIndex][
            "isImageUploading"
          ] = false;
          this.setState({ formFields });
        }
      });
      this.setState({
        formFields,
      });
    }
  };

  handleChapterAdd = (e, index) => {
    this.validator.hideMessages();

    let formFields = Object.assign({}, this.state.formFields);

    let { isEdit } = this.props;

    if (isEdit) {
      formFields.sectionArray[index].chapter = formFields.sectionArray[
        index
      ].chapter.concat([
        {
          chapterTitle: "",
          video: [],
          videoArray: [],
          fileName: "",
          fileType: "",
          fileTypeRequest: "video",
          isAdded: true,
          isUpdate: false,
          isDeleted: false,
          isImageUploading: false,
        },
      ]);
    } else {
      formFields.sectionArray[index].chapter = formFields.sectionArray[
        index
      ].chapter.concat([
        {
          chapterTitle: "",
          video: [],
          videoArray: [],
          fileName: "",
          fileType: "",
          fileTypeRequest: "video",
          isImageUploading: false,
        },
      ]);
    }

    this.setState({
      formFields,
    });
  };

  handleChapterRemove = (e, chapterIndex, sectionIndex) => {
    this.validator.hideMessages();
    let { formFields } = this.state;
    let { isEdit } = this.props;

    if (isEdit) {
      if (
        formFields.sectionArray[sectionIndex].chapter[chapterIndex][
          "isAdded"
        ] === true
      ) {
        delete formFields.sectionArray[sectionIndex].chapter[chapterIndex];
        formFields.sectionArray[sectionIndex].chapter = formFields.sectionArray[
          sectionIndex
        ].chapter.filter(function (a) {
          return typeof a !== "undefined";
        });
      } else {
        formFields.sectionArray[sectionIndex].chapter[chapterIndex][
          "isDeleted"
        ] = true;
      }
    } else {
      delete formFields.sectionArray[sectionIndex].chapter[chapterIndex];
      formFields.sectionArray[sectionIndex].chapter = formFields.sectionArray[
        sectionIndex
      ].chapter.filter(function (a) {
        return typeof a !== "undefined";
      });
    }

    this.setState({
      formFields,
    });
  };

  handleSectionAdd = (e) => {
    this.validator.hideMessages();

    let formFields = Object.assign({}, this.state.formFields);

    let { isEdit } = this.props;

    if (isEdit) {
      formFields.sectionArray = formFields.sectionArray.concat([
        {
          sectionTitle: "",
          sectionDescriptions: "",
          sectionisDeleted: false,
          isAdded: true,
          isUpdate: false,
          chapter: [
            {
              chapterTitle: "",
              video: [],
              videoArray: [],
              fileName: "",
              fileType: "",
              fileTypeRequest: "video",
              isImageUploading: false,
              isDeleted: false,
              isAdded: true,
              isUpdate: false,
            },
          ],
        },
      ]);
    } else {
      formFields.sectionArray = formFields.sectionArray.concat([
        {
          sectionTitle: "",
          sectionDescriptions: "",
          chapter: [
            {
              chapterTitle: "",
              video: [],
              videoArray: [],
              fileName: "",
              fileType: "",
              fileTypeRequest: "video",
              isImageUploading: false,
            },
          ],
        },
      ]);
    }

    this.setState({
      formFields,
    });
  };

  handleSectionRemove = (e, index) => {
    this.validator.hideMessages();
    let { formFields } = this.state;

    let { isEdit } = this.props;

    if (isEdit) {
      formFields.sectionArray[index]["sectionisDeleted"] = true;
    } else {
      formFields.sectionArray = formFields.sectionArray.filter(
        (s, sidx) => index !== sidx
      );
    }

    this.setState({
      formFields,
    });
  };

  onEditorChange = (editor, index) => {
    let { formFields } = this.state;
    formFields["sectionArray"][index]["sectionDescriptions"] = editor.getData();
    this.setState({
      formFields,
    });
  };

  handleSubmit = (isChangeStepper) => {
    let { handleStepperChange, isEdit } = this.props;

    if (this.validator.allValid()) {
      if (isEdit) {
        this.sectionUpdate(isChangeStepper);
      } else {
        let { formFields } = this.state;

        let body = {
          courseId: parseInt(localStorage.getItem("courseId")),
          sections: [],
        };

        formFields.sectionArray.map((list, indexSection) => {
          let data = {
            title: list.sectionTitle,
            objectiveSection: list.sectionDescriptions,
            chapters: [],
          };

          let uploadingIndex = "";

          list.chapter.map((chapterDetail, index) => {
            if (chapterDetail.isImageUploading == true) {
              uploadingIndex = index;
            }

            data.chapters.push({
              title: chapterDetail.chapterTitle,
              fileType: chapterDetail.fileTypeRequest,
              chapterFileName: chapterDetail.fileName,
              chapterFileUrl:
                chapterDetail.video != 0 ? chapterDetail.video[0] : "",
            });
          });

          if (list.chapter[uploadingIndex] == undefined) {
            body.sections.push(data);

            if (formFields.sectionArray.length === indexSection + 1) {
              this.props.addSections(body).then(() => {
                this.validator.hideMessages();

                if (isChangeStepper) {
                  let path = this.props.match.path.split("/");

                  handleStepperChange("next");

                  if (path[3] === "section") {
                    this.props.history.push({
                      pathname: `lessons`,
                      state: { changeStepperIndex: true },
                    });
                  }
                }
              });
            }
          } else {
            Toast({
              type: "error",
              message: "Please wait video is uploading",
            });
          }
        });
      }
    } else {
      this.validator.showMessages();
    }
  };

  sectionUpdate = (isChangeStepper) => {
    let { handleStepperChange, isEdit, courseDetail } = this.props;

    let { formFields, isNewlyAddedData } = this.state;

    let body = {
      courseId: courseDetail.courseId,
      sections: [],
    };

    if (isNewlyAddedData) {
      body.isAdded = true;
    }

    formFields.sectionArray.map((list, indexSection) => {
      let data = {
        sectionId: list.sectionId,
        title: list.sectionTitle,
        objectiveSection: list.sectionDescriptions,
        isDeleted: list.sectionisDeleted,
        isAdded: list.isAdded,
        isUpdated: true,
        chapters: [],
      };

      if (isNewlyAddedData) {
        delete data.isUpdated;
        data.isAdded = true;
      }

      let uploadingIndex = "";

      list.chapter.map((chapterDetail, index) => {
        if (chapterDetail.isImageUploading == true) {
          uploadingIndex = index;
        }

        data.chapters.push({
          title: chapterDetail.chapterTitle,
          chapterFileName: chapterDetail.fileName,
          chapterFileUrl:
            chapterDetail.video != 0 ? chapterDetail.video[0] : "",
          isDeleted: chapterDetail.isDeleted,
          isAdded: chapterDetail.isAdded,
          fileType: chapterDetail.fileTypeRequest,
          isUpdated: true,
          chapterId: chapterDetail.chapterId,
        });
      });

      if (list.chapter[uploadingIndex] == undefined) {
        body.sections.push(data);
        if (formFields.sectionArray.length === indexSection + 1) {
          this.props.updateSections(body).then(() => {
            this.validator.hideMessages();

            if (isChangeStepper) {
              let path = this.props.match.path.split("/");

              handleStepperChange("next");
              if (path[3] === "section") {
                this.props.history.push({
                  state: { changeStepperIndex: true },
                });
                this.props.history.push(
                  `/admin/addCourse/lessons/${body.courseId}`
                );
              }
            }
          });
        }
      } else {
        Toast({ type: "error", message: "Please wait video is uploading" });
      }
    });
  };

  handleToggleForEvent = ({ target: { name, value } }, index, chapterIndex) => {
    let { formFields } = this.state;

    if (
      formFields["sectionArray"][index]["chapter"][chapterIndex]["fileName"] !==
      ""
    ) {
      formFields["sectionArray"][index]["chapter"][chapterIndex]["video"] = [];
      formFields["sectionArray"][index]["chapter"][chapterIndex][
        "videoArray"
      ] = [];
      formFields["sectionArray"][index]["chapter"][chapterIndex]["fileName"] =
        "";
      formFields["sectionArray"][index]["chapter"][chapterIndex][
        "fileType"
      ] = [];
    }

    formFields["sectionArray"][index]["chapter"][chapterIndex][
      "fileTypeRequest"
    ] = value;

    this.setState({
      formFields,
    });
  };

  render() {
    let { formFields } = this.state;

    let { sectionArray } = formFields;

    let { isEdit = false } = this.props;

    return (
      <>
        {sectionArray.map((list, index) => {
          let { sectionisDeleted = false } = list;
          return !sectionisDeleted ? (
            <div className="mb-3">
              <div className="lesson-details">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="course-heading pb-2">Section</h5>
                </div>
                <div className="pt-3">
                  <h5 className="fs-19 text-black font-Gilroy-Bold line-height-22 mb-0 mt-2">
                    {index + 1}. Section
                  </h5>
                  <div className="row">
                    <div className="col-12">
                      <NormalInput
                        label="Title of the section"
                        type="text"
                        onChange={(e) => this.handleInput(e, index)}
                        value={list.sectionTitle}
                        placeholder="Enter title"
                        name="sectionTitle"
                        required={true}
                      />
                      {this.validator.message(
                        `sectionTitle `,
                        list.sectionTitle,
                        "required|max:75"
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <NormalEditor
                        label="Objective of the section"
                        data={list.sectionDescriptions}
                        onChange={(e) => this.onEditorChange(e, index)}
                        required={true}
                      />
                      {this.validator.message(
                        `sectionDescriptions `,
                        list.sectionDescriptions,
                        "required|max:150"
                      )}
                    </div>
                  </div>
                  {list.chapter.map((chapterList, chapterIndex) => {
                    let { isDeleted = false } = chapterList;
                    return !isDeleted ? (
                      <div className="pt-3">
                        <h5 className="fs-19 text-black font-Gilroy-Bold line-height-22 mb-0 mt-2">
                          {chapterIndex + 1}. Chapter
                        </h5>
                        <div className="row">
                          <div className="col-12">
                            <NormalInput
                              label="Title of the chapter"
                              type="text"
                              onChange={(e) =>
                                this.handleInput(e, index, chapterIndex)
                              }
                              value={chapterList.chapterTitle}
                              placeholder="Enter title"
                              name="chapterTitle"
                              required={true}
                            />
                            {this.validator.message(
                              `chapterTitle`,
                              chapterList.chapterTitle,
                              "required|max:50"
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex radio-inputs-area mt-5">
                              <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20 mr-5">
                                Choose your file format{" "}
                                <span className="text-danger pl-2 fs-18">
                                  *
                                </span>
                              </div>
                              <p className="d-flex align-items-center h-100 pr-5 mr-4 mb-0">
                                <input
                                  disabled={chapterList.isImageUploading}
                                  id={
                                    "video" +
                                    chapterIndex +
                                    1 +
                                    "section" +
                                    index
                                  }
                                  type="radio"
                                  value="video"
                                  name={
                                    "video" +
                                    chapterIndex +
                                    1 +
                                    "section" +
                                    index
                                  }
                                  checked={
                                    chapterList.fileTypeRequest === "video"
                                  }
                                  onChange={(e) =>
                                    this.handleToggleForEvent(
                                      e,
                                      index,
                                      chapterIndex
                                    )
                                  }
                                />
                                <label
                                  htmlFor={
                                    "video" +
                                    chapterIndex +
                                    1 +
                                    "section" +
                                    index
                                  }
                                  className="pl-2 ml-1 fs-15 mb-0"
                                >
                                  Video
                                </label>
                              </p>
                              <p className="d-flex align-items-center h-100 pr-5 mr-4">
                                <input
                                  disabled={chapterList.isImageUploading}
                                  id={
                                    "odp" + chapterIndex + 1 + "section" + index
                                  }
                                  type="radio"
                                  value="odp"
                                  name={
                                    "odp" + chapterIndex + 1 + "section" + index
                                  }
                                  checked={
                                    chapterList.fileTypeRequest === "odp"
                                  }
                                  onChange={(e) =>
                                    this.handleToggleForEvent(
                                      e,
                                      index,
                                      chapterIndex
                                    )
                                  }
                                />
                                <label
                                  htmlFor={
                                    "odp" + chapterIndex + 1 + "section" + index
                                  }
                                  className="pl-2 ml-1 fs-15 mb-0"
                                >
                                  ODP
                                </label>
                              </p>
                              <p className="d-flex align-items-center h-100 mb-0">
                                <input
                                  disabled={chapterList.isImageUploading}
                                  id={
                                    "pdf" + chapterIndex + 1 + "section" + index
                                  }
                                  type="radio"
                                  value="pdf"
                                  name={
                                    "pdf" + chapterIndex + 1 + "section" + index
                                  }
                                  checked={
                                    chapterList.fileTypeRequest === "pdf"
                                  }
                                  onChange={(e) =>
                                    this.handleToggleForEvent(
                                      e,
                                      index,
                                      chapterIndex
                                    )
                                  }
                                />
                                <label
                                  htmlFor={
                                    "pdf" + chapterIndex + 1 + "section" + index
                                  }
                                  className="pl-2 ml-1 fs-15 mb-0"
                                >
                                  PDF
                                </label>
                              </p>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="mt-4 py-2">
                              <div className="fs-17 text-black font-Gilroy pb-3 mb-1">
                                Upload{" "}
                                {chapterList.fileTypeRequest === "video"
                                  ? "video"
                                  : chapterList.fileTypeRequest === "odp"
                                  ? "ODP"
                                  : "PDF"}
                                <span className="text-danger pl-2 fs-18">
                                  *
                                </span>
                              </div>
                              <DragFileUpload
                                acceptFileFormat={
                                  chapterList.fileTypeRequest === "video"
                                    ? ["MP4", "mp4", "webm", "WEBM", "ogg"]
                                    : chapterList.fileTypeRequest === "odp"
                                    ? ["odp"]
                                    : ["pdf"]
                                }
                                className={`file-uploader ${
                                  chapterList.video.length !== 0 ? "" : "no-img"
                                }`}
                                handleFileUpload={([file]) =>
                                  this.handleFileInput(
                                    {
                                      target: {
                                        name: "video",
                                        value: file,
                                      },
                                    },
                                    index,
                                    chapterIndex
                                  )
                                }
                              >
                                {chapterList.isImageUploading ? (
                                  <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100">
                                    <div
                                      id={`video-upload-${index}-chapter-${chapterIndex}`}
                                    ></div>
                                    {/* <CommonPageLoader isSpinningLoader={true} />
                                                                                <p className="mt-2 mb-0 fs-14 text-grey-65">Uploading .....</p> */}
                                  </div>
                                ) : (
                                  <>
                                    {chapterList.video.length !== 0 ? (
                                      chapterList.fileTypeRequest ===
                                      "video" ? (
                                        <div className="row">
                                          <div
                                            className="justify-content-center align-items-center d-flex"
                                            onMouseEnter={() =>
                                              this.refs.vidRef.play()
                                            }
                                            onMouseLeave={() =>
                                              this.refs.vidRef.pause()
                                            }
                                          >
                                            <video
                                              height="150"
                                              width="300"
                                              ref="vidRef"
                                              src={chapterList.video[0]}
                                            ></video>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {chapterList.fileTypeRequest ===
                                          "odp" ? (
                                            <img src={odpImage} alt="image" />
                                          ) : (
                                            <img src={pdfImage} alt="image" />
                                          )}
                                        </>
                                      )
                                    ) : (
                                      <div className="uploader-content text-center">
                                        {chapterList.fileTypeRequest.includes(
                                          "video"
                                        ) ? (
                                          <img
                                            src={uploaderVideo}
                                            alt="image"
                                          />
                                        ) : chapterList.fileTypeRequest ===
                                          "odp" ? (
                                          <img src={odpImage} alt="image" />
                                        ) : (
                                          <img src={pdfImage} alt="image" />
                                        )}
                                        <span className="pt-2 mt-1 fs-14 text-black">
                                          Drag & drop to upload{" "}
                                          {chapterList.fileTypeRequest ===
                                          "video"
                                            ? "video"
                                            : chapterList.fileTypeRequest ===
                                              "odp"
                                            ? "ODP"
                                            : "PDF"}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )}
                              </DragFileUpload>
                              {this.validator.message(
                                `File`,
                                chapterList.videoArray[0],
                                "required"
                              )}
                            </div>
                          </div>
                        </div>
                        {chapterIndex >= 1 ? (
                          <div>
                            <NormalButton
                              label={`Remove chapter`}
                              danger={true}
                              className="px-4"
                              onClick={(e) =>
                                this.handleChapterRemove(e, chapterIndex, index)
                              }
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : null;
                  })}
                  <div className="d-flex justify-content-between">
                    <div>
                      <NormalButton
                        label="Add chapter"
                        outline={true}
                        className="px-4 font-Gilroy line-height-19"
                        onClick={(e) => this.handleChapterAdd(e, index)}
                      />
                    </div>
                    <div className="add-section-button d-flex">
                      {index >= 1 ? (
                        <div className="mr-2">
                          <NormalButton
                            label={`Remove section`}
                            danger={true}
                            className="px-4"
                            onClick={(e) => this.handleSectionRemove(e, index)}
                          />
                        </div>
                      ) : null}
                      <NormalButton
                        label="Add section"
                        outline={true}
                        className="px-4 font-Gilroy line-height-19"
                        onClick={(e) => this.handleSectionAdd(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null;
        })}
        <div className="d-flex align-items-center add-course-buttons">
          <div className="ml-auto d-flex">
            <NormalButton
              label="Discard changes"
              outline={true}
              className="px-4 mr-2 border-none"
              onClick={() => history.push("/admin/courseManagement")}
            />
            <NormalButton
              label={isEdit ? "Update & next" : "Save & next"}
              mainbg={true}
              className="px-4"
              onClick={this.handleSubmit.bind(this, true)}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addSections,
      fileUpload,
      updateSections,
    },
    dispatch
  );
};

let component = SectionsClass;

export const Sections = connect(null, mapDispatchToProps)(component);
