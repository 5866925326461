import React from "react";
import { history } from "../../../service/helpers";
export class AppBack extends React.Component {
  handleAppBack = () => {
    history.goBack()
  }
  render() {
    let { label = "" } = this.props;
    return (
      <div className="d-flex align-items-center cursor-pointer back-area" onClick={this.handleAppBack}>
        <i className='icon-arrow-left fs-24'></i>
        <h5 className='heading-20 pl-3 mb-0'>{label}</h5>
      </div>
    );
  }
}
