import React, { Component } from "react";

import { bindActionCreators } from "redux";

import { connect } from "react-redux";

import "./style.scss";

import {
  TableWrapper,
  CommonPageLoader,
  NormalButton,
  InputSearch,
  DeletePopup,
} from "component/common";

import { history } from "service/helpers";

import { getDescriptionData } from "service/helperFunctions";

import { getFaqList, deleteFaq } from "action/FaqAct";

import { debounceFunction } from "service/debounce";

export class FaqListClass extends Component {
  state = {
    headerDetails: [
      {
        label: "Question",
      },
      {
        label: "Answer",
      },
      {
        label: "Action",
      },
    ],
    pageMeta: {},
    faqList: [],
    isloading: false,
    searchValue: "",
    isDeletePopup: false,
    faqId: "",
  };

  //Life Cylces
  componentDidMount = () => {
    this.setState({ isloading: true });
    let { searchValue } = this.state;
    this._getFaqList(1, searchValue);
  };

  //Get Faq List
  _getFaqList = (page, searchValue) => {
    this.props.getFaqList({ page, search: searchValue }).then((data) => {
      let results = data.results;
      this.setState({
        pageMeta: data.pageMeta,
        faqList: results,
        isloading: false,
      });
    });
  };

  handlePageChange = (page) => {
    // page change
    let { searchValue } = this.state;
    this.setState({ isloading: true });
    this._getFaqList(page, searchValue);
  };

  handleInputChange = ({ target: { name, value } }) => {
    //Debounce Function
    this.setState({ search: value });
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this._getFaqList(1, searchValue);
  };

  //handle delete
  handleDelete = () => {
    let { faqId } = this.state;
    this.props.deleteFaq(faqId).then(() => {
      this.setState({ faqId: "" });
      this.handlePageChange(1);
      this.toggleDelete("isDeletePopup");
    });
  };

  toggleDelete = (name, id) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
      faqId: id,
    }));
  };

  render() {
    const {
      headerDetails,
      pageMeta = {},
      faqList,
      isloading,
      isDeletePopup,
    } = this.state;

    return (
      <div className="faq-list">
        <div className="d-flex justify-content-between align-items-center faq-list-heading">
          <div className="">
            <h5 className="heading-20">FAQ’s list</h5>
          </div>
          <div className="d-flex">
            <InputSearch
              className={"mr-4"}
              placeholder={"Search"}
              onChange={(e) => this.handleInputChange(e)}
            />
            <NormalButton
              label="Add FAQ’s"
              mainbg={true}
              onClick={() => {
                history.push("/admin/faq/addfaq");
              }}
            />
          </div>
        </div>
        {isloading ? (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        ) : (
          <div className="table-container w-100 mt-4 pt-1">
            {faqList.length != 0 ? (
              <TableWrapper
                headerDetails={headerDetails}
                queryHandler={this.handlePageChange}
                pageMeta={pageMeta}
              >
                {faqList.map((item, index) => {
                  let { question, answer, faqId } = item;

                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {question ? getDescriptionData(question, 45) : "-"}
                          </div>
                        </td>
                        <td>
                          <div
                            className="d-flex align-items-center h-100 answer-area"
                            dangerouslySetInnerHTML={{
                              __html: getDescriptionData(answer, 45),
                            }}
                          ></div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            <i
                              className="icon-edit fs-20 cursor-pointer"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: `/admin/faq/editfaq/${faqId}`,
                                });
                              }}
                            ></i>
                            <i
                              className="icon-Delete fs-20 pl-3 cursor-pointer"
                              onClick={() => {
                                this.toggleDelete("isDeletePopup", faqId);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                      <tr className="trans-bg">
                        <td className="empty-height" height="12"></td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </TableWrapper>
            ) : (
              <div className="text-center fs-18 text-black py-5">
                No records found !!!
              </div>
            )}
          </div>
        )}
        <DeletePopup
          isOpen={isDeletePopup}
          toggle={() => this.toggleDelete("isDeletePopup")}
          handleChange={() => this.handleDelete()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFaqList,
      deleteFaq,
    },
    dispatch
  );
};

let component = FaqListClass;

export const FaqList = connect(null, mapDispatchToProps)(component);
