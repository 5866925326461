import React, { Component } from "react";

import { bindActionCreators } from "redux";

import { connect } from "react-redux";

import "./style.scss";

import {
  TableWrapper,
  CommonPageLoader,
  NormalButton,
  InputSearch,
  DeletePopup,
} from "component/common";

import { history } from "service/helpers";

import moment from "moment";

import { getcomplianceCalenderList } from "action/ComplianceCalenderAct";

import { debounceFunction } from "service/debounce";
import { getDescriptionData } from "service/helperFunctions";

export class ComplianceCalenderListClass extends Component {
  state = {
    headerDetails: [
      {
        label: "Service name",
      },
      {
        label: "Year",
      },
      {
        label: "Date to be filed",
      },
      {
        label: "Excel sheet/Supporting link",
      },
      {
        label: "Action",
      },
    ],
    pageMeta: {},
    taskList: [],
    isloading: false,
    searchValue: "",
    isDeletePopup: false,
    fpoTasksId: "",
  };

  //Life Cylces
  componentDidMount = () => {
    this.setState({ isloading: true });
    let { searchValue } = this.state;
    this._getFpoTaskList(1, searchValue);
  };

  //Get Faq List
  _getFpoTaskList = (page, searchValue) => {
    this.props
      .getcomplianceCalenderList({ page, search: searchValue })
      .then((data) => {
        let results = data.results;
        this.setState({
          pageMeta: data.pageMeta,
          taskList: results,
          isloading: false,
        });
      });
  };

  handlePageChange = (page) => {
    // page change
    let { searchValue } = this.state;
    this.setState({ isloading: true });
    this._getFpoTaskList(page, searchValue);
  };

  handleInputChange = ({ target: { name, value } }) => {
    //Debounce Function
    this.setState({ search: value });
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this.setState({ isloading: true }, () => {
      this._getFpoTaskList(1, searchValue);
    });
  };

  //handle delete
  handleDelete = () => {
    // let { fpoTasksId } = this.state
    // this.props.deleteFaq(fpoTasksId).then(() => {
    //    this.setState({ fpoTasksId: '' })
    //    this.handlePageChange(1)
    //    this.toggleDelete('isDeletePopup')
    // });
  };

  toggleDelete = (name, id) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
      fpoTasksId: id,
    }));
  };

  render() {
    const {
      headerDetails,
      pageMeta = {},
      taskList,
      isloading,
      isDeletePopup,
    } = this.state;

    return (
      <div className="faq-list">
        <div className="d-flex justify-content-between align-items-center faq-list-heading">
          <div className="">
            <h5 className="heading-20">Compliance calender</h5>
          </div>
          <div className="d-flex">
            <InputSearch
              className={"mr-4"}
              placeholder={"Search"}
              onChange={(e) => this.handleInputChange(e)}
            />
            <NormalButton
              label="Add new"
              mainbg={true}
              onClick={() => {
                history.push("/admin/compliance-calender/add-task");
              }}
            />
          </div>
        </div>
        {isloading ? (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        ) : (
          <div className="table-container w-100 mt-4 pt-1">
            {taskList.length != 0 ? (
              <TableWrapper
                headerDetails={headerDetails}
                queryHandler={this.handlePageChange}
                pageMeta={pageMeta}
              >
                {taskList.map((item, index) => {
                  let {
                    dueDate,
                    supportingLink,
                    fpoTasksId,
                    fileName = "",
                    taskName,
                  } = item;
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <div>
                            <div className="d-flex align-items-center h-100">
                              {taskName
                                ? getDescriptionData(taskName, "30")
                                : "-"}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {moment(dueDate).format("YYYY")}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {moment(dueDate).format("DD/MM/YYYY")}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {supportingLink !== null &&
                            supportingLink !== "" ? (
                              <a
                                className="text-black-50 fs-14 text-underline"
                                href={
                                  supportingLink.includes("http" || "https")
                                    ? supportingLink
                                    : `https://${supportingLink}`
                                }
                                target="_blank"
                              >
                                {supportingLink}
                              </a>
                            ) : fileName !== null && fileName !== "" ? (
                              <p className="text-black-50 fs-14 mb-0">
                                {getDescriptionData(fileName, 50)}
                              </p>
                            ) : (
                              " - "
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            <i
                              className="icon-edit fs-20 cursor-pointer"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: `/admin/compliance-calender/edit-task/${fpoTasksId}`,
                                });
                              }}
                            ></i>
                            {/* <i
                                             className='icon-Delete fs-20 pl-3 cursor-pointer'
                                             onClick={() => {
                                                this.toggleDelete('isDeletePopup', fpoTasksId)
                                             }}
                                          ></i> */}
                          </div>
                        </td>
                      </tr>
                      <tr className="trans-bg">
                        <td className="empty-height" height="12"></td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </TableWrapper>
            ) : (
              <div className="text-center fs-18 text-black py-5">
                No records found !!!
              </div>
            )}
          </div>
        )}
        <DeletePopup
          isOpen={isDeletePopup}
          toggle={() => this.toggleDelete("isDeletePopup")}
          handleChange={() => this.handleDelete()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getcomplianceCalenderList,
    },
    dispatch
  );
};

let component = ComplianceCalenderListClass;

export const ComplianceCalenderList = connect(
  null,
  mapDispatchToProps
)(component);
