import React, { Component } from "react";

export class Pagination extends Component {
  handlePagination = (direction, num = null) => {
    try {
      let { page = 1 } = this.props.pageMeta || {};
      let pageNumber = eval(`${page} ${direction} ${1}`);
      if (num === 1) {
        this.props.handleChange(num);
      } else if (num !== null) {
        this.props.handleChange({ page: num ? num : pageNumber });
      } else {
        this.props.handleChange(pageNumber);
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleLastPage = (pageCount) => {
    this.props.handleChange(pageCount);
  };

  render() {
    let { pageCount = 10, page = 1 } = this.props.pageMeta || {};
    if (pageCount <= 1) {
      return "";
    }

    return (
      <div className="d-flex text-center w-100 justify-content-end pr-4 py-3">
        <p className="m-0 d-flex align-items-center">
          <span
            className="icon-page-left pr-3 cursor-pointer"
            onClick={() => page != 1 && this.handlePagination("-", 1)}
          />
          <button
            className="btn-effect table-left-arrow cursor-pointer"
            disabled={page === 1}
            onClick={(e) => page !== 1 && this.handlePagination("-")}
          >
            Previous
          </button>

          <span>
            {page} of {pageCount}
          </span>

          <button
            className="btn-effect table-right-arrow cursor-pointer"
            disabled={page === pageCount}
            onClick={(e) => page !== pageCount && this.handlePagination("+")}
          >
            Next
          </button>
          <span
            className="pl-3 icon-page-right cursor-pointer"
            onClick={() => pageCount !== page && this.handleLastPage(pageCount)}
          />
        </p>
      </div>
    );
  }
}
