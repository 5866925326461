import { Master } from 'service/apiVariables';
import { addQuery } from 'service/helperFunctions';

// Pincode master
export const pincodemaster = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.pincodemaster, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addbusinessActivity
export const addbusinessActivity = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addbusinessActivity, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addkeyfinancialDetails
export const addkeyfinancialDetails = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addkeyfinancialDetails, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addCrops
export const addCrops = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addCrops, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Add district
export const addDistrict = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addDistrict, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addfinancialYear
export const addfinancialYear = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addfinancialYear, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addfinancialDetails
export const addfinancialDetails = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addfinancialDetails, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addeducationQualification
export const addeducationQualification = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addeducationQualification, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addinfraDetails
export const addinfraDetails = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addinfraDetails, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addinsititutionDetails
export const addinsititutionDetails = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addinsititutionDetails, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addlicenceDetails
export const addlicenceDetails = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addlicenceDetails, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addliveStockDetails
export const addliveStockDetails = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addliveStockDetails, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addKYCDocumentDetails
export const addKycDocumentDetails = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addKycDocumentDetails, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addState
export const addState = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addState, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//addAssetDetails
export const addAssetDetails = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.addAssetDetails, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//District List
export const districtList = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.districtList, body })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//State list
export const stateList = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...Master.stateList, body })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
