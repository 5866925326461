import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
   getAllEvents,
   getEventsById,
   deleteEventsById,
} from 'action/ContentAct';

import moment from 'moment';

import { TableWrapper, CommonPageLoader, DeletePopup } from 'component/common';

import { history } from 'service/helpers';

import { DetailPopup } from 'component/contentManagement/Modal';

import { getDescriptionData } from "service/helperFunctions"

export class EventsListClass extends Component {
   state = {
      headerDetails: [
         {
            label: 'Events id',
         },
         {
            label: 'Events image',
         },
         {
            label: 'Events title',
         },
         {
            label: 'Event date',
         },
         {
            label: 'Organiser',
         },
         {
            label: 'Publication date',
         },
         {
            label: 'Action',
         },
      ],
      pageMeta: {},
      isloading: false,
      isEventView: false,
      eventsList: [],
      eventDetials: {
         title: '',
         date: '',
         description:
            '',
         subtitles: [
            { title: '', content: '24/09/2020' },
            {
               title: '',
               schedules: [],
            },
         ],
      },
      isDeletePopup: false,
      eventId: ''
   };

   toggle = (name, id) => {
      this.state.isEventView
         ? this.setState((prevState) => ({
            [name]: !prevState[name],
         }))
         : this.props.getEventsById(id).then((data) => {
            const {
               eventTitle,
               publishOn,
               eventDate,
               description,
               organiseBy,
               agendaOfEvent,
               modeOfEvent,
               placeAndLocation,
               registrationLink,
               isSave,
               isShare,
               idDownload,
               isLike,
               idDislike,
               uploadImage,
            } = data;

            this.setState((prevState) => ({
               eventDetials: {
                  uploadImage: uploadImage,
                  title: eventTitle ? eventTitle : '',
                  date: publishOn
                     ? moment(publishOn).format('DD-MM-YYYY')
                     : '',
                  description: description ? description : '',
                  subtitles: [
                     {
                        title: 'Event date',
                        content: eventDate
                           ? moment(eventDate).format('DD-MM-YYYY')
                           : '',
                     },
                     {
                        title: 'Organiser',
                        content: organiseBy ? organiseBy : '',
                     },
                     {
                        title: 'Agenda of the event',
                        schedules: [
                           {
                              content: agendaOfEvent ? agendaOfEvent : '',
                           },
                        ],
                     },
                     {
                        title: 'Event Mode',
                        content: modeOfEvent === true ? 'Online' : 'Offline',
                     },
                     {
                        title: 'Place / Location',
                        content: placeAndLocation ? placeAndLocation : '',
                     },
                     {
                        title: 'Registration link',
                        content: registrationLink ? registrationLink : '',
                     },
                  ],
               },
               [name]: !prevState[name],
            }));
         });
   };
   handleAllEvents = (page) => {
      this.setState({ isloading: true });
      this.props.getAllEvents({ limit: 10, page: page }).then((data) => {
         this.setState({
            eventsList: data.results,
            pageMeta: data.pageMeta,
            isloading: false,
         });
      });
   };

   //handle delete
   handleDelete = () => {
      let { eventId } = this.state
      this.props.deleteEventsById(eventId).then(() => {
         this.setState({ eventId: '' })
         this.handleAllEvents(1);
         this.toggleDelete('isDeletePopup')
      });
   };

   toggleDelete = (name, id) => {
      this.setState((prevState) => ({
         [name]: !prevState[name],
         eventId: id
      }));
   };

   //Lifecycles
   componentDidMount() {
      this.setState({ isloading: true });
      this.handleAllEvents(1);
   }

   render() {
      const {
         headerDetails,
         pageMeta = {},
         eventsList,
         isEventView,
         eventDetials,
         isloading,
         isDeletePopup
      } = this.state;

      return (
         <>
            {isloading ? (
               <>
                  {/* Loader */}
                  <CommonPageLoader />
               </>
            ) : (
                  <div className='table-container w-100 mt-4 pt-1'>
                     {eventsList.length !== 0 ?

                        <TableWrapper
                           headerDetails={headerDetails}
                           pageMeta={pageMeta}
                           queryHandler={this.handleAllEvents}
                        >
                           {eventsList.map((item, index) => {
                              let {
                                 eventTitle,
                                 organiseBy,
                                 eventDate,
                                 eventId,
                                 uploadImage,
                                 publishOn
                              } = item;

                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <p className="mb-0">{eventId}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        style={{
                                          width: "136px",
                                          height: "69px",
                                        }}
                                        src={uploadImage}
                                        alt="video"
                                      />
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <p className="mb-0">
                                          {getDescriptionData(eventTitle, 30)}
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {moment(eventDate).format("DD/MM/YYYY")}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {organiseBy}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {moment(publishOn).format("DD/MM/YYYY")}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <i
                                          className="icon-eye mr-3 cursor-pointer fs-20"
                                          onClick={() => {
                                            this.toggle("isEventView", eventId);
                                          }}
                                        ></i>
                                        <i
                                          className="icon-edit mr-3 fs-20 cursor-pointer"
                                          onClick={() => {
                                            history.push(
                                              `editEvent/${eventId}`
                                            );
                                          }}
                                        ></i>
                                        <i
                                          className="icon-Delete fs-20 cursor-pointer"
                                          onClick={() => {
                                            this.toggleDelete(
                                              "isDeletePopup",
                                              eventId
                                            );
                                          }}
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="trans-bg">
                                    <td
                                      className="empty-height"
                                      height="12"
                                    ></td>
                                  </tr>
                                </React.Fragment>
                              );
                           })}
                        </TableWrapper>
                        : (
                           <div className='text-center fs-18 text-black py-5'>
                              No records found !!!
                           </div>
                        )
                     }
                  </div>
               )}
            <DetailPopup
               title='Event details'
               details={eventDetials}
               isOpen={isEventView}
               toggle={() => this.toggle('isEventView')}
            />
            <DeletePopup
               isOpen={isDeletePopup}
               toggle={() => this.toggleDelete('isDeletePopup')}
               handleChange={() => this.handleDelete()}
            />
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         getAllEvents,
         getEventsById,
         deleteEventsById,
      },
      dispatch
   );
};

let component = EventsListClass;

export const EventsList = connect(null, mapDispatchToProps)(component);
