import React, { Component } from "react";

import "./Addcontent.scss";

import { connect } from "react-redux";

import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalEditor,
} from "component/common";

import moment from "moment";

import { history } from "service/helpers";

import { getDescriptionData } from "service/helperFunctions";

import { bindActionCreators } from "redux";

import SimpleReactValidator from "simple-react-validator";

import { fileUpload } from "action/CourseAct";

import {
  addNews,
  updateNews,
  getNewsById,
  contentManagementTab,
} from "action/ContentAct";

import { Toast } from "service/toast";

export class AddcontentClass extends Component {
  constructor(props) {
    super();
    this.state = {
      // toggleEvent: {
      //    share: 0,
      //    like: 0,
      //    dislike: 0,
      // },
      fileName: "",
      fileArray: [],
      formFields: {
        title: "",
        publishDate: "",
        source: "",
        sourceHyperLink: "",
        description: "",
      },
      isImageUploading: false,
      isImageUploaded: false,
    };
  }

  //validation
  validator = new SimpleReactValidator({
    validators: {
      checkUrl: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleInput = ({ target: { name, value } }) => {
    let { formFields } = this.state;

    formFields[name] = value;

    this.setState({
      formFields,
    });
  };

  uploadSingleFile = (e) => {
    //upload image
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ fileArray: [] }, () => {
        let { fileArray, fileName } = this.state;
        fileName = "Uploading ....";
        let fileData = [];
        fileData.push(fileData);
        fileArray = fileData;
        this.setState({ isImageUploading: true });

        const config = {
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            document.getElementById(
              "image-upload"
            ).innerText = `Uploading ${percentCompleted}%`;
          },
        };

        const formData = new FormData();
        formData.append("file", file);

        this.props.fileUpload(formData, config).then((data) => {
          if (data !== undefined) {
            let uploadedFile = [];
            uploadedFile.push(data[0].fileUrl);
            fileArray = uploadedFile;
            fileName = data[0].fileName;
            this.setState({
              fileArray,
              isImageUploading: false,
              fileName,
            });
          } else {
            this.setState({
              fileArray: [],
              isImageUploading: false,
              fileName: "",
            });
          }
        });
        this.setState({
          fileArray,
          isImageUploaded: false,
          fileName,
        });
      });
    }
  };

  handleToggle = ({ target: { name, value } }) => {
    let toggleEvent = Object.assign({}, this.state.toggleEvent);
    toggleEvent[name] = value ? 1 : 0;
    this.setState({
      toggleEvent,
    });
  };

  onEditorChange = (editor) => {
    let { formFields } = this.state;
    formFields.description = editor.getData();
    this.setState({
      formFields,
    });
  };

  handleSubmit = () => {
    let { fileArray, formFields, toggleEvent, isImageUploading, fileName } =
      this.state;

    let { addNews, updateNews, match } = this.props;

    if (this.validator.allValid() && fileArray.length != 0) {
      if (!isImageUploading) {
        this.setState({ isImageUploading: false });

        // let { share, like, dislike } = toggleEvent;

        let { title, publishDate, source, sourceHyperLink, description } =
          formFields;

        let body = {
          newsId: this.props.match.params.id,
          fileName: fileName,
          newsTitle: title,
          description: description,
          source: source,
          publishOn: moment(publishDate).format("YYYY-MM-DD"),
          sourceHyperlink: sourceHyperLink,
          uploadImage: fileArray.length != 0 ? fileArray[0] : "",
          // isShared: share == 0 ? false : true,
          // isLiked: like == 0 ? false : true,
          // isDisliked: dislike == 0 ? false : true,
        };

        {
          match.params.id === undefined
            ? addNews(body).then(() => {
                this.handleBack();
              })
            : updateNews(body).then(() => {
                this.handleBack();
              });
        }
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      if (fileArray.length == 0) {
        this.setState({ isImageUploaded: true });
      }
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.props.getNewsById(this.props.match.params.id).then((data) => {
        this.setState({
          fileName: data.fileName,
          fileArray: [data.uploadImage],
          isImageUploaded: false,
          formFields: {
            title: data.newsTitle,
            publishDate: new Date(data.publishOn),
            source: data.source,
            sourceHyperLink: data.sourceHyperlink,
            description: data.description,
          },
          // toggleEvent: {
          //    share: data.isShared === true ? 1 : 0,
          //    like: data.isLiked === true ? 1 : 0,
          //    dislike: data.isDisliked === true ? 1 : 0,
          // },
        });
      });
    }
  }

  handleBack = () => {
    history.goBack();
    this.validator.hideMessages();
    this.props.contentManagementTab(2);
  };

  render() {
    let {
      formFields,
      toggleEvent,
      isImageUploaded,
      fileName,
      fileArray,
      isImageUploading,
    } = this.state;

    // let { share, like, dislike } = toggleEvent;

    let { title, publishDate, description, sourceHyperLink, source } =
      formFields;

    let { match } = this.props;

    return (
      <div className="add-news">
        <div
          className="d-flex cursor-pointer py-2 mt-1 mb-4 back-area"
          onClick={() => this.handleBack()}
        >
          <i className="icon-arrow-left fs-24"></i>
          <h5 className="heading-20 ml-1 pl-3 mb-0">
            {match.params.id === undefined ? "Add" : "Edit"} articles
          </h5>
        </div>
        <div className="news-details">
          <div className="">
            <h3 className="news-heading mb-0">Article details</h3>
          </div>
          <div className="">
            <div className="row">
              <div className="col-12">
                <NormalInput
                  label="Article title"
                  type="text"
                  onChange={this.handleInput}
                  value={title}
                  placeholder="Enter here"
                  name="title"
                  required={true}
                />
                {this.validator.message("title", title, "required|max:200")}
              </div>
            </div>
            <div className="row">
              <div className="col-12 pt-3">
                <label>Description</label>
                <span className="text-danger pl-3 fs-18">*</span>
                <NormalEditor
                  label="Description"
                  data={description}
                  onChange={this.onEditorChange}
                  required={true}
                />
                {this.validator.message(
                  "description",
                  description,
                  "required|max:700"
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <NormalInput
                  label="Source"
                  type="text"
                  onChange={this.handleInput}
                  value={source}
                  placeholder="Enter here"
                  name="source"
                  required={true}
                />
                {this.validator.message("source", source, "required|max:200")}
              </div>
              <div className="col-6">
                <NormalDate
                  value={publishDate}
                  name="publishDate"
                  placeholder="DD/MM/YYYY"
                  onChange={this.handleInput}
                  mindate={true}
                  maxdate={false}
                  timeInput={false}
                  label={"Publication date"}
                  required={true}
                />
                {this.validator.message("publishDate", publishDate, "required")}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput
                  label="Source hyperlink"
                  type="text"
                  onChange={this.handleInput}
                  value={sourceHyperLink}
                  placeholder="Enter here"
                  name="sourceHyperLink"
                  required={true}
                />
                {this.validator.message(
                  "sourceHyperLink",
                  sourceHyperLink,
                  "required|checkUrl"
                )}
              </div>
            </div>
            {/* <div className='row'>
                     <div className='col-12 my-4 py-2'>
                        <div className='fs-17 text-black font-Gilroy pb-3 mb-1'>
                           Viewers able to
                        </div>
                        <div className='row'>
                           <div className='col-2'>
                              <NormalCheckbox
                                 value=''
                                 label='Share'
                                 name='share'
                                 checked={share}
                                 onChange={(event) => this.handleToggle(event)}
                              />
                           </div>
                           <div className='col-2'>
                              <NormalCheckbox
                                 value=''
                                 label='Like'
                                 name='like'
                                 checked={like}
                                 onChange={(event) => this.handleToggle(event)}
                              />
                           </div>
                           <div className='col-2'>
                              <NormalCheckbox
                                 value=''
                                 label='Dislike'
                                 name='dislike'
                                 checked={dislike}
                                 onChange={(event) => this.handleToggle(event)}
                              />
                           </div>
                        </div>
                     </div>
                  </div> */}
            <div className="mb-2">
              <div className="fs-17 text-black font-Gilroy pb-4">
                Upload image <span className="text-danger pl-2 fs-18">*</span>
              </div>
              <div className="d-flex align-items-center w-100 btn-file-input">
                <button className="">
                  <input
                    type="file"
                    className="h-100"
                    accept="image/*"
                    onChange={this.uploadSingleFile}
                  ></input>
                  {isImageUploading ? (
                    <div
                      id="image-upload"
                      className="d-flex align-items-center"
                    ></div>
                  ) : fileName == "" ? (
                    "Upload"
                  ) : (
                    getDescriptionData(fileName, 15)
                  )}
                </button>
                {fileArray.length != 0 && !isImageUploading ? (
                  <div className="ml-4">
                    <img
                      src={fileArray[0]}
                      alt=""
                      className="upload-image-preview"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {isImageUploaded ? (
                <span className="error-message text-danger fs-16 pb-3">
                  Image field is required
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center add-news-buttons">
          <div className="ml-auto d-flex">
            <NormalButton
              label="Cancel"
              outline={true}
              className="px-4 mr-2 border-none"
              onClick={() => history.goBack()}
            />
            <NormalButton
              label={match.params.id === undefined ? "Publish" : "Update"}
              mainbg={true}
              className="px-4"
              onClick={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
      addNews,
      getNewsById,
      updateNews,
      contentManagementTab,
    },
    dispatch
  );
};

let component = AddcontentClass;

export const Addcontent = connect(null, mapDispatchToProps)(component);
