import React, { Component } from 'react';
import './style.scss';
export class NormalTextarea extends Component {
   render() {
      let {
         className = 'form-control',
         placeholder = '',
         onChange,
         value = '',
         name,
         disabled = false,
         resize,
         rows = '4',
         maxlength,
         label = '',
         required = false,
      } = this.props;

      return (
         <div className='normal-text-area-input'>
            {label !== '' ? (
               <div className='fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20'>
                  {label}
                  {required && (
                     <span className='text-danger pl-2 fs-18'>*</span>
                  )}
               </div>
            ) : (
               ''
            )}
            <textarea
               maxLength={maxlength}
               rows={rows}
               className={`${className} ${!resize ? 'resize-none' : ''}`}
               name={name}
               disabled={disabled}
               value={value}
               placeholder={placeholder}
               onChange={(e) => {
                  let body = {};

                  body = {
                     target: {
                        name: e.target.name,
                        value: e.target.value,
                     },
                  };
                  onChange(body);
               }}
            ></textarea>
         </div>
      );
   }
}
