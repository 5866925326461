import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllNews, getNewsById, deleteNewsById } from 'action/ContentAct';
import { TableWrapper, CommonPageLoader, DeletePopup } from 'component/common';
import { history } from 'service/helpers';
import { DetailPopup } from 'component/contentManagement/Modal';
import moment from 'moment';
import { getDescriptionData } from 'service/helperFunctions';
export class NewsListClass extends Component {
   constructor(props) {
      super();
      this.state = {
         headerDetails: [
            {
               label: 'News id',
            },
            {
               label: 'News image',
            },
            {
               label: 'News title',
            },
            {
               label: 'Source',
            },
            {
               label: 'Publication date',
            },
            {
               label: 'Action',
            },
         ],
         isloading: false,
         pageMeta: {},
         contentManagement: [],
         isNewsView: false,
         newsDetails: {
            title: 'Lorum ipsum dolor sit amet consecuter do',
            date: '25/09/2020',
            description:
               'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non commodo quam venenatis, libero libero. Quisque Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non commodo quam venenatis, libero mauris odio. Leo placerat fermentum sagittis, libero. Quisque ',
            subtitles: [
               { title: 'Source', content: 'The Hindu' },
               {
                  title: 'Source Hyperlink',
                  content: 'www.thehindu/news/lorumipsum_dolorsit/',
               },
            ],
         },
         isDeletePopup: false,
         newsId: ''
      };
   }

   toggle = (name, id) => {
      this.state.isNewsView
         ? this.setState((prevState) => ({
            [name]: !prevState[name],
         }))
         : this.props.getNewsById(id).then((data) => {
            let {
               newsTitle,
               publishOn,
               uploadImage,
               description,
               source,
               sourceHyperlink,
               isShared,
               isLiked,
               isDisliked,
            } = data;
            this.setState((prevState) => ({
               newsDetails: {
                  title: newsTitle,
                  date: moment(publishOn).format('DD-MM-YYYY'),
                  uploadImage: uploadImage,
                  description: description,
                  subtitles: [
                     { title: 'Source', content: source },
                     {
                        title: 'Source Hyperlink',
                        content: sourceHyperlink,
                     }
                  ],
               },
               [name]: !prevState[name],
            }));
         });
   };

   handleAllNews = (page) => {
      this.props.getAllNews({ limit: 10, page: page }).then((data) => {
         this.setState({
            contentManagement: data.results,
            pageMeta: data.pageMeta,
            isloading: false,
         });
      });
   };

   //handle delete
   handleDelete = () => {
      let { newsId } = this.state
      this.props.deleteNewsById(newsId).then(() => {
         this.setState({ newsId: '' })
         this.handleAllNews(1);
         this.toggleDelete('isDeletePopup')
      });
   };

   toggleDelete = (name, id) => {
      this.setState((prevState) => ({
         [name]: !prevState[name],
         newsId: id
      }));
   };

   componentDidMount() {
      this.setState({ isloading: true });
      this.handleAllNews(1);
   }

   render() {
      const {
         headerDetails,
         pageMeta = {},
         contentManagement,
         isNewsView,
         newsDetails,
         isloading,
         isDeletePopup
      } = this.state;

      return (
         <>
            {isloading ? (
               <>
                  {/* Loader */}
                  <CommonPageLoader />
               </>
            ) : (
                  <div className='table-container w-100 mt-4 pt-1'>
                     {contentManagement.length != 0 ? (
                        <TableWrapper
                           headerDetails={headerDetails}
                           queryHandler={this.handleAllNews}
                           pageMeta={pageMeta}
                        >
                           {contentManagement.map((item, index) => {
                              let {
                                 newsTitle,
                                 source,
                                 publishOn,
                                 uploadImage,
                                 newsId,
                              } = item;

                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {newsId}
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        style={{
                                          width: "136px",
                                          height: "69px",
                                        }}
                                        src={uploadImage}
                                        alt="video"
                                      />
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <p className="mb-0">
                                          {getDescriptionData(newsTitle, 30)}
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {getDescriptionData(source, 30)}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {moment(publishOn).format("DD/MM/YYYY")}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <i
                                          className="icon-eye mr-3 cursor-pointer fs-20"
                                          onClick={() => {
                                            this.toggle("isNewsView", newsId);
                                          }}
                                        ></i>
                                        <i
                                          className="icon-edit mr-3 fs-20 cursor-pointer"
                                          onClick={() => {
                                            history.push(
                                              `editContent/${newsId}`
                                            );
                                          }}
                                        ></i>
                                        <i
                                          className="icon-Delete fs-20 cursor-pointer"
                                          onClick={() => {
                                            this.toggleDelete(
                                              "isDeletePopup",
                                              newsId
                                            );
                                          }}
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="trans-bg">
                                    <td
                                      className="empty-height"
                                      height="12"
                                    ></td>
                                  </tr>
                                </React.Fragment>
                              );
                           })}
                        </TableWrapper>
                     ) : (
                           <div className='text-center fs-18 text-black py-5'>
                              No records found !!!
                           </div>
                        )}
                  </div>
               )}
            <DetailPopup
               title='News Details'
               details={newsDetails}
               isOpen={isNewsView}
               toggle={() => this.toggle('isNewsView')}
            />
            <DeletePopup
               isOpen={isDeletePopup}
               toggle={() => this.toggleDelete('isDeletePopup')}
               handleChange={() => this.handleDelete()}
            />
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         getAllNews,
         getNewsById,
         deleteNewsById,
      },
      dispatch
   );
};

let component = NewsListClass;

export const NewsList = connect(null, mapDispatchToProps)(component);
