import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { login } from "../../../../action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import { history } from "service/helpers";
import { encryptData } from "service/helperFunctions";

const cookies = new Cookies();

export class OtpLoginClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailOrMobNum: cookies.get("emailOrMobNum") || "",
      password: cookies.get("password") || "",
      rememberme: cookies.get("emailOrMobNum") && cookies.get("password"),
      loginLoader: false,
    };

    this.handleChange = this.handleChange.bind(this);

    this.validator = new SimpleReactValidator({
      validators: {
        email: {
          message: "must be a valid mobile number or Email.",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[0-9]{10}$/) ||
              validator.helpers.testRegex(
                val,
                /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/
              )
            );
          },
          messageReplace: (message, params) =>
            message.replace("", this.helpers.toSentence(params)),
          required: true,
        },
        element: (message) => (
          <span className="error-message font-md">{message}</span>
        ),
        // autoForceUpdate: this,
      },
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  toggle = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    if (this.validator.allValid()) {
      let { rememberme, emailOrMobNum, password } = this.state;
      let body = {
        emailOrMobNum: emailOrMobNum,
        isEmail: emailOrMobNum.includes("@") ? true : false,
        password: encryptData(password),
      };
      this.setState({ loginLoader: true });
      this.props
        .login(body)
        .then((data) => {
          this.setState({ loginLoader: false });
          if (data.userRoles.userRoleId == 13) {
            history.push("admin/dashboard");
          } else {
            history.push("admin/dashboard");
          }
          if (rememberme) {
            let date = new Date();
            date.setTime(date.getTime() + 48 * 60 * 60 * 1000);
            let dateString = date.toGMTString();
            cookies.set("emailOrMobNum", emailOrMobNum, [
              { path: "/" },
              { expires: dateString },
            ]);
            cookies.set("password", password, [
              { path: "/" },
              { expires: dateString },
            ]);
          } else {
            cookies.remove("emailOrMobNum");
            cookies.remove("password");
          }
        })
        .catch(() => this.setState({ loginLoader: false }));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    let { emailOrMobNum, password, rememberme, loginLoader } = this.state;
    return (
      <div className="w-100">
        <div>
          <label className="font-weight-bold" htmlFor="mobileOrEmail">
            Mobile Number or Email Address
          </label>
          <input
            type="text"
            className="form-control w-100 bg-light"
            id="mobileOrEmail"
            name="emailOrMobNum"
            value={emailOrMobNum}
            onChange={this.handleChange}
            onKeyUp={(e) => {
              if (e.keyCode == 13) {
                this.handleSubmit();
              }
            }}
          />
          {this.validator.message(
            "Mobile number or email address",
            emailOrMobNum,
            "required|email",
            {
              className: "text-danger",
            }
          )}
          <label className="font-weight-bold mt-4 pt-2" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className="form-control w-100 bg-light"
            id="password"
            name="password"
            value={password}
            onChange={this.handleChange}
            onKeyUp={(e) => {
              if (e.keyCode == 13) {
                this.handleSubmit();
              }
            }}
          />
          {this.validator.message("password", password, "required|min:7", {
            className: "text-danger",
          })}
          <div className="d-flex justify-content-between">
            <div className="custom-control custom-checkbox mt-3">
              <input
                type="checkbox"
                className="custom-control-input bg-dark"
                id="remember"
                checked={rememberme}
                onChange={() => this.toggle("rememberme")}
              />
              <label className="custom-control-label" htmlFor="remember">
                Remember me
              </label>
            </div>
            <div>
              <p
                className="mb-0 mt-3 fs-16 text-black line-height-19 font-Gilroy cursor-pointer"
                onClick={() => {
                  history.push("/login/forgot-pass");
                }}
              >
                <u>Forgot Password</u>?
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button
              className="btn w-100 py-2 font-weight-bold mainbg-btn"
              disabled={loginLoader}
              onClick={this.handleSubmit}
            >
              {" "}
              Login{" "}
              {loginLoader ? (
                <div
                  className="spinner-border ml-2 spinner-border-sm"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  );
};

let component = OtpLoginClass;

export const OtpLogin = connect(null, mapDispatchToProps)(component);
