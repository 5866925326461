import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAllReports,
  getReportsById,
  deleteReportsById,
} from "action/ContentAct";
import { TableWrapper, CommonPageLoader, DeletePopup } from "component/common";
import { history } from "service/helpers";
import {
  DetailPopup,
  ReportDetailPopup,
} from "component/contentManagement/Modal";
import moment from "moment";
import { getDescriptionData } from "service/helperFunctions";

export class ReportListClass extends Component {
  constructor(props) {
    super();
    this.state = {
      headerDetails: [
        {
          label: "Reports id",
        },
        {
          label: "Report title",
        },
        {
          label: "Report image",
        },
        {
          label: "Action",
        },
      ],
      isloading: false,
      pageMeta: {},
      contentManagement: [],
      isNewsView: false,
      reportDetails: {
        reportId: "",
        reportTitle: "",
        uploadImage: "",
        description: "",
        content: "",
        uploadDoc: "",
        status: null,
        reportType:'',
        fileName:''
      },
      isDeletePopup: false,
      reportId: "",
    };
  }

  toggle = (name, id) => {
    this.state.isNewsView
      ? this.setState((prevState) => ({
          [name]: !prevState[name],
        }))
      : this.props.getReportsById(id).then((data) => {
          let {
            reportId,
            reportTitle,
            uploadImage,
            description,
            content,
            uploadDoc,
            status,
            reportType,
            fileName
          } = data;
          this.setState((prevState) => ({
            reportDetails: {
              reportId: reportId,
              reportTitle: reportTitle,
              uploadImage: uploadImage,
              description: description,
              content: content,
              uploadDoc: uploadDoc,
              status: status,
              reportType: reportType,
              fileName: fileName
            },
            [name]: !prevState[name],
          }));
        });
  };

  handleAllReport = (page) => {
    this.props.getAllReports({ limit: 10, page: page }).then((data) => {
      this.setState({
        contentManagement: data?.results,
        pageMeta: data.pageMeta,
        isloading: false,
      });
    });
  };

  //handle delete
  handleDelete = () => {
    let { reportId } = this.state;
    this.props.deleteReportsById(reportId).then(() => {
      this.setState({ reportId: "" });
      this.handleAllReport(1);
      this.toggleDelete("isDeletePopup");
    });
  };

  toggleDelete = (name, id) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
      reportId: id,
    }));
  };

  componentDidMount() {
    this.setState({ isloading: true });
    this.handleAllReport(1);
  }

  render() {
    const {
      headerDetails,
      pageMeta = {},
      contentManagement,
      isNewsView,
      reportDetails,
      isloading,
      isDeletePopup,
    } = this.state;

    return (
      <>
        {isloading ? (
          <>
            <CommonPageLoader />
          </>
        ) : (
          <div className="table-container w-100 mt-4 pt-1">
            {contentManagement?.length !== 0 ? (
              <TableWrapper
                headerDetails={headerDetails}
                queryHandler={this.handleAllReport}
                pageMeta={pageMeta}
              >
                {contentManagement?.map((item, index) => {
                  let { reportTitle, uploadImage, reportId } = item;

                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {reportId}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            <p className="mb-0">
                              {getDescriptionData(reportTitle, 30)}
                            </p>
                          </div>
                        </td>
                        <td>
                          <img
                            style={{
                              width: "136px",
                              height: "69px",
                            }}
                            src={uploadImage}
                            alt="img"
                          />
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            <i
                              className="icon-eye mr-3 cursor-pointer fs-20"
                              onClick={() => {
                                this.toggle("isNewsView", reportId);
                              }}
                            ></i>
                            <i
                              className="icon-edit mr-3 fs-20 cursor-pointer"
                              onClick={() => {
                                history.push(`editReport/${reportId}`);
                              }}
                            ></i>
                            <i
                              className="icon-Delete fs-20 cursor-pointer"
                              onClick={() => {
                                this.toggleDelete("isDeletePopup", reportId);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                      <tr className="trans-bg">
                        <td className="empty-height" height="12"></td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </TableWrapper>
            ) : (
              <div className="text-center fs-18 text-black py-5">
                No records found !!!
              </div>
            )}
          </div>
        )}
        <ReportDetailPopup
          title="Report Details"
          details={reportDetails}
          isOpen={isNewsView}
          toggle={() => this.toggle("isNewsView")}
        />
        <DeletePopup
          isOpen={isDeletePopup}
          toggle={() => this.toggleDelete("isDeletePopup")}
          handleChange={() => this.handleDelete()}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllReports,
      getReportsById,
      deleteReportsById,
    },
    dispatch
  );
};

let component = ReportListClass;

export const ReportList = connect(null, mapDispatchToProps)(component);
