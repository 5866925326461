import React, { Component } from 'react';
import './AddCourse.scss';
import { connect } from 'react-redux';
import {
   NormalInput,
   NormalButton,
   NormalTimeInput,
   NormalDate,
   NormalSelect,
   NormalEditor,
   NormalCheckbox,
} from 'component/common';

import moment from 'moment';

import { history } from 'service/helpers';

import { bindActionCreators } from 'redux';

import SimpleReactValidator from 'simple-react-validator';
import { getDescriptionData } from 'service/helperFunctions';

import {
   getCategoryList,
   createCourse,
   fileUpload,
   updateCourse,
   getCertifyingInstituteDropdown,
   getAuthorDropdown,
} from 'action/CourseAct';

import { Toast } from 'service/toast';

export class CourseDetailsClass extends Component {
   constructor(props) {
      super(props);
      this.state = {
         toggleEvent: {
            save: 0,
            share: 0,
            like: 0,
            dislike: 0,
            download: 0,
         },
         fileName: '',
         fileArray: [],
         formFields: {
            title: '',
            releaseDate: '',
            category: '',
            instituteId: '',
            authorId: '',
            designation: '',
            difficultlyLevel: '',
            durationSeconds: '',
            durationHours: '',
            durationMinutes: '',
            partnership: '',
            description: '',
            skillsCovered: '',
            certifyingInstitutions: '',
            Author: '',
         },
         isImageUploading: false,
         categoryOptions: [],
         difficultlyLevelOptions: [
            { label: 'Beginner', value: 'Beginner' },
            { label: 'Intermediate', value: 'Intermediate' },
            { label: 'Advanced', value: 'Advanced' },
         ],
         certifyingInstituteOptions: [],
         authorOptions: [],
      };
   }

   validator = new SimpleReactValidator({
      validators: {
         checkHours: {
            message: 'Select Valid Hours',
            rule: (val, params, validator) => {
               let selected_minutes = this.state.formFields.durationHours;
               if (selected_minutes > 23) {
                  return false;
               } else {
                  return true;
               }
            },
         },
         checkMinutes: {
            message: 'Select Valid Minutes',
            rule: (val, params, validator) => {
               let selected_minutes = this.state.formFields.durationMinutes;
               if (selected_minutes > 59) {
                  return false;
               } else {
                  return true;
               }
            },
         },
         checkSeconds: {
            message: 'Select Valid Seconds',
            rule: (val, params, validator) => {
               let selected_minutes = this.state.formFields.durationSeconds;
               if (selected_minutes > 59) {
                  return false;
               } else {
                  return true;
               }
            },
         },
      },
      element: (message) => (
         <span className='error-message text-danger fs-16 pb-3'>{message}</span>
      ),
   });

   componentDidMount() {
      let {
         getCategoryList,
         isEdit,
         courseDetail,
         getCertifyingInstituteDropdown,
         getAuthorDropdown,
         match
      } = this.props;
      if (match && match.path.includes('addCourse/courseDetail')) {

         getCategoryList().then((data) => {
            let categoryList = [];
            data.map((list) => {
               categoryList.push({
                  label: list.categoryName,
                  value: list.courseCategoryId,
               });
            });
            this.setState({ categoryOptions: categoryList });
            getCertifyingInstituteDropdown().then(({ results }) => {
               let certifyingInstituteOptionsList = [];
               results.map((list) => {
                  certifyingInstituteOptionsList.push({
                     label: list.instituteName,
                     value: list.instituteId,
                  });
               });
               this.setState({
                  certifyingInstituteOptions: certifyingInstituteOptionsList,
               });
            });
            getAuthorDropdown().then(({ results }) => {
               let authorOptionsList = [];
               results.map((list) => {
                  authorOptionsList.push({
                     label: list.authorName,
                     value: list.authorId,
                  });
               });
               this.setState({ authorOptions: authorOptionsList });
            });
         });
      }

      if (isEdit) {
         this.updateData(courseDetail);
      }
   }

   componentDidUpdate(nextProps) {
      const { isEdit, courseDetail } = this.props;
      if (nextProps.isEdit) {
         if (
            JSON.stringify(nextProps.courseDetail) !==
            JSON.stringify(courseDetail)
         ) {
            this.updateData(courseDetail);
         }
      }
   }

   updateData = (courseDetail) => {
      // description
      let formFields = {
         title: courseDetail.title,
         releaseDate: new Date(courseDetail.releaseDate),
         category: courseDetail.courseCategoryId,
         instituteId: courseDetail.instituteId,
         authorId: courseDetail.authorId,
         designation: '',
         difficultlyLevel: courseDetail.difficultyLevel,
         durationSeconds: courseDetail.durationSeconds,
         durationHours: courseDetail.durationHours,
         durationMinutes: courseDetail.durationMinutes,
         partnership: courseDetail.partnerShip,
         description: courseDetail.description,
         skillsCovered: courseDetail.skillsCovered,
         certifyingInstitutions: courseDetail.certifyingInstitutions,
         Author: courseDetail.Author,
      };

      let toggleEvent = {
         save: courseDetail.isSaved ? 1 : 0,
         share: courseDetail.isLiked ? 1 : 0,
         like: courseDetail.isShared ? 1 : 0,
         dislike: courseDetail.isDownloaded ? 1 : 0,
         download: courseDetail.isDisliked ? 1 : 0,
      };

      let fileArray = [];

      fileArray.push(courseDetail.thumbnailFileUrl);

      this.setState({
         formFields,
         toggleEvent,
         fileName: courseDetail.thumbnailFileName,
         fileArray,
      });
   };

   handleInput = ({ target: { name, value } }) => {
      let {
         formFields,
         certifyingInstituteOptions,
         authorOptions,
      } = this.state;

      formFields[name] = value;

      if (name === 'instituteId') {
         certifyingInstituteOptions.map((list) => {
            if (list.value == value) {
               formFields['certifyingInstitutions'] = list.label;
            }
         });
      } else if (name === 'authorId') {
         authorOptions.map((list) => {
            if (list.value == value) {
               formFields['Author'] = list.label;
            }
         });
      }

      this.setState({
         formFields,
      });
   };

   uploadSingleFile = (e) => {
      //upload image
      let file = e.target.files[0];
      if (file !== undefined) {
         this.setState({ fileArray: [] }, () => {
            let { fileArray, fileName } = this.state;
            fileName = 'Uploading ....';

            let fileData = [];
            fileData.push(fileData);
            fileArray = fileData;
            this.setState({ isImageUploading: true });

            const config = {
               onUploadProgress: function (progressEvent) {
                  var percentCompleted = Math.round(
                     (progressEvent.loaded * 100) / progressEvent.total
                  );
                  document.getElementById(
                     'image-upload'
                  ).innerText = `Uploading ${percentCompleted}%`;
               },
            };

            const formData = new FormData();
            formData.append('file', file);

            this.props.fileUpload(formData, config).then((data) => {
               if (data !== undefined) {
                  let uploadedFile = [];
                  uploadedFile.push(data[0].fileUrl);
                  fileArray = uploadedFile;
                  fileName = data[0].fileName;
                  this.setState({
                     fileArray,
                     isImageUploading: false,
                     fileName,
                  });
               } else {
                  this.setState({
                     fileArray: [],
                     isImageUploading: false,
                     fileName: '',
                  });
               }
            });
            this.setState({
               fileArray,
               fileName,
            });
         });
      }
   };

   handleToggle = ({ target: { name, value } }) => {
      let toggleEvent = Object.assign({}, this.state.toggleEvent);
      toggleEvent[name] = value ? 1 : 0;
      this.setState({
         toggleEvent,
      });
   };

   onEditorChange = (editor) => {
      let { formFields } = this.state;
      formFields.description = editor.getData();
      this.setState({
         formFields,
      });
   };

   onEditorChangeForSkills = (editor) => {
      let { formFields } = this.state;
      formFields.skillsCovered = editor.getData();
      this.setState({
         formFields,
      });
   };

   handleSubmit = (isChangeStepper) => {
      let { handleStepperChange, isEdit, courseDetail } = this.props;

      let { fileArray, isImageUploading } = this.state;

      if (this.validator.allValid() && fileArray.length != 0) {
         if (!isImageUploading) {
            let { formFields, toggleEvent, fileName } = this.state;

            let {
               title,
               durationSeconds,
               durationHours,
               durationMinutes,
               releaseDate,
               category,
               description,
               partnership,
               authorId,
               instituteId,
               difficultlyLevel,
               skillsCovered,
               certifyingInstitutions,
               Author,
            } = formFields;

            let { save, share, like, dislike, download } = toggleEvent;

            let body = {
               title: title,
               thumbnailFileName: fileName,
               thumbnailFileUrl: fileArray.length != 0 ? fileArray[0] : '',
               durationHours: durationHours.toString(),
               courseCategoryId: parseInt(category),
               instituteId,
               authorId,
               description: description,
               durationMinutes: durationMinutes.toString(),
               durationSeconds: durationSeconds.toString(),
               releaseDate: moment(releaseDate).format('YYYY-MM-DD'),
               partnerShip: partnership,
               skillsCovered: skillsCovered,
               difficultyLevel: difficultlyLevel,
               isSaved: save == 0 ? false : true,
               isShared: share == 0 ? false : true,
               isDownloaded: download == 0 ? false : true,
               isLiked: like == 0 ? false : true,
               isDisliked: dislike == 0 ? false : true,
               certifyingInstitutions,
               Author,
            };

            if (isEdit) {
               body.courseId = courseDetail.courseId;

               this.props.updateCourse(body).then((data) => {
                  localStorage.setItem('courseId', data.courseId);

                  this.validator.hideMessages();
                  if (isChangeStepper) {
                     let path = this.props.match.path.split('/');

                     handleStepperChange('next');

                     if (path[3] === 'courseDetail') {
                        this.props.history.push({
                           state: { changeStepperIndex: true },
                        });

                        this.props.history.push(
                           `/admin/addCourse/section/${body.courseId}`
                        );
                     }
                  }
               });
            } else {
               this.props.createCourse(body).then((data) => {
                  localStorage.setItem('courseId', data.courseId);

                  this.validator.hideMessages();
                  if (isChangeStepper) {
                     let path = this.props.match.path.split('/');

                     handleStepperChange('next');

                     if (path[3] === 'courseDetail') {
                        this.props.history.push({
                           pathname: `section`,
                           state: { changeStepperIndex: true },
                        });
                     }
                  }
               });
            }
         } else {
            Toast({ type: 'error', message: 'Please wait Image is uploading' });
         }
      } else {
         this.validator.showMessages();
         this.forceUpdate();
      }
   };

   render() {
      let { isEdit } = this.props;
      let {
         formFields,
         categoryOptions,
         toggleEvent,
         difficultlyLevelOptions,
         fileName,
         fileArray,
         isImageUploading,
         certifyingInstituteOptions,
         authorOptions,
      } = this.state;

      let { save, share, like, dislike, download } = toggleEvent;

      let {
         title,
         durationSeconds,
         durationHours,
         durationMinutes,
         releaseDate,
         category,
         description,
         partnership,
         authorId,
         instituteId,
         difficultlyLevel,
         skillsCovered,
      } = formFields;

      return (
         <>
            <div className='course-details'>
               <div className=''>
                  <h3 className='course-heading mb-0'>Course details</h3>
               </div>
               <div className=''>
                  <div className='row'>
                     <div className='col-6'>
                        <NormalInput
                           label='Course title'
                           type='text'
                           onChange={this.handleInput}
                           value={title}
                           placeholder='eg. Agriculture '
                           name='title'
                           required={true}
                        />
                        {this.validator.message(
                           'title',
                           title,
                           'required|max:70'
                        )}
                     </div>
                     <div className='col-6'>
                        <NormalSelect
                           label={'Category'}
                           placeholder='select'
                           value={category}
                           arrow={true}
                           name='category'
                           options={categoryOptions}
                           handleChange={this.handleInput}
                           required={true}
                        />
                        {this.validator.message(
                           'category',
                           category,
                           'required'
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-12'>
                        <NormalEditor
                           label='Course description'
                           data={description}
                           onChange={this.onEditorChange}
                           required={true}
                        />
                        {this.validator.message(
                           'description',
                           description,
                           'required|max:500'
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-12'>
                        <NormalEditor
                           label='Skills covered in the course'
                           data={skillsCovered}
                           onChange={this.onEditorChangeForSkills}
                           required={true}
                        />
                        {this.validator.message(
                           'skillsCovered',
                           skillsCovered,
                           'required|max:100'
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-6'>
                        <NormalSelect
                           label={'Difficulty level'}
                           placeholder='select'
                           value={difficultlyLevel}
                           arrow={true}
                           name='difficultlyLevel'
                           options={difficultlyLevelOptions}
                           handleChange={this.handleInput}
                           required={true}
                        />
                        {this.validator.message(
                           'difficultlyLevel',
                           difficultlyLevel,
                           'required'
                        )}
                     </div>

                     <div className='col-6 duration-area'>
                        <div className='fs-16 text-black font-Gilroy pb-2'>
                           Total duration
                           <span className='text-danger pl-2 fs-18'>*</span>
                        </div>
                        <div className='row'>
                           <div className='col-4'>
                              <NormalTimeInput
                                 type='number'
                                 onChange={this.handleInput}
                                 value={durationHours}
                                 placeholder='00  Hrs'
                                 name='durationHours'
                              />
                              {this.validator.message(
                                 'durationHours',
                                 durationHours,
                                 'required|min:1|max:2|checkHours'
                              )}
                           </div>
                           <div className='col-4'>
                              <NormalTimeInput
                                 type='number'
                                 onChange={this.handleInput}
                                 value={durationMinutes}
                                 placeholder='00  Min'
                                 name='durationMinutes'
                              />
                              {this.validator.message(
                                 'durationMinutes',
                                 durationMinutes,
                                 'required|min:1|max:2|checkMinutes'
                              )}
                           </div>
                           <div className='col-4'>
                              <NormalTimeInput
                                 type='number'
                                 onChange={this.handleInput}
                                 value={durationSeconds}
                                 placeholder='00  Sec'
                                 name='durationSeconds'
                              />
                              {this.validator.message(
                                 'durationSeconds',
                                 durationSeconds,
                                 'required|min:1|max:2|checkSeconds'
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-6'>
                        <NormalSelect
                           label={'Certifying institute'}
                           placeholder='select'
                           value={instituteId}
                           arrow={true}
                           name='instituteId'
                           options={certifyingInstituteOptions}
                           handleChange={this.handleInput}
                           required={true}
                        />
                        {this.validator.message(
                           'instituteId',
                           instituteId,
                           'required'
                        )}
                     </div>
                     <div className='col-6'>
                        <NormalDate
                           value={releaseDate}
                           name='releaseDate'
                           placeholder='DD/MM/YYYY'
                           onChange={this.handleInput}
                           mindate={true}
                           maxdate={false}
                           timeInput={false}
                           label={'Publication date'}
                           required={true}
                        />
                        {this.validator.message(
                           'releaseDate',
                           releaseDate,
                           'required'
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-6'>
                        <NormalSelect
                           label={'Author'}
                           placeholder='select'
                           value={authorId}
                           arrow={true}
                           name='authorId'
                           options={authorOptions}
                           handleChange={this.handleInput}
                           required={true}
                        />
                        {this.validator.message(
                           'authorId',
                           authorId,
                           'required'
                        )}
                     </div>
                     <div className='col-6'>
                        <NormalInput
                           label='Content partners'
                           type='text'
                           onChange={this.handleInput}
                           value={partnership}
                           placeholder=''
                           name='partnership'
                           required={true}
                        />
                        {this.validator.message(
                           'partnership',
                           partnership,
                           'required|max:50'
                        )}
                     </div>
                  </div>
                  <div className='mb-2'>
                     <div className='fs-17 text-black font-Gilroy pb-4 mt-3'>
                        Upload thumbnail image
                        <span className='text-danger pl-2 fs-18'>*</span>
                     </div>
                     <div className='d-flex align-items-center w-100 btn-file-input'>
                        <button className=''>
                           <input
                              type='file'
                              className='h-100'
                              accept='image/*'
                              onChange={this.uploadSingleFile}
                           ></input>
                           {isImageUploading ? (
                              <div
                                 id='image-upload'
                                 className='d-flex align-items-center'
                              ></div>
                           ) : fileName == '' ?
                                 'Upload'
                                 :
                                 getDescriptionData(fileName, 20)
                           }
                        </button>
                        {fileArray.length != 0 && !isImageUploading ? (
                           <div className='ml-4'>
                              <img
                                 src={fileArray[0]}
                                 alt=''
                                 className='upload-image-preview'
                              />
                           </div>
                        ) : (
                              ''
                           )}
                     </div>
                     {this.validator.message(
                        'image',
                        fileArray[0],
                        'required'
                     )}
                     <div className='row'>
                        <div className='col-12 my-4 py-2'>
                           <div className='fs-17 text-black font-Gilroy pb-3 mb-1'>
                              Viewers able to
                           </div>
                           <div className='row'>
                              <div className='col'>
                                 <NormalCheckbox
                                    value=''
                                    label='Save'
                                    name='save'
                                    checked={save}
                                    onChange={(event) =>
                                       this.handleToggle(event)
                                    }
                                 />
                              </div>
                              <div className='col'>
                                 <NormalCheckbox
                                    value=''
                                    label='Share'
                                    name='share'
                                    checked={share}
                                    onChange={(event) =>
                                       this.handleToggle(event)
                                    }
                                 />
                              </div>
                              <div className='col'>
                                 <NormalCheckbox
                                    value=''
                                    label='Download'
                                    name='download'
                                    checked={download}
                                    onChange={(event) =>
                                       this.handleToggle(event)
                                    }
                                 />
                              </div>
                              <div className='col'>
                                 <NormalCheckbox
                                    value=''
                                    label='Like'
                                    name='like'
                                    checked={like}
                                    onChange={(event) =>
                                       this.handleToggle(event)
                                    }
                                 />
                              </div>
                              <div className='col'>
                                 <NormalCheckbox
                                    value=''
                                    label='Dislike'
                                    name='dislike'
                                    checked={dislike}
                                    onChange={(event) =>
                                       this.handleToggle(event)
                                    }
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className='d-flex align-items-center add-course-buttons'>
               <div className='ml-auto d-flex'>
                  <NormalButton
                     label='Discard changes'
                     outline={true}
                     className='px-4 mr-2 border-none'
                     onClick={() => history.push('/admin/courseManagement')}
                  />
                  {isEdit ? (
                     <NormalButton
                        label='Update & next'
                        mainbg={true}
                        className='px-4'
                        onClick={this.handleSubmit.bind(this, true)}
                     />
                  ) : (
                        <NormalButton
                           label='Save & next'
                           mainbg={true}
                           className='px-4'
                           onClick={this.handleSubmit.bind(this, true)}
                        />
                     )}
               </div>
            </div>
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         getCategoryList,
         createCourse,
         updateCourse,
         fileUpload,
         getCertifyingInstituteDropdown,
         getAuthorDropdown,
      },
      dispatch
   );
};

let component = CourseDetailsClass;

export const CourseDetails = connect(null, mapDispatchToProps)(component);
