import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./masters.scss";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { NormalSelect, NormalInput, NormalButton } from "component/common";
import SimpleReactValidator from "simple-react-validator";
import {
  pincodemaster,
  districtList,
  addbusinessActivity,
  addkeyfinancialDetails,
  addCrops,
  stateList,
  addDistrict,
  addfinancialDetails,
  addeducationQualification,
  addfinancialYear,
  addinfraDetails,
  addlicenceDetails,
  addliveStockDetails,
  addState,
  addAssetDetails,
  addinsititutionDetails,
  addKycDocumentDetails,
} from "action/master";

import { Toast } from "service/toast";

import { fileUpload } from "action/CourseAct";
export class MastersClass extends Component {
  state = {
    mastersList: [
      {
        name: "Pincode",
      },
      {
        name: "Business activity",
      },
      {
        name: "Asset",
      },
      {
        name: "Crops",
      },
      {
        name: "District",
      },
      {
        name: "Education qualification",
      },
      {
        name: "Financial institution",
      },
      {
        name: "Financial year",
      },
      {
        name: "Infrastructure",
      },
      {
        name: "Institution type",
      },
      {
        name: "KYC document",
      },
      {
        name: "Licence master",
      },
      {
        name: "Livestock",
      },
      {
        name: "State",
      },
    ],
    isAddNewModal: false,
    addModalType: "",
    pincodeForm: {
      districtId: "",
      pincode: "",
    },
    businessActivity: {
      businessName: "",
      businessIconUrl: "",
    },
    crops: {
      cropName: "",
      cropIconUrl: "",
    },
    districtDetail: {
      stateId: "",
      districtName: "",
    },
    financialMaster: {
      institutionType: "",
      institutionIconUrl: "",
    },
    educationDetail: {
      qualificationType: "",
      qualificationIconUrl: "",
    },
    financialYearDetails: {
      fymYear: "",
    },
    infrastructureDetails: {
      InfrastructureIconUrl: "",
      infrastructureName: "",
    },
    institutionDetails: {
      institutiontype: "",
      institutionUrl: "",
    },
    licenceMaster: {
      licenseIconUrl: "",
      licenseName: "",
    },
    livestockDetails: {
      liveStockName: "",
      liveStockIconUrl: "",
    },
    stateName: "",
    assetDetails: {
      assetName: "",
      assetType: "",
      assetSubtype: "",
      assetIconUrl: "",
    },
    kycDetails: {
      kycIconUrl: "",
      kycDoc: "",
      kycType: "",
    },
    isfinancialIconUploading: false,
    iscropiconUploading: false,
    isbusinessiconUploading: false,
    iseducationiconUploading: false,
    isinfraIconUploading: false,
    isInstitutioIconUploading: false,
    isLicenceIconUploading: false,
    isAssetIconUploading: false,
    isLivestockIconUploading: false,
    isKycIconUploading: false,
    districtList: [],
    stateList: [],
    kycTypeList: [
      {
        value: "Identity",
        label: "Identity",
      },
      {
        value: "Address",
        label: "Address",
      },
    ],
    kycDocList: [
      {
        value: "Voter ID",
        label: "Voter ID",
      },
      {
        value: "Pan Card",
        label: "Pan Card",
      },
      {
        value: "Aadhar Card",
        label: "Aadhar Card",
      },
      {
        value: "Driving License",
        label: "Driving License",
      },
    ],
  };
  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-16 pb-3">{message}</span>
    ),
    autoForceUpdate: this,
  });

  //Lifecycles
  componentDidMount() {
    //District List
    this.props.districtList().then(({ data }) => {
      let districtData = [];
      data.map((list) => {
        districtData.push({
          label: list.districtName,
          value: list.districtId,
        });
      });
      this.setState({ districtList: districtData });
    });

    //State List
    this.props.stateList().then(({ data }) => {
      let stateData = [];
      data.map((list) => {
        stateData.push({ label: list.stateName, value: list.stateId });
      });
      this.setState({ stateList: stateData });
    });
  }

  //handle submit
  handleFormSubmit = (currentMaster) => {
    let {
      pincodeForm,
      businessActivity,
      crops,
      financialMaster,
      districtDetail,
      educationDetail,
      financialYearDetails,
      infrastructureDetails,
      licenceMaster,
      livestockDetails,
      stateName,
      assetDetails,
      institutionDetails,
      kycDetails,
      isfinancialIconUploading,
      iscropiconUploading,
      isbusinessiconUploading,
      iseducationiconUploading,
      isinfraIconUploading,
      isInstitutioIconUploading,
      isLicenceIconUploading,
      isAssetIconUploading,
      isLivestockIconUploading,
      isKycIconUploading,
    } = this.state;
    if (this.validator.allValid()) {
      if (
        !isfinancialIconUploading &&
        !iscropiconUploading &&
        !isbusinessiconUploading &&
        !iseducationiconUploading &&
        !isinfraIconUploading &&
        !isInstitutioIconUploading &&
        !isLicenceIconUploading &&
        !isLivestockIconUploading &&
        !isAssetIconUploading &&
        !isKycIconUploading
      ) {
        if (currentMaster === "Pincode") {
          this.props.pincodemaster(pincodeForm).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "Business activity") {
          this.props.addbusinessActivity(businessActivity).then(() => {
            this.props.addkeyfinancialDetails(businessActivity).then(() => {
              this._clearData();
            });
          });
        } else if (currentMaster === "Crops") {
          this.props.addCrops(crops).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "District") {
          this.props.addDistrict(districtDetail).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "Financial institution") {
          this.props.addfinancialDetails(financialMaster).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "Institution type") {
          this.props.addinsititutionDetails(institutionDetails).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "Education qualification") {
          this.props.addeducationQualification(educationDetail).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "Financial year") {
          this.props.addfinancialYear(financialYearDetails).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "Infrastructure") {
          this.props.addinfraDetails(infrastructureDetails).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "Licence master") {
          this.props.addlicenceDetails(licenceMaster).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "Livestock") {
          this.props.addliveStockDetails(livestockDetails).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "State") {
          this.props.addState({ stateName }).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "Asset") {
          this.props.addAssetDetails(assetDetails).then(() => {
            this._clearData();
          });
        } else if (currentMaster === "KYC document") {
          this.props.addKycDocumentDetails(kycDetails).then(() => {
            this._clearData();
          });
        }
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.validator.showMessages();
    }
  };

  handleInputChange = (event) => {
    let {
      pincodeForm,
      businessActivity,
      crops,
      districtDetail,
      financialMaster,
      educationDetail,
      financialYearDetails,
      infrastructureDetails,
      institutionDetails,
      licenceMaster,
      livestockDetails,
      stateName,
      assetDetails,
      kycDetails,
    } = this.state;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "businessName") {
      businessActivity[name] = value;
    } else if (name === "cropName") {
      crops[name] = value;
    } else if (name === "districtName" || name === "stateId") {
      districtDetail[name] = value;
    } else if (name === "institutionType" || name === "institutionIconUrl") {
      financialMaster[name] = value;
    } else if (name === "qualificationType") {
      educationDetail[name] = value;
    } else if (name === "fymYear") {
      financialYearDetails[name] = value;
    } else if (name === "infrastructureName") {
      infrastructureDetails[name] = value;
    } else if (name === "districtId" || name === "pincode") {
      pincodeForm[name] = value;
    } else if (name === "institutiontype") {
      institutionDetails[name] = value;
    } else if (name === "licenseName") {
      licenceMaster[name] = value;
    } else if (name === "liveStockName") {
      livestockDetails[name] = value;
    } else if (name === "stateName") {
      stateName = value;
    } else if (
      name === "assetName" ||
      name === "assetType" ||
      name === "assetSubtype"
    ) {
      assetDetails[name] = value;
    } else if (name === "kycDoc" || name === "kycType") {
      kycDetails[name] = value;
    }
    this.setState({
      educationDetail,
      financialMaster,
      businessActivity,
      districtDetail,
      infrastructureDetails,
      crops,
      pincodeForm,
      financialYearDetails,
      institutionDetails,
      livestockDetails,
      assetDetails,
      stateName,
    });
  };

  //Upload Business activy Icon
  uploadactivityIcon = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { businessActivity } = this.state;
      businessActivity.businessIconUrl = file;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "image-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", file);
      this.setState({ isbusinessiconUploading: true });

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          businessActivity.businessIconUrl = data[0].fileUrl;
        } else {
          businessActivity.businessIconUrl = "";
        }
        this.setState({
          businessActivity,
          isbusinessiconUploading: false,
        });
      });
      this.setState({
        businessActivity,
      });
    }
  };

  //Upload crop icon
  uploadaCropIcon = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { crops } = this.state;
      crops.cropIconUrl = file;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "image-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", file);
      this.setState({ iscropiconUploading: true });

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          crops.cropIconUrl = data[0].fileUrl;
        } else {
          crops.cropIconUrl = "";
        }
        this.setState({
          crops,
          iscropiconUploading: false,
        });
      });
      this.setState({
        crops,
      });
    }
  };

  //Upload Education qualification icon
  uploadQualificationIcon = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { educationDetail } = this.state;
      educationDetail.qualificationIconUrl = file;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "image-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", file);
      this.setState({ iseducationiconUploading: true });

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          educationDetail.qualificationIconUrl = data[0].fileUrl;
        } else {
          educationDetail.qualificationIconUrl = "";
        }
        this.setState({
          educationDetail,
          iseducationiconUploading: false,
        });
      });
      this.setState({
        educationDetail,
      });
    }
  };

  //Upload financial icon
  uploadaFinancialIcon = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { financialMaster } = this.state;
      financialMaster.institutionIconUrl = file;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "image-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", file);
      this.setState({ isfinancialIconUploading: true });

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          financialMaster.institutionIconUrl = data[0].fileUrl;
        } else {
          financialMaster.institutionIconUrl = "";
        }
        this.setState({
          financialMaster,
          isfinancialIconUploading: false,
        });
      });
      this.setState({
        financialMaster,
      });
    }
  };

  //upload infrastructure icon
  uploadInfraIcon = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { infrastructureDetails } = this.state;
      infrastructureDetails.InfrastructureIconUrl = file;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "image-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", file);
      this.setState({ isinfraIconUploading: true });

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          infrastructureDetails.InfrastructureIconUrl = data[0].fileUrl;
        } else {
          infrastructureDetails.InfrastructureIconUrl = "";
        }
        this.setState({
          infrastructureDetails,
          isinfraIconUploading: false,
        });
      });
      this.setState({
        infrastructureDetails,
      });
    }
  };

  //upload institution icon
  uploadInstitutionIcon = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { institutionDetails } = this.state;
      institutionDetails.institutionUrl = file;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "image-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", file);
      this.setState({ isInstitutioIconUploading: true });

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          institutionDetails.institutionUrl = data[0].fileUrl;
        } else {
          institutionDetails.institutionUrl = "";
        }
        this.setState({
          institutionDetails,
          isInstitutioIconUploading: false,
        });
      });
      this.setState({
        institutionDetails,
      });
    }
  };

  //upload licence icon
  uploadlicenceIcon = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { licenceMaster } = this.state;
      licenceMaster.licenseIconUrl = file;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "image-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", file);
      this.setState({ isLicenceIconUploading: true });

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          licenceMaster.licenseIconUrl = data[0].fileUrl;
        } else {
          licenceMaster.licenseIconUrl = "";
        }
        this.setState({
          licenceMaster,
          isLicenceIconUploading: false,
        });
      });
      this.setState({
        licenceMaster,
      });
    }
  };

  //upload live stock icon
  uploadliveStockIcon = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { livestockDetails } = this.state;
      livestockDetails.liveStockIconUrl = file;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "image-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", file);
      this.setState({ isLivestockIconUploading: true });

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          livestockDetails.liveStockIconUrl = data[0].fileUrl;
        } else {
          livestockDetails.liveStockIconUrl = "";
        }
        this.setState({
          livestockDetails,
          isLivestockIconUploading: false,
        });
      });
      this.setState({
        livestockDetails,
      });
    }
  };

  //upload Asset icon
  uploadAssetIcon = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { assetDetails } = this.state;
      assetDetails.assetIconUrl = file;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "image-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", file);
      this.setState({ isAssetIconUploading: true });

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          assetDetails.assetIconUrl = data[0].fileUrl;
        } else {
          assetDetails.assetIconUrl = "";
        }
        this.setState({
          assetDetails,
          isAssetIconUploading: false,
        });
      });
      this.setState({
        assetDetails,
      });
    }
  };

  uploadKycIcon = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { kycDetails } = this.state;
      kycDetails.kycIconUrl = file;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "image-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", file);
      this.setState({ isKycIconUploading: true });

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          kycDetails.kycIconUrl = data[0].fileUrl;
        } else {
          kycDetails.kycIconUrl = "";
        }
        this.setState({
          kycDetails,
          isKycIconUploading: false,
        });
      });
      this.setState({
        kycDetails,
      });
    }
  };

  handleBack = () => {
    let {
      isfinancialIconUploading,
      iscropiconUploading,
      isbusinessiconUploading,
      iseducationiconUploading,
      isinfraIconUploading,
      isInstitutioIconUploading,
      isLicenceIconUploading,
      isAssetIconUploading,
      isLivestockIconUploading,
      isKycIconUploading,
    } = this.state;
    if (
      !isfinancialIconUploading &&
      !iscropiconUploading &&
      !isbusinessiconUploading &&
      !iseducationiconUploading &&
      !isinfraIconUploading &&
      !isInstitutioIconUploading &&
      !isLicenceIconUploading &&
      !isLivestockIconUploading &&
      !isAssetIconUploading &&
      !isKycIconUploading
    ) {
      this._clearData();
    } else {
      Toast({ type: "error", message: "Please wait Image is uploading" });
    }
  };

  //Clear data
  _clearData = () => {
    this.validator.hideMessages();
    this.setState({
      isAddNewModal: false,
      pincodeForm: {
        districtId: "",
        pincode: "",
      },
      businessActivity: {
        businessName: "",
        businessIconUrl: "",
      },
      crops: {
        cropName: "",
        cropIconUrl: "",
      },
      districtDetail: {
        stateId: "",
        districtName: "",
      },
      financialMaster: {
        institutionType: "",
        institutionIconUrl: "",
      },
      educationDetail: {
        qualificationType: "",
        qualificationIconUrl: "",
      },
      financialYearDetails: {
        fymYear: "",
      },
      infrastructureDetails: {
        InfrastructureIconUrl: "",
        infrastructureName: "",
      },
      institutionDetails: {
        institutiontype: "",
        institutionUrl: "",
      },
      licenceMaster: {
        licenseIconUrl: "",
        licenseName: "",
      },
      livestockDetails: {
        liveStockName: "",
        liveStockIconUrl: "",
      },
      stateName: "",
      assetDetails: {
        assetName: "",
        assetType: "",
        assetSubtype: "",
        assetIconUrl: "",
      },
    });
  };

  render() {
    this.validator.purgeFields();
    let {
      mastersList,
      isAddNewModal,
      addModalType,
      pincodeForm,
      districtList,
      isbusinessiconUploading,
      businessActivity,
      iscropiconUploading,
      crops,
      stateList,
      districtDetail,
      iseducationiconUploading,
      financialMaster,
      isfinancialIconUploading,
      educationDetail,
      financialYearDetails,
      isyearIconUploading,
      infrastructureDetails,
      isinfraIconUploading,
      isInstitutioIconUploading,
      institutionDetails,
      isLicenceIconUploading,
      licenceMaster,
      livestockDetails,
      isLivestockIconUploading,
      stateName,
      assetDetails,
      isAssetIconUploading,
      kycDetails,
      kycDocList,
      kycTypeList,
      isKycIconUploading,
    } = this.state;

    let { businessName, businessIconUrl } = businessActivity;

    let { cropName, cropIconUrl } = crops;

    let { stateId, districtName } = districtDetail;
    let { institutionType, institutionIconUrl } = financialMaster;

    let { qualificationType, qualificationIconUrl } = educationDetail;

    let { fymYear } = financialYearDetails;

    let { InfrastructureIconUrl, infrastructureName } = infrastructureDetails;

    let { institutiontype, institutionUrl } = institutionDetails;

    let { licenseName, licenseIconUrl } = licenceMaster;

    let { liveStockName, liveStockIconUrl } = livestockDetails;

    let { assetName, assetType, assetSubtype, assetIconUrl } = assetDetails;

    return (
      <div className="masters-component mt-4">
        <div className="row">
          {mastersList.map(({ name }, index) => (
            <div
              className="col-md-3 text-center mb-4"
              key={index}
              onClick={() =>
                this.setState({
                  isAddNewModal: true,
                  addModalType: name,
                })
              }
            >
              <div className="card">
                <div className="card-body">
                  <img
                    className="card-img-top mb-2"
                    src="https://gatewaycdn.azureedge.net/images/25-License-1-e15880132889192018.png"
                    alt=""
                  />
                  <h5 className="card-title">{name}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Modal
          isOpen={isAddNewModal}
          toggle={() => this.setState({ isAddNewModal: !isAddNewModal })}
          className="modal-dialog-centered masters-addmodal"
        >
          <div className="p-3 w-100 d-flex justify-content-between">
            <div className="fs-16 font-weight-bold">
              Add {addModalType.toLowerCase()}
            </div>
            <div>
              <i
                className="icon-close fs-16 cursor-pointer"
                onClick={() => this.handleBack()}
              ></i>
            </div>
          </div>
          <ModalBody className="pt-0">
            {addModalType === "Pincode" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalSelect
                    label="District"
                    placeholder="select"
                    arrow={true}
                    value={pincodeForm.districtId}
                    options={districtList}
                    name="districtId"
                    handleChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "district",
                    pincodeForm.districtId,
                    "required"
                  )}
                </div>
                <div className="col-md-12">
                  <NormalInput
                    label="Pincode"
                    type="number"
                    onChange={this.handleInputChange}
                    value={pincodeForm.pincode}
                    placeholder="Enter Pincode"
                    name="pincode"
                    required={true}
                  />
                  {this.validator.message(
                    "pincode",
                    pincodeForm.pincode,
                    "required|max:9"
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "Business activity" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="Activity name"
                    placeholder="Activity name"
                    arrow={true}
                    value={businessName}
                    name="businessName"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "activity name",
                    businessName,
                    "required|max:20"
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-2">
                      Activity icon{" "}
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className="h-100"
                          accept="image/*"
                          onChange={this.uploadactivityIcon}
                        ></input>
                        {isbusinessiconUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : businessIconUrl === "" ? (
                          "Upload"
                        ) : (
                          "Uploaded"
                        )}
                      </button>
                      {businessIconUrl !== "" && !isbusinessiconUploading ? (
                        <div className="ml-4">
                          <img
                            src={businessIconUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "icon",
                      businessIconUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "Crops" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="Crop name"
                    placeholder="Crop name"
                    arrow={true}
                    value={cropName}
                    name="cropName"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "crop Name ",
                    cropName,
                    "required|max:20"
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-2">
                      Crop icon
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className="h-100"
                          accept="image/*"
                          onChange={this.uploadaCropIcon}
                        ></input>
                        {iscropiconUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : cropIconUrl == "" ? (
                          "Upload"
                        ) : (
                          "Uploaded"
                        )}
                      </button>
                      {cropIconUrl != "" && !iscropiconUploading ? (
                        <div className="ml-4">
                          <img
                            src={cropIconUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "crop icon ",
                      cropIconUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "District" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalSelect
                    label="State"
                    placeholder="select"
                    arrow={true}
                    value={stateId}
                    options={stateList}
                    name="stateId"
                    handleChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message("State", stateId, "required")}
                </div>
                <div className="col-md-12">
                  <NormalInput
                    label="District"
                    type="text"
                    onChange={this.handleInputChange}
                    value={districtName}
                    placeholder="District Name"
                    name="districtName"
                    required={true}
                  />
                  {this.validator.message(
                    "district name",
                    districtName,
                    "required|max:40"
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "Education qualification" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="Qualification type"
                    placeholder="Qualification Type"
                    arrow={true}
                    value={qualificationType}
                    name="qualificationType"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "qualification",
                    qualificationType,
                    "required|max:40"
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-2">
                      Qualification icon
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className="h-100"
                          accept="image/*"
                          onChange={this.uploadQualificationIcon}
                        ></input>
                        {iseducationiconUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : qualificationIconUrl == "" ? (
                          "Upload"
                        ) : (
                          "Uploaded"
                        )}
                      </button>
                      {qualificationIconUrl != "" &&
                      !iseducationiconUploading ? (
                        <div className="ml-4">
                          <img
                            src={qualificationIconUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "qualification icon",
                      qualificationIconUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "Financial institution" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="Institution type"
                    placeholder="Institution Type"
                    arrow={true}
                    value={institutionType}
                    name="institutionType"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "institution",
                    institutionType,
                    "required|max:40"
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-2">
                      Institution icon
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className=""
                          accept="image/*"
                          onChange={this.uploadaFinancialIcon}
                        ></input>
                        {isfinancialIconUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : institutionIconUrl == "" ? (
                          "Upload"
                        ) : (
                          "Uploaded"
                        )}
                      </button>
                      {institutionIconUrl != "" && !isfinancialIconUploading ? (
                        <div className="ml-4">
                          <img
                            src={institutionIconUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "institution icon",
                      institutionIconUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "Financial year" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="Financial year"
                    placeholder="Financial Year"
                    arrow={true}
                    value={fymYear}
                    name="fymYear"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message("year", fymYear, "required|max:20")}
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "Infrastructure" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="Infrastructure name"
                    placeholder="Infrastructure Name"
                    arrow={true}
                    value={infrastructureName}
                    name="infrastructureName"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "Infrastructure name",
                    infrastructureName,
                    "required|max:40"
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-2">
                      Infrastructure icon
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className="h-100"
                          accept="image/*"
                          onChange={this.uploadInfraIcon}
                        ></input>
                        {isinfraIconUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : InfrastructureIconUrl == "" ? (
                          "Upload"
                        ) : (
                          "Uploaded"
                        )}
                      </button>
                      {InfrastructureIconUrl != "" && !isinfraIconUploading ? (
                        <div className="ml-4">
                          <img
                            src={InfrastructureIconUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "Infrastructure icon",
                      InfrastructureIconUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "Institution type" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="Institution type"
                    placeholder="Institution Type"
                    arrow={true}
                    value={institutiontype}
                    name="institutiontype"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "institution",
                    institutiontype,
                    "required|max:40"
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-2">
                      Institution icon
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className="h-100"
                          accept="image/*"
                          onChange={this.uploadInstitutionIcon}
                        ></input>
                        {isInstitutioIconUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : institutionUrl == "" ? (
                          "Upload"
                        ) : (
                          "Uploaded"
                        )}
                      </button>
                      {institutionUrl != "" && !isInstitutioIconUploading ? (
                        <div className="ml-4">
                          <img
                            src={institutionUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "institution icon",
                      institutionUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "Licence master" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="Licence type"
                    placeholder="Licence Type"
                    arrow={true}
                    value={licenseName}
                    name="licenseName"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "licence name",
                    licenseName,
                    "required|max:40"
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-2">
                      Licence icon
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className="h-100"
                          accept="image/*"
                          onChange={this.uploadlicenceIcon}
                        ></input>
                        {isLicenceIconUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : licenseIconUrl == "" ? (
                          "Upload"
                        ) : (
                          "Uploaded"
                        )}
                      </button>
                      {licenseIconUrl != "" && !isLicenceIconUploading ? (
                        <div className="ml-4">
                          <img
                            src={licenseIconUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "licence icon",
                      licenseIconUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "Livestock" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="Livestock name"
                    placeholder="Livestock Name"
                    arrow={true}
                    value={liveStockName}
                    name="liveStockName"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "livestock name",
                    liveStockName,
                    "required|max:40"
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-2">
                      Licence icon
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className="h-100"
                          accept="image/*"
                          onChange={this.uploadliveStockIcon}
                        ></input>
                        {isLivestockIconUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : liveStockIconUrl == "" ? (
                          "Upload"
                        ) : (
                          "Uploaded"
                        )}
                      </button>
                      {liveStockIconUrl != "" && !isLivestockIconUploading ? (
                        <div className="ml-4">
                          <img
                            src={liveStockIconUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "livestock icon",
                      liveStockIconUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "State" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="State"
                    placeholder="State"
                    arrow={true}
                    value={stateName}
                    name="stateName"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "state name",
                    stateName,
                    "required|max:40"
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {addModalType === "Asset" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalInput
                    label="Asset name"
                    placeholder="Asset Name"
                    arrow={true}
                    value={assetName}
                    name="assetName"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "asset name",
                    assetName,
                    "required|max:40"
                  )}
                </div>
                <div className="col-md-12">
                  <NormalInput
                    label="Asset type"
                    placeholder="Asset Type"
                    arrow={true}
                    value={assetType}
                    name="assetType"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "asset type",
                    assetType,
                    "required|max:40"
                  )}
                </div>
                <div className="col-md-12">
                  <NormalInput
                    label="Asset subtype"
                    placeholder="Asset Subtype"
                    arrow={true}
                    value={assetSubtype}
                    name="assetSubtype"
                    onChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "asset subtype",
                    assetSubtype,
                    "required|max:40"
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-2">
                      Asset icon
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className="h-100"
                          accept="image/*"
                          onChange={this.uploadAssetIcon}
                        ></input>
                        {isAssetIconUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : assetIconUrl == "" ? (
                          "Upload"
                        ) : (
                          "Uploaded"
                        )}
                      </button>
                      {assetIconUrl != "" && !isAssetIconUploading ? (
                        <div className="ml-4">
                          <img
                            src={assetIconUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "asset icon",
                      assetIconUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            ) : addModalType === "KYC document" ? (
              <div className="row">
                <div className="col-md-12">
                  <NormalSelect
                    label="KYC type"
                    placeholder="select"
                    arrow={true}
                    value={kycDetails.kycType}
                    options={kycTypeList}
                    name="kycType"
                    handleChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "kycType",
                    kycDetails.kycType,
                    "required"
                  )}
                </div>
                <div className="col-md-12">
                  <NormalSelect
                    label="KYC document"
                    placeholder="select"
                    arrow={true}
                    value={kycDetails.kycDoc}
                    options={kycDocList}
                    name="kycDoc"
                    handleChange={this.handleInputChange}
                    required={true}
                  />
                  {this.validator.message(
                    "kycDoc",
                    kycDetails.kycDoc,
                    "required"
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-2">
                      KYC icon
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className="h-100"
                          accept="image/*"
                          onChange={this.uploadKycIcon}
                        ></input>
                        {isKycIconUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : kycDetails.kycIconUrl == "" ? (
                          "Upload"
                        ) : (
                          "Uploaded"
                        )}
                      </button>
                      {kycDetails.kycIconUrl != "" && !isKycIconUploading ? (
                        <div className="ml-4">
                          <img
                            src={kycDetails.kycIconUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "kyc icon",
                      kycDetails.kycIconUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </ModalBody>
          <ModalFooter>
            <div className="mx-auto d-flex">
              <NormalButton
                className="mr-3"
                label="Close"
                outline={true}
                onClick={() => this._clearData()}
              />
              <NormalButton
                label="Save"
                mainbg={true}
                onClick={() => this.handleFormSubmit(addModalType)}
              />
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      pincodemaster,
      districtList,
      fileUpload,
      addbusinessActivity,
      addCrops,
      stateList,
      addDistrict,
      addfinancialDetails,
      addeducationQualification,
      addfinancialYear,
      addinfraDetails,
      addlicenceDetails,
      addliveStockDetails,
      addState,
      addAssetDetails,
      addkeyfinancialDetails,
      addinsititutionDetails,
      addKycDocumentDetails,
    },
    dispatch
  );
};

export const MastersList = connect(null, mapDispatchToProps)(MastersClass);
