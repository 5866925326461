import React from 'react'
import Dropzone from 'react-dropzone'
import { Toast } from "service/toast";
import "./style.scss"

export const DragFileUploadForCompliance = ({ children, className, disabled = false, handleFileUpload = '', acceptFileFormat = ['xlsx'], type = 'image' }) => {

    return (
        <Dropzone
            disabled={disabled}
            onDrop={(files, a) => {
                if (files.length) {
                    if (files[0].size < 100 * 1024 * 1024) {

                        if (!checkFileValidation(files, acceptFileFormat)) {

                            Toast({ type: 'error', message: `Please upload ${acceptFileFormat.join('/')} File` })

                            return ''
                        }

                        handleFileUpload(files)

                        uploadFile(files).then((res) => {

                            if (handleFileUpload) {

                                handleFileUpload(res.data)

                            }

                        })
                    } else {
                        Toast({ type: 'error', message: `Please upload ${acceptFileFormat.join('/')} within 100mb` })
                    }



                } else {
                    Toast({ type: 'error', message: `Please upload ${type} within 10mb` })
                }

            }}>

            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={`${className} position-relative `}>

                    {children}

                    <input {...getInputProps()} />
                </div>

            )}
        </Dropzone >
    )
}

const uploadFile = ([file]) => {

    return new Promise((resolve, reject) => {

        let formData = new FormData()

        formData.append('key1', file)

    })
}


const checkFileValidation = (files, validationType) => {

    return files.every((file) => {

        let temp = file.name.split('.')

        let type = temp[temp.length - 1]

        return validationType.indexOf(type) != -1

    })

}