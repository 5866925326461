import React, { Component } from "react";

import { bindActionCreators } from "redux";

import { connect } from "react-redux";
import { TableWrapper } from "component/common";

import {
  getRequestFormList,
  requestFormRejectUser,
  requestFormAcceptUser,
} from "action/dashboard";
import "./requestFormList.scss";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

export class RequestFormListClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerDetails: [
        {
          label: "User name",
        },
        {
          label: "Role",
        },
        {
          label: "FPO Name",
        },
        {
          label: "Email id",
        },
        {
          label: "Mobile number",
        },
        {
          label: "Action",
        },
      ],
      pageMeta: {},
      courseManagement: [],
      isShowDetalModal: false,
    };
  }

  handleRequestFormList = (page) => {
    this.props
      .getRequestFormList({ limit: 10, page: page.page })
      .then(({ data: { results, total, pageMeta } }) => {
        this.setState({ courseManagement: results, pageMeta });
      });
  };

  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
    this.setState({ isloading: true });
    this.handleRequestFormList({ page: 1 });
  }

  handleRejectUser = (i, type) => {
    let { courseManagement } = this.state;
    let {
      userMasterId,
      userRoles: {
        userGroup: { userGroupName },
      },
    } = courseManagement[i];
    let body = {
      userMasterId,
      userGroupName,
    };
    let apiCall =
      type === "accept"
        ? this.props.requestFormAcceptUser(body)
        : this.props.requestFormRejectUser(body);
    apiCall.then(() => {
      this.handleRequestFormList({ page: 1 });
    });
  };
  render() {
    let { headerDetails, courseManagement, pageMeta } = this.state;
    return (
      <div className="requestFormList">
        <div className="d-flex justify-content-between align-items-center view-course-management-heading">
          <div className="d-flex cursor-pointe back-arear my-2">
            <h5 className="heading-20">Request form </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-containermt-4 pt-1">
              <TableWrapper
                headerDetails={headerDetails}
                queryHandler={this.handleRequestFormList}
                pageMeta={pageMeta}
              >
                {courseManagement.map(
                  (
                    {
                      userName,
                      userRoles: { userRoleName },
                      emailId,
                      userMasterId,
                      mobileNumber,
                      fpousers,
                    },
                    index
                  ) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{userName}</td>
                          <td>{userRoleName}</td>
                          <td align="center">
                            {fpousers ? fpousers.fpoName : "-"}
                          </td>
                          <td>{emailId}</td>
                          <td>{mobileNumber}</td>
                          <td>
                            <div className="d-flex align-items-center h-100">
                              <span
                                className=" mr-3 cursor-pointer approve-text"
                                onClick={() =>
                                  this.handleRejectUser(index, "accept")
                                }
                              >
                                Approve
                              </span>
                              <span
                                className=" mr-3 cursor-pointer approve-text text-danger"
                                onClick={() =>
                                  this.handleRejectUser(index, "reject")
                                }
                              >
                                Reject
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr className="trans-bg">
                          <td className="empty-height" height="12"></td>
                        </tr>
                      </React.Fragment>
                    );
                  }
                )}
              </TableWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRequestFormList,
      requestFormRejectUser,
      requestFormAcceptUser,
    },
    dispatch
  );
};

export const RequestFormList = connect(
  null,
  mapDispatchToProps
)(RequestFormListClass);
