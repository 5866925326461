import React, { Component } from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import SimpleReactValidator from "simple-react-validator";

import { Modal, ModalBody } from "reactstrap";

import { Toast } from "service/toast";

import { history } from "service/helpers";

import { fileUpload } from "action/CourseAct";

export class CourseMasterClass extends Component {
  state = {
    mastersList: [
      {
        name: "Author",
      },
      {
        name: "Certifying institute",
      },
    ],
    isAddNewModal: false,
    addModalType: "",
    isauthorProfileUploading: false,
    authorDetail: {
      authorName: "",
      authorProfileUrl: "",
      authorProfileFileName: "",
    },
  };

  render() {
    const { mastersList } = this.state;

    return (
      <>
        <div className="masters-component mt-4">
          <div className="row">
            {mastersList.map(({ name }, index) => (
              <div
                className="col-md-3 text-center mb-4"
                key={index}
                onClick={() =>
                  name === "Author"
                    ? history.push("/admin/courseManagement/author")
                    : history.push("/admin/courseManagement/certifingList")
                }
              >
                <div className="card">
                  <div className="card-body">
                    <img
                      className="card-img-top mb-2"
                      src="https://gatewaycdn.azureedge.net/images/25-License-1-e15880132889192018.png"
                      alt=""
                    />
                    <h5 className="card-title">{name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
    },
    dispatch
  );
};

let component = CourseMasterClass;

export const CourseMaster = connect(null, mapDispatchToProps)(component);
