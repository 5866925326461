import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { TableWrapper, CommonPageLoader, NormalButton } from "component/common";
import { getDescriptionData } from "service/helperFunctions";
import { FeedbackDetails } from "./FeedbackDetails/FeedbackDetails";
import { getFeedbackList, getFeedbackById } from "action/FeedbackAct";
import moment from "moment";

export class FeedbackListClass extends Component {
  state = {
    headerDetails: [
      {
        label: "Date & Time",
      },
      {
        label: "Name",
      },
      {
        label: "Phone number",
      },
      {
        label: "Email id",
      },
      {
        label: "Feedback",
      },
      {
        label: "Action",
      },
    ],
    pageMeta: {},
    feedbackList: [],
    isloading: false,
    isShowFeedbackDetails: false,
    feedbackData: {},
  };

  handleForumsDetails = (body) => {
    let { isShowFeedbackDetails } = this.state;
    isShowFeedbackDetails === true
      ? this.setState((prevState) => ({
          isShowFeedbackDetails: false,
        }))
      : this.props.getFeedbackById(body).then((data) => {
          this.setState({
            feedbackData: {
              name: data.userDetails.data.userName,
              designation: data.contactNo,
              feedbackTitle: data.improvementsText,
              feedbackContent: data.feedbackText,
              sentOn: moment(data.createdAt).format("DD/MM/YYYY"),
            },
            isShowFeedbackDetails: true,
          });
        });
  };

  //get feedback list
  _getFeedbackList = (page) => {
    this.props.getFeedbackList({ page }).then((data) => {
      let results = data.results;
      let dataSet = [];
      results.map((result) => {
        dataSet.push({
          feedbackId: result.feedbackId,
          date: result.createdAt,
          name: result.userDetails.userName,
          designation: result.contactNo,
          email: result.emailId,
          feedback_title: result.feedbackText,
          feedback_content: result.improvementsText,
        });
      });
      this.setState({
        pageMeta: data.pageMeta,
        feedbackList: dataSet,
        isloading: false,
      });
    });
  };
  handlePageChange = (page) => {
    // page change
    this.setState({ isloading: true });
    this._getFeedbackList(page);
  };

  //life cycles
  componentDidMount() {
    this.setState({ isloading: true });
    this._getFeedbackList(1);
  }

  render() {
    const {
      headerDetails,
      pageMeta,
      feedbackList,
      isloading,
      isShowFeedbackDetails,
      feedbackData,
    } = this.state;
    return (
      <div className="faq-list">
        <div className="d-flex justify-content-between align-items-center faq-list-heading">
          <div className="">
            <h5 className="heading-20">Feedbacks</h5>
          </div>
        </div>
        {isloading ? (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        ) : (
          <div className="table-container w-100 mt-4 pt-1">
            {feedbackList.length != 0 ? (
              <TableWrapper
                headerDetails={headerDetails}
                queryHandler={this.handlePageChange}
                pageMeta={pageMeta}
              >
                {feedbackList.map((item, index) => {
                  let {
                    date,
                    name,
                    designation,
                    email,
                    feedback_content,
                    feedbackId,
                  } = item;

                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>{moment(date).format("DD/MM/YYYY | hh:mm")}</td>
                        <td>{name}</td>
                        <td>{designation ? designation : "-"}</td>
                        <td>{email ? email : "-"}</td>
                        <td>{getDescriptionData(feedback_content, 15)}</td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            <i
                              className="icon-eye mr-3 cursor-pointer fs-20"
                              onClick={() => {
                                this.handleForumsDetails({
                                  id: feedbackId,
                                });
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                      <tr className="trans-bg">
                        <td className="empty-height" height="12"></td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </TableWrapper>
            ) : (
              <div className="text-center fs-18 text-black py-5">
                No records found !!!
              </div>
            )}
          </div>
        )}
        <FeedbackDetails
          show={isShowFeedbackDetails}
          detailModalToggle={this.handleForumsDetails}
          feedbackData={feedbackData}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFeedbackById,
      getFeedbackList,
    },
    dispatch
  );
};

let component = FeedbackListClass;

export const FeedbackList = connect(null, mapDispatchToProps)(component);
