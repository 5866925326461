import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, ModalBody } from 'reactstrap';
import sampleImage from 'assets/images/sampleImage.png';
import moment from 'moment';
import './popup.scss';

export class DetailPopupClass extends Component {
   render() {
      let {
         className = 'modal-dialog-centered content-mangement',
         isOpen = true,
         toggle,
         title,
         details,
      } = this.props;
      //   let {
      //      newsTitle,
      //      publishOn,
      //      uploadImage,
      //      fileName,
      //      description,
      //      source,
      //      sourceHyperlink,
      //   } = details;
      return (
         <>
            <Modal isOpen={isOpen} toggle={toggle} className={className}>
               <ModalBody className='p-0'>
                  <div className='d-flex justify-content-between pb-2'>
                     <h5 className='px-0 pb-1 text-green-18 fs-26 font-Gilroy-Bold'>
                        {title}
                     </h5>
                     <i
                        className='icon-close fs-20 cursor-pointer'
                        onClick={toggle}
                     ></i>
                  </div>
                  {details.profileDetails === undefined ? (
                     <p className='fs-26 mb-2 line-height-35 font-Gilroy-Medium text-black-30'>
                        {details.title}
                     </p>
                  ) : (
                     ''
                  )}
                  {details.date !== undefined ? (
                     <p className='text-left text-black mb-4 fs-16 font-Gilroy line-height-19'>
                        {details.date}
                     </p>
                  ) : (
                     ''
                  )}
                  {details.profileDetails !== undefined ? (
                     <>
                        <div className='d-flex profile-details-area'>
                           <div>
                              <img
                                 src={details.profileDetails.uploadImage}
                                 alt=''
                                 className='profile-image'
                              />
                           </div>
                           <div className='d-flex flex-column ml-4 pl-1 justify-content-center'>
                              <h5 className='mb-2 fs-32 line-height-36 font-Gilroy-Medium text-black-30'>
                                 {details.profileDetails.name}
                              </h5>
                              <p className='designation mb-0'>
                                 {details.profileDetails.designation}
                              </p>
                           </div>
                        </div>
                        <div>
                           <p className='fs-24 pb-1 mb-2 line-height-29 font-Gilroy-Medium text-black-30'>
                              {details.title}
                           </p>
                        </div>
                     </>
                  ) : (
                     <img
                        src={details.uploadImage}
                        className='mb-4 uploaded-image'
                        alt=''
                     />
                  )}
                  {/* <p className='news-description mb-4'>{details.description}</p> */}
                  <p
                     className='news-description mb-4'
                     dangerouslySetInnerHTML={{ __html: details.description }}
                  ></p>
                  {details.subtitles.map((data) => (
                     <div className='row'>
                        <div className='col-3'>
                           <h5 className='mb-4 sub-title'>{data.title}</h5>
                        </div>
                        <div className='col-9'>
                           {data.content !== undefined ? (
                              <p className='mb-4 sub-title-content'>
                                 {data.content}
                              </p>
                           ) : (
                              data.schedules.map((list, index) =>
                                 data.title == 'Agenda of the event' ? (
                                    <div
                                       className='mb-4 sub-title-content'
                                       dangerouslySetInnerHTML={{
                                          __html: list.content,
                                       }}
                                    ></div>
                                 ) : (
                                    <p
                                       className={`${
                                          index == data.schedules.length - 1
                                             ? 'mb-4'
                                             : 'mb-3'
                                       } sub-title-content `}
                                    >
                                       {list.content}
                                    </p>
                                 )
                              )
                           )}
                        </div>
                     </div>
                  ))}
               </ModalBody>
            </Modal>
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators({}, dispatch);
};

let component = DetailPopupClass;

export const DetailPopup = connect(null, mapDispatchToProps)(component);
