import React, { Component } from 'react';

import { connect } from 'react-redux';

import { TableWrapper, CommonPageLoader, DeletePopup } from 'component/common';

import moment from 'moment';

import { history } from 'service/helpers';
import { bindActionCreators } from 'redux';
import {
   getAllStories,
   getStoriesById,
   deleteStoriesById,
} from 'action/ContentAct';

import { DetailPopup } from 'component/contentManagement/Modal';
import { getDescriptionData } from 'service/helperFunctions';

export class SuccessStoriesListClass extends Component {
   state = {
      headerDetails: [
         {
            label: 'Success stories id',
         },
         {
            label: 'Success stories image',
         },
         {
            label: 'Title',
         },
         {
            label: 'Author',
         },
         {
            label: 'Publication date',
         },
         {
            label: 'Action',
         },
      ],
      pageMeta: {},
      isloading: false,
      contentManagement: [],
      isSuccessStoryView: false,
      successStoryDetails: {
         title: 'Lorum ipsum dolor sit amet consecuter do',
         date: '25/09/2020',
         description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non commodo quam venenatis, libero libero. Quisque Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non commodo quam venenatis, libero mauris odio. Leo placerat fermentum sagittis, libero. Quisque ',
         subtitles: [
            { title: 'Author', content: 'Rajiv Gupta' },
            { title: 'Publication date', content: '15/09/2020' },
         ],
      },
      isDeletePopup: false,
      successId: ''
   };

   toggle = (name, id) => {
      this.state.isSuccessStoryView
         ? this.setState((prevState) => ({
            [name]: !prevState[name],
         }))
         : this.props.getStoriesById(id).then((data) => {
            let { title, uploadImage, description, author, publishOn } = data;
            this.setState((prevState) => ({
               successStoryDetails: {
                  title: title,
                  date: moment(publishOn).format('DD-MM-YYYY'),
                  description: description,
                  uploadImage: uploadImage,
                  subtitles: [
                     { title: 'Author', content: author },
                     {
                        title: 'Publication date',
                        content: moment(publishOn).format('DD-MM-YYYY'),
                     },
                  ],
               },
               [name]: !prevState[name],
            }));
         });
   };

   handleAllStories = (page) => {
      this.setState({ isloading: true });
      this.props.getAllStories({ limit: 10, page: page }).then((data) => {
         this.setState({
            contentManagement: data.results,
            pageMeta: data.pageMeta,
            isloading: false,
         });
      });
   };

   //handle delete
   handleDelete = () => {
      let { successId } = this.state
      this.props.deleteStoriesById(successId).then(() => {
         this.setState({ successId: '' })
         this.handleAllStories(1);
         this.toggleDelete('isDeletePopup')
      });
   };

   toggleDelete = (name, id) => {
      this.setState((prevState) => ({
         [name]: !prevState[name],
         successId: id
      }));
   };

   //lifecycles
   componentDidMount() {
      this.setState({ isloading: true });
      this.handleAllStories({ page: 1 });
   }
   render() {
      const {
         headerDetails,
         pageMeta = {},
         contentManagement,
         isSuccessStoryView,
         successStoryDetails,
         isloading,
         isDeletePopup
      } = this.state;

      return (
         <>
            {isloading ? (
               <>
                  {/* Loader */}
                  <CommonPageLoader />
               </>
            ) : (
                  <div className='table-container w-100 mt-4 pt-1'>
                     {contentManagement.length != 0 ? (
                        <TableWrapper
                           headerDetails={headerDetails}
                           queryHandler={this.handleAllStories}
                           pageMeta={pageMeta}
                        >
                           {contentManagement.map((item, index) => {
                              let {
                                 title,
                                 author,
                                 publishOn,
                                 successId,
                                 uploadImage = '',
                              } = item;

                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {successId}
                                      </div>
                                    </td>
                                    <td>
                                      <img
                                        src={uploadImage}
                                        alt="image"
                                        className="image-preview"
                                      />
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <p className="mb-0">
                                          {getDescriptionData(title, 30)}
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {author}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {moment(publishOn).format("DD/MM/YYYY")}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <i
                                          className="icon-eye mr-3 cursor-pointer fs-20"
                                          onClick={() => {
                                            this.toggle(
                                              "isSuccessStoryView",
                                              successId
                                            );
                                          }}
                                        ></i>
                                        <i
                                          className="icon-edit mr-3 fs-20 cursor-pointer"
                                          onClick={() => {
                                            history.push(
                                              `editstories/${successId}`
                                            );
                                          }}
                                        ></i>
                                        <i
                                          className="icon-Delete fs-20 cursor-pointer"
                                          onClick={() => {
                                            this.toggleDelete(
                                              "isDeletePopup",
                                              successId
                                            );
                                          }}
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="trans-bg">
                                    <td
                                      className="empty-height"
                                      height="12"
                                    ></td>
                                  </tr>
                                </React.Fragment>
                              );
                           })}
                        </TableWrapper>
                     ) : (
                           <div className='text-center fs-18 text-black py-5'>
                              No records found !!!
                           </div>
                        )}
                  </div>
               )}
            <DetailPopup
               title='Success stories details'
               details={successStoryDetails}
               isOpen={isSuccessStoryView}
               toggle={() => this.toggle('isSuccessStoryView')}
            />
            <DeletePopup
               isOpen={isDeletePopup}
               toggle={() => this.toggleDelete('isDeletePopup')}
               handleChange={() => this.handleDelete()}
            />
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         getAllStories,
         getStoriesById,
         deleteStoriesById,
      },
      dispatch
   );
};

let component = SuccessStoriesListClass;

export const SuccessStoriesList = connect(null, mapDispatchToProps)(component);
