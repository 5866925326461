import React from "react";

import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export const NormalEditor = ({
  label = "",
  onChange,
  data = "",
  required = false,
}) => {
  return (
    <div className="" style={{ zIndex: 0 }}>
      {/* {label !== "" ? (
        <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">
          {label}
          {required && <span className="text-danger pl-2 fs-18">*</span>}
        </div>
      ) : (
        ""
      )} */}
      <CKEditor
        editor={DecoupledEditor}
        data={data}
        onInit={(editor) => {
          editor.setData(data);

          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            );
        }}
        onChange={(event, editor) => {
          onChange(editor);
        }}
        config={{
          toolbar: [
            "heading",
            "|",
            "Bold",
            "Italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "Link",
            "|",
            "Indent",
            "outdent",
            "|",
            "undo",
            "redo",
          ],
        }}
      />
    </div>
  );
};
