import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ModalBody } from "reactstrap";
import "./popup.scss";

export class ReportDetailPopupClass extends Component {
  openPdfInNewTab = () => {
    const { details } = this.props;
    window.open(details?.uploadDoc);
  };

  render() {
    let {
      className = "modal-dialog-centered content-mangement",
      isOpen = true,
      toggle,
      title,
      details,
    } = this.props;

    return (
      <>
        <Modal isOpen={isOpen} toggle={toggle} className={className}>
          <ModalBody className="p-0">
            <div className="d-flex justify-content-between pb-2">
              <h5 className="px-0 pb-1 text-green-18 fs-26 font-Gilroy-Bold">
                {title}
              </h5>
              <i
                className="icon-close fs-20 cursor-pointer"
                onClick={toggle}
              ></i>
            </div>
            <p className="fs-26 mb-2 line-height-35 font-Gilroy-Medium text-black-30">
              {details?.reportTitle}
            </p>
            <span
              className="news-description mb-0"
              dangerouslySetInnerHTML={{ __html: details?.description }}
            ></span>
            {details?.reportType === "text" && (
              <span
                className="news-description mb-0"
                dangerouslySetInnerHTML={{ __html: details?.content }}
              ></span>
            )}
            <img
              src={details?.uploadImage}
              className="mb-4 uploaded-image"
              alt="uploadImg"
            />
            {details?.reportType === "document" && <p>{details?.fileName}</p>}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

let component = ReportDetailPopupClass;

export const ReportDetailPopup = connect(null, mapDispatchToProps)(component);
