import React, { Component } from 'react';
import Select from 'react-select';
import './select.scss';

export class CustomSelect extends Component {
   //change select
   handleChange = (newValue) => {
      let body = {
         target: {
            name: this.props.name,
            value: [],
         },
      };
      if (!!newValue && newValue.length) {
         newValue.forEach((array) => {
            let obj = {
               value: array.value,
               label: array.label,
            };
            body.target.value.push(obj);
         });
      }
      this.props.onChange(body);
   };


   render() {
      const customStyles = {
         control: (base) => ({
            ...base,
            height: 10,
            minHeight: 10,
         }),
         dropdownIndicator: base => ({
            ...base,
            color: "#000000"
         })
      };

      let {
         className = '',
         options = [],
         name = '',
         disabled = false,
         placeholder = '',
         value = [],
      } = this.props;

      return (
         <>
            <Select
               className={className}
               classNamePrefix='Select'
               isDisabled={disabled}
               isClearable={false}
               isSearchable={true}
               name={name}
               options={options}
               onChange={this.handleChange}
               isMulti={true}
               placeholder={placeholder}
               styles={customStyles}
               value={value}
            />
         </>
      );
   }
}
