import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Addcontent } from "component/contentManagement/Addcontent/Addcontent";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

export class AddContentClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <Addcontent match={this.props.match} />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

let component = AddContentClass;

export const AddContent = connect(null, mapDispatchToProps)(component);
