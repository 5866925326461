import React, { Component } from "react";
import { Authorlist } from "../../../component/courseManagement/index";
import { connect } from "react-redux";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

class AuthorListClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <Authorlist match={this.props.match} />;
  }
}

export const AuthorList = connect(null, null)(AuthorListClass);
