import React, { Component } from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import {
  NormalInput,
  NormalButton,
  NormalSelect,
  NormalCheckbox,
  NormalEditor,
  NormalTextarea,
  NormalDate,
  CommonPageLoader,
} from "component/common";

import { BlogPreviewPopup } from "../BlogPreviewPopup";

import { history } from "service/helpers";

import SimpleReactValidator from "simple-react-validator";

import moment from "moment";
import { getDescriptionData } from "service/helperFunctions";

import {
  getCategoryList,
  fileUpload,
  multipleImageUpload,
  addBlog,
  getBlogDetail,
  updateBlog,
  courseManagementTab,
} from "action/CourseAct";

import { Toast } from "service/toast";

import "./AddBlog.scss";

export class AddblogClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleEvent: {
        save: 0,
        share: 0,
        like: 0,
        dislike: 0,
        download: 0,
      },
      fileName: "",
      isImageUploading: false,
      formFields: {
        title: "",
        description: "",
        link: "",
        author: "",
        tags: "",
        publishDate: "",
        category: "",
        shortDescription: "",
        blogImageFileUrl: "",
        imageUrl: [],
      },
      categoryOptions: [],
      isLoading: false,
      ispreviewPopup: false,
    };
  }

  //validation
  validator = new SimpleReactValidator({
    validators: {
      checkUrl: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  //LifeCycles
  componentDidMount = () => {
    let { getCategoryList, match = {} } = this.props;

    //get category list
    getCategoryList().then((data) => {
      let categoryList = [];
      data.map((list) => {
        categoryList.push({
          label: list.categoryName,
          value: list.courseCategoryId,
        });
      });
      this.setState({ categoryOptions: categoryList });
    });

    let {
      params: { id },
    } = match;

    if (id != undefined) {
      //get blog details

      this._getBlogDetails(id);
    }
  };

  _getBlogDetails = (blogId) => {
    //get blog details

    let { getBlogDetail } = this.props;

    this.setState({ isLoading: true });

    getBlogDetail({ blogId: blogId }).then((data) => {
      let {
        description,
        title,
        link,
        categoryId,
        tags,
        author,
        blogImageFileName,
        blogImageFileUrl,
        isDisliked,
        isDownloaded,
        isLiked,
        isSaved,
        isShared,
        shortDescription,
        publishDate,
        imageUrl,
      } = data;

      this.setState({
        formFields: {
          title,
          description,
          link: link,
          author,
          tags,
          category: categoryId,
          shortDescription,
          publishDate: publishDate ? new Date(publishDate) : new Date(),
          blogImageFileUrl,
          imageUrl: imageUrl ? imageUrl : [],
        },
        fileName: blogImageFileName,
        toggleEvent: {
          save: isSaved ? 1 : 0,
          share: isShared ? 1 : 0,
          like: isLiked ? 1 : 0,
          dislike: isDisliked ? 1 : 0,
          download: isDownloaded ? 1 : 0,
        },
        isLoading: false,
      });
    });
  };

  handleInput = ({ target: { name, value } }) => {
    //handle inputs

    let { formFields } = this.state;

    formFields[name] = value;

    this.setState({
      formFields,
    });
  };

  uploadSingleFile = (e) => {
    //upload image
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ blogImageFileUrl: "" }, () => {
        let { formFields, fileName } = this.state;
        fileName = "Uploading ....";
        let fileData = [];
        fileData.push(fileData);
        formFields.blogImageFileUrl = fileData;
        this.setState({ isImageUploading: true });

        const config = {
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            document.getElementById(
              "image-upload"
            ).innerText = `Uploading ${percentCompleted}%`;
          },
        };

        const formData = new FormData();
        formData.append("file", file);

        this.props.fileUpload(formData, config).then((data) => {
          if (data !== undefined) {
            formFields.blogImageFileUrl = data[0].fileUrl;
            fileName = data[0].fileName;
            this.setState({
              formFields,
              isImageUploading: false,
              fileName,
            });
          } else {
            formFields.blogImageFileUrl = "";
            this.setState({
              formFields,
              isImageUploading: false,
              fileName: "",
            });
          }
        });
        this.setState({
          formFields,
          fileName,
        });
      });
    }
  };

  uploadBlogImg = (event) => {
    const { formFields } = this.state;
    let formData = new FormData();
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      formData.append("file", file);
    }
    this.props.multipleImageUpload(formData).then((data) => {
      let imgUrl = formFields.imageUrl;
      data.map((value) => {
        imgUrl.push(value.fileUrl);
      });
      formFields.blogUrl = imgUrl;
      this.setState({
        formFields: formFields,
      });
    });
  };

  handleToggle = ({ target: { name, value } }) => {
    let toggleEvent = Object.assign({}, this.state.toggleEvent);
    toggleEvent[name] = value ? 1 : 0;
    this.setState({
      toggleEvent,
    });
  };

  onEditorChange = (editor) => {
    let { formFields } = this.state;
    formFields.description = editor.getData();
    this.setState({
      formFields,
    });
  };

  handleSubmit = () => {
    let { isImageUploading, formFields, fileName, toggleEvent } = this.state;

    let { match = {}, updateBlog, addBlog } = this.props;

    let {
      params: { id },
    } = match;

    if (this.validator.allValid()) {
      if (!isImageUploading) {
        let { save, share, like, dislike, download } = toggleEvent;

        let {
          title,
          description,
          link,
          author,
          tags,
          category,
          shortDescription,
          publishDate,
          blogImageFileUrl,
          imageUrl,
        } = formFields;

        let body = {
          title: title,
          description: description,
          blogImageFileName: fileName,
          blogImageFileUrl: blogImageFileUrl,
          link: link,
          tags: tags,
          author: author,
          shortDescription: shortDescription,
          categoryId: parseInt(category),
          isSaved: save == 0 ? false : true,
          isShared: share == 0 ? false : true,
          isDownloaded: download == 0 ? false : true,
          isLiked: like == 0 ? false : true,
          isDisliked: dislike == 0 ? false : true,
          publishDate: moment(publishDate).format("YYYY-MM-DD HH:MM:SS"),
          imageUrl: imageUrl,
        };

        if (!id) {
          addBlog(body).then(() => {
            this.handleBack();
          });
        } else {
          body.blogId = parseInt(id);

          updateBlog(body).then(() => {
            this.handleBack();
          });
        }
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handlePreview = () => {
    let { isImageUploading } = this.state;

    if (this.validator.allValid()) {
      if (!isImageUploading) {
        this.setState({ isImageUploading: false });
        this.togglePreview("ispreviewPopup");
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  togglePreview = (name) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  handleBack = () => {
    history.goBack();
    this.validator.hideMessages();
    this.props.courseManagementTab(3);
  };

  render() {
    let {
      formFields,
      categoryOptions,
      toggleEvent,
      fileName,
      isImageUploading,
      isLoading,
      ispreviewPopup,
    } = this.state;

    let { save, share, like, dislike, download } = toggleEvent;

    let {
      title,
      description,
      link,
      author,
      tags,
      category,
      shortDescription,
      publishDate,
      blogImageFileUrl,
      imageUrl,
    } = formFields;

    let { match = {} } = this.props;

    let {
      params: { id },
    } = match;

    return (
      <div className="add-blog">
        <div
          className="d-flex cursor-pointer pt-2 mt-1 mb-4 back-area"
          onClick={() => this.handleBack()}
        >
          <i className="icon-arrow-left fs-24"></i>
          <h5 className="heading-20 pl-4">{id ? "Edit" : "Add"} new blog</h5>
        </div>
        {!isLoading ? (
          <>
            <div className="add-blog-form">
              <h5 className="fs-19 text-black font-Gilroy-Bold line-height-22 mb-0 mt-2">
                Blog details
              </h5>
              <div className="row">
                <div className="col-6">
                  <NormalInput
                    label="Blog title"
                    type="text"
                    onChange={(e) => this.handleInput(e)}
                    value={title}
                    placeholder="Enter title"
                    name="title"
                    required={true}
                  />
                  {this.validator.message("title", title, "required|max:100")}
                </div>
                <div className="col-6">
                  <NormalSelect
                    label={"Category"}
                    placeholder="select"
                    value={category}
                    arrow={true}
                    name="category"
                    options={categoryOptions}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  {this.validator.message("category", category, "required")}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <NormalTextarea
                    label="Short description"
                    onChange={(e) => this.handleInput(e)}
                    value={shortDescription}
                    placeholder="Enter here"
                    name="shortDescription"
                    required={true}
                  />
                  {this.validator.message(
                    "shortDescription",
                    shortDescription,
                    "required|max:500"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <NormalEditor
                    label="Blog content"
                    data={description}
                    onChange={this.onEditorChange}
                    required={true}
                  />
                  {this.validator.message(
                    "description",
                    description,
                    "required"
                  )}
                </div>
                <div className="col-12">
                  <div className="mb-2 mt-4 pt-2">
                    <div className="fs-17 text-black font-Gilroy pb-3">
                      Upload Image
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className=""
                          accept="image/*"
                          multiple={true}
                          onChange={this.uploadBlogImg}
                        ></input>
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {imageUrl.length != 0 && (
                <div className="blog-image-preview">
                  {imageUrl.map((list, index) => {
                    return <img src={list} key={index} alt="" />;
                  })}
                </div>
              )}
              <div className="row">
                <div className="col-6">
                  <NormalInput
                    label="Author"
                    type="text"
                    onChange={(e) => this.handleInput(e)}
                    value={author}
                    placeholder="Enter author name"
                    name="author"
                    required={true}
                  />
                  {this.validator.message("author", author, "required|max:50")}
                </div>
                <div className="col-6">
                  <NormalDate
                    value={publishDate}
                    name="publishDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={true}
                    maxdate={false}
                    timeInput={false}
                    label={"Publication date"}
                    required={true}
                  />
                  {this.validator.message(
                    "publishDate",
                    publishDate,
                    "required"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalInput
                    label="Source link"
                    type="text"
                    onChange={(e) => this.handleInput(e)}
                    value={link}
                    placeholder="Paste link here"
                    name="link"
                    isLink={true}
                    required={true}
                  />
                  {this.validator.message("link", link, "required|checkUrl")}
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Tags/ labels"
                    type="text"
                    onChange={(e) => this.handleInput(e)}
                    value={tags}
                    placeholder="Enter here"
                    name="tags"
                    required={true}
                  />
                  {this.validator.message("tags", tags, "required|max:100")}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-2 mt-4 pt-2">
                    <div className="fs-17 text-black font-Gilroy pb-3">
                      Upload blog image
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className=""
                          accept="image/*"
                          onChange={this.uploadSingleFile}
                        ></input>
                        {isImageUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : fileName == "" ? (
                          "Upload"
                        ) : (
                          getDescriptionData(fileName, 20)
                        )}
                      </button>
                      {blogImageFileUrl != "" && !isImageUploading ? (
                        <div className="ml-4">
                          <img
                            src={blogImageFileUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.validator.message(
                      "file",
                      blogImageFileUrl,
                      "required"
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 my-4 py-2">
                  <div className="fs-17 text-black font-Gilroy pb-3 mb-1">
                    {"Viewers able to"}
                  </div>
                  <div className="row">
                    <div className="col">
                      <NormalCheckbox
                        value=""
                        label="Save"
                        name="save"
                        checked={save}
                        onChange={(event) => this.handleToggle(event)}
                      />
                    </div>
                    <div className="col">
                      <NormalCheckbox
                        value=""
                        label="Share"
                        name="share"
                        checked={share}
                        onChange={(event) => this.handleToggle(event)}
                      />
                    </div>
                    <div className="col">
                      <NormalCheckbox
                        value=""
                        label="Download"
                        name="download"
                        checked={download}
                        onChange={(event) => this.handleToggle(event)}
                      />
                    </div>
                    <div className="col">
                      <NormalCheckbox
                        value=""
                        label="Like"
                        name="like"
                        checked={like}
                        onChange={(event) => this.handleToggle(event)}
                      />
                    </div>
                    <div className="col">
                      <NormalCheckbox
                        value=""
                        label="Dislike"
                        name="dislike"
                        checked={dislike}
                        onChange={(event) => this.handleToggle(event)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center add-blog-buttons">
              <div className="ml-auto d-flex">
                <NormalButton
                  label="Cancel"
                  outline={true}
                  className="px-4 mr-2 border-none"
                  onClick={() => history.goBack()}
                />
                <NormalButton
                  label={id ? "Update" : "Publish"}
                  mainbg={true}
                  className="px-4 mr-2"
                  onClick={this.handleSubmit.bind(this, true)}
                />
                <NormalButton
                  label={"Preview"}
                  outline={true}
                  className="px-4"
                  onClick={this.handlePreview}
                />
              </div>
            </div>
          </>
        ) : (
          <CommonPageLoader />
        )}
        <BlogPreviewPopup
          isOpen={ispreviewPopup}
          toggle={() => this.togglePreview("ispreviewPopup")}
          formFields={formFields}
          toggleEvent={toggleEvent}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCategoryList,
      fileUpload,
      addBlog,
      getBlogDetail,
      updateBlog,
      courseManagementTab,
      multipleImageUpload,
    },
    dispatch
  );
};

let component = AddblogClass;

export const Addblog = connect(null, mapDispatchToProps)(component);
