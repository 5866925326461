import React, { Component } from 'react';

import { connect } from 'react-redux';

import FPOImage from "assets/svg/FPOImage.svg";
import courseViewers from "assets/svg/courseViewers.svg";
import courseShareinHours from "assets/svg/courseShareinHours.svg";
import certifyingInstitute from "assets/svg/certifyingInstitute.svg";
import usersImages from "assets/svg/usersImages.svg";
import authors from "assets/svg/authors.svg";


import "./countInforOverAll.scss";

export class AcademyContentsClass extends Component {

    render() {
        let { acadamyDetail } = this.props
        let {
            noOfFpoUser = 0,
            noOfAuthor = 0,
            noOfCertificatesIssued = 0,
            noOfCertifyingInstitute = 0,
            noOfUsers = 0,
            viewedIn24hrs = 0,
            noOfCoursesSharedIn24hrs = 0,
            noOfCoursesShared = 0
        } = acadamyDetail
        return (
            <div className="row">
                <div className="col-3 mb-4">
                    <div className="dashboard-small-card">
                        <div className="d-flex justify-content-between align-items-end">
                            <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">{noOfUsers}</p>
                            <img src={usersImages} height="53" width="54" alt="fpo" />
                        </div>
                        <div>
                            <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">Number of users</p>
                        </div>
                    </div>
                </div>
                <div className="col-3 mb-4">
                    <div className="dashboard-small-card">
                        <div className="d-flex justify-content-between align-items-end">
                            <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">{noOfFpoUser}</p>
                            <img src={FPOImage} height="53" width="54" alt="fpo" />
                        </div>
                        <div>
                            <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">Number of users from FPO</p>
                        </div>
                    </div>
                </div>
                <div className="col-3 mb-4">
                    <div className="dashboard-small-card">
                        <div className="d-flex justify-content-between align-items-end">
                            <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">{viewedIn24hrs}</p>
                            <img src={courseViewers} height="53" width="54" alt="fpo" />
                        </div>
                        <div>
                            <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">Course viewers in last 24 hours</p>
                        </div>
                    </div>
                </div>
                <div className="col-3 mb-4">
                    <div className="dashboard-small-card">
                        <div className="d-flex justify-content-between align-items-end">
                            <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">{noOfCoursesShared}</p>
                            <img src={courseShareinHours} height="53" width="54" alt="fpo" />
                        </div>
                        <div>
                            <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">Course shared</p>
                        </div>
                    </div>
                </div>
                <div className="col-3 mb-4">
                    <div className="dashboard-small-card">
                        <div className="d-flex justify-content-between align-items-end">
                            <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">{noOfCoursesSharedIn24hrs}</p>
                            <img src={courseShareinHours} height="53" width="54" alt="fpo" />
                        </div>
                        <div>
                            <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">Course shared in last 24 hours</p>
                        </div>
                    </div>
                </div>
                <div className="col-3 mb-4">
                    <div className="dashboard-small-card">
                        <div className="d-flex justify-content-between align-items-end">
                            <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">{noOfCertificatesIssued}</p>
                            <img src={certifyingInstitute} height="53" width="54" alt="fpo" />
                        </div>
                        <div>
                            <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">Certificates Issued</p>
                        </div>
                    </div>
                </div>
                <div className="col-3 mb-4">
                    <div className="dashboard-small-card">
                        <div className="d-flex justify-content-between align-items-end">
                            <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">{noOfCertifyingInstitute}</p>
                            <img src={certifyingInstitute} height="53" width="54" alt="fpo" />
                        </div>
                        <div>
                            <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">Certifying institues</p>
                        </div>
                    </div>
                </div>
                <div className="col-3 mb-4">
                    <div className="dashboard-small-card">
                        <div className="d-flex justify-content-between align-items-end">
                            <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">{noOfAuthor}</p>
                            <img src={authors} height="53" width="54" alt="fpo" />
                        </div>
                        <div>
                            <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">Authors on academy</p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export const AcademyContents = connect(null, null)(AcademyContentsClass)