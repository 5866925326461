import React, { Component } from 'react';

import { connect } from 'react-redux';

import "./employeeGender.scss";

export class EmployeeGenderClass extends Component {
    render() {
        let { dashboardDetails } = this.props
        let {
            female = 0,
            male = 0,
        } = dashboardDetails
        return (
            <div className="card employeeGender">
                <div className="card-body">
                    <h5 className="card-title fs-24 font-Gilroy-Bold line-height-28 text-green-18">Employee gender</h5>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <img src="/images/maleFarmers.svg" alt="" />
                            <label className="title">Male farmers</label>
                            <label className="count">{male}</label>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <img src="/images/femaleFarmers.svg" alt="" />
                            <label className="title">Female farmers</label>
                            <label className="count">{female}</label>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export const EmployeeGender = connect(null, null)(EmployeeGenderClass)