import React, { Component } from "react";
import "./AddSuccessStories.scss";
import { connect } from "react-redux";
import { NormalInput, NormalButton, NormalDate } from "component/common";

import moment from "moment";

import { history } from "service/helpers";

import { bindActionCreators } from "redux";

import SimpleReactValidator from "simple-react-validator";

import { fileUpload } from "action/CourseAct";

import { Toast } from "service/toast";

import {
  addStories,
  getStoriesById,
  updateStories,
  contentManagementTab,
} from "action/ContentAct";

export class AddSuccessStoriesClass extends Component {
  constructor(props) {
    super();
    this.state = {
      fileName: "",
      fileArray: [],
      formFields: {
        title: "",
        publishDate: "",
        description: "",
        author: "",
      },
      isImageUploaded: false,
      isImageUploading: false,
    };
  }

  //Validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-16 pb-3">{message}</span>
    ),
  });

  componentDidMount() {}

  handleInput = ({ target: { name, value } }) => {
    let { formFields } = this.state;

    formFields[name] = value;

    this.setState({
      formFields,
    });
  };

  uploadSingleFile = (e) => {
    //upload image
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ fileArray: [] }, () => {
        let { fileArray, fileName } = this.state;
        fileName = "Uploading ....";
        let fileData = [];
        fileData.push(fileData);
        fileArray = fileData;
        this.setState({ isImageUploading: true });

        const config = {
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            document.getElementById(
              "image-upload"
            ).innerText = `Uploading ${percentCompleted}%`;
          },
        };

        const formData = new FormData();
        formData.append("file", file);

        this.props.fileUpload(formData, config).then((data) => {
          if (data !== undefined) {
            let uploadedFile = [];
            uploadedFile.push(data[0].fileUrl);
            fileArray = uploadedFile;
            fileName = data[0].fileName;
            this.setState({
              fileArray,
              isImageUploading: false,
              fileName,
            });
          } else {
            this.setState({
              fileArray: [],
              isImageUploading: false,
              fileName: "",
            });
          }
        });
        this.setState({
          fileArray,
          isImageUploaded: false,
          fileName,
        });
      });
    }
  };

  onEditorChange = (editor) => {
    let { formFields } = this.state;
    formFields.description = editor.getData();
    this.setState({
      formFields,
    });
  };

  handleSubmit = () => {
    let { fileArray, formFields, isImageUploading, fileName } = this.state;

    if (this.validator.allValid() && fileArray.length != 0) {
      if (!isImageUploading) {
        this.setState({ isImageUploading: false });

        let { title, publishDate, description, author } = formFields;

        let body = {
          successId: this.props.match.params.id,
          title: title,
          description: description,
          publishOn: moment(publishDate).format("YYYY-MM-DD"),
          author: author,
          uploadImage: fileArray.length != 0 ? fileArray[0] : "",
          fileName: fileName,
        };
        this.props.match.params.id === undefined
          ? this.props.addStories(body).then(() => {
              this.handleBack();
            })
          : this.props.updateStories(body).then(() => {
              this.handleBack();
            });
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      if (fileArray.length == 0) {
        this.setState({ isImageUploaded: true });
      }
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.props.getStoriesById(this.props.match.params.id).then((data) => {
        this.setState({
          fileName: data.fileName,
          fileArray: [data.uploadImage],
          isImageUploaded: false,
          formFields: {
            title: data.title,
            publishDate: new Date(data.publishOn),
            description: data.description,
            author: data.author,
          },
        });
      });
    }
  }

  handleBack = () => {
    history.goBack();
    this.validator.hideMessages();
    this.props.contentManagementTab(4);
  };
  render() {
    let {
      formFields,
      isImageUploaded,
      fileName,
      fileArray,
      isImageUploading,
    } = this.state;

    let { title, publishDate, description, author } = formFields;

    let { match } = this.props;

    return (
      <div className="add-success-stories">
        <div
          className="d-flex cursor-pointer py-2 mt-1 mb-4 back-area"
          onClick={() => this.handleBack()}
        >
          <i className="icon-arrow-left fs-24"></i>
          <h5 className="heading-20 ml-1 pl-3 mb-0">
            {match.params.id === undefined ? "Add" : "Edit"} success stories
          </h5>
        </div>
        <div className="success-stories-details">
          <div className="">
            <h3 className="success-stories-heading mb-0">
              Success story details
            </h3>
          </div>
          <div className="">
            <div className="row">
              <div className="col-12">
                <NormalInput
                  label="Title"
                  type="text"
                  onChange={this.handleInput}
                  value={title}
                  placeholder="Enter here"
                  name="title"
                  required={true}
                />
                {this.validator.message("title", title, "required|max:100")}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput
                  label="Description"
                  type="text"
                  onChange={this.handleInput}
                  value={description}
                  placeholder="Enter here"
                  required={true}
                  name="description"
                />
                {this.validator.message(
                  "description",
                  description,
                  "required|max:700"
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <NormalDate
                  value={publishDate}
                  name="publishDate"
                  placeholder="DD/MM/YYYY"
                  onChange={this.handleInput}
                  mindate={true}
                  maxdate={false}
                  timeInput={false}
                  label={"Publication date"}
                  required={true}
                />
                {this.validator.message("publishDate", publishDate, "required")}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Author"
                  type="text"
                  onChange={this.handleInput}
                  value={author}
                  placeholder="Enter here"
                  name="author"
                  required={true}
                />
                {this.validator.message("author", author, "required|max:30")}
              </div>
            </div>
            <div className="mb-2 mt-4 pt-2">
              <div className="fs-17 text-black font-Gilroy pb-4">
                Upload image <span className="text-danger pl-2 fs-18">*</span>
              </div>
              <div className="d-flex align-items-center w-100 btn-file-input">
                <button className="">
                  <input
                    type="file"
                    className="h-100"
                    accept="image/*"
                    onChange={this.uploadSingleFile}
                  ></input>
                  {isImageUploading ? (
                    <div
                      id="image-upload"
                      className="d-flex align-items-center"
                    ></div>
                  ) : fileName == "" ? (
                    "Upload"
                  ) : (
                    fileName
                  )}
                </button>
                {fileArray.length != 0 && !isImageUploading ? (
                  <div className="ml-4">
                    <img
                      src={fileArray[0]}
                      alt=""
                      className="upload-image-preview"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {isImageUploaded ? (
                <span className="error-message text-danger fs-16 pb-3">
                  Image field is required
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center add-success-stories-buttons">
          <div className="ml-auto d-flex">
            <NormalButton
              label="Cancel"
              outline={true}
              className="px-4 mr-2 border-none"
              onClick={() => history.goBack()}
            />
            <NormalButton
              label={match.params.id === undefined ? "Publish" : "Update"}
              mainbg={true}
              className="px-4"
              onClick={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
      addStories,
      getStoriesById,
      updateStories,
      contentManagementTab,
    },
    dispatch
  );
};

let component = AddSuccessStoriesClass;

export const AddSuccessStories = connect(null, mapDispatchToProps)(component);
