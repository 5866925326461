import React, { Component } from "react";
import { RoleManagementList } from "component/RoleManagement";
import { connect } from "react-redux";
class RoleManagementClass extends Component {
  render() {
    return <RoleManagementList history={this.props.history} />;
  }
}

export const RoleManagement = connect(null, null)(RoleManagementClass);
