import React, { Component } from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import {
  NormalInput,
  NormalButton,
  DragFileUpload,
  NormalTimeInput,
  NormalEditor,
  NormalSelect,
  NormalDate,
  CommonPageLoader,
} from "component/common";

import { VideoPreviewPopup } from "../VideoPreviewPopup";

import { history } from "service/helpers";

import uploaderFile from "assets/images/video-upload.png";

import SimpleReactValidator from "simple-react-validator";

import { displayImg } from "service/helperFunctions";
import { getDescriptionData } from "service/helperFunctions";

import "./addVideo.scss";

import {
  addTrendingVideo,
  getCategoryList,
  fileUpload,
  getVideoDetail,
  updateVideo,
  courseManagementTab,
} from "action/CourseAct";
import { Toast } from "service/toast";

import moment from "moment";

export class AddvideoClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        title: "",
        description: "",
        video: [],
        durationSeconds: "",
        durationHours: "",
        durationMinutes: "",
        fileType: "",
        fileName: "",
        category: "",
        thumbnailImageUrl: "",
        thumbfileName: "",
        publishDate: "",
      },
      isImageUploading: false,
      isFileUploading: false,
      categoryOptions: [],
      isFileUploaded: false,
      isLoading: false,
      ispreviewPopup: false,
    };
  }

  //validation
  validator = new SimpleReactValidator({
    validators: {
      checkHours: {
        message: "Select Valid Hours",
        rule: (val, params, validator) => {
          let selected_minutes = this.state.formFields.durationHours;
          if (selected_minutes > 23) {
            return false;
          } else {
            return true;
          }
        },
      },
      checkMinutes: {
        message: "Select Valid Minutes",
        rule: (val, params, validator) => {
          let selected_minutes = this.state.formFields.durationMinutes;
          if (selected_minutes > 59) {
            return false;
          } else {
            return true;
          }
        },
      },
      checkSeconds: {
        message: "Select Valid Seconds",
        rule: (val, params, validator) => {
          let selected_seconds = this.state.formFields.durationSeconds;
          if (selected_seconds > 59) {
            return false;
          } else {
            return true;
          }
        },
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs-16 pb-3">{message}</span>
    ),
  });
  //LifeCycles
  componentDidMount = () => {
    let { getCategoryList, match = {} } = this.props;

    getCategoryList().then((data) => {
      let categoryList = [];
      data.map((list) => {
        categoryList.push({
          label: list.categoryName,
          value: list.courseCategoryId,
        });
      });
      this.setState({ categoryOptions: categoryList });
    });

    let {
      params: { id },
    } = match;

    if (id != undefined) {
      //get video details

      this._getVideoDetails(id);
    }
  };

  _getVideoDetails = (videoId) => {
    //get blog details

    let { getVideoDetail } = this.props;

    this.setState({ isLoading: true });

    getVideoDetail({ videoId: videoId }).then((data) => {
      let {
        description,
        title,
        categoryId,
        trendingvideoFileName,
        trendingvideoFileUrl,
        publishDate,
        durationMinutes,
        durationHours,
        durationSeconds,
        thumbnailImageUrl,
        thumbnailImageName,
      } = data;

      let file = [];
      file.push(trendingvideoFileUrl);

      this.setState({
        formFields: {
          title,
          description,
          video: file,
          publishDate: publishDate ? new Date(publishDate) : new Date(),
          durationSeconds,
          durationHours,
          durationMinutes,
          fileType: "video",
          fileName: trendingvideoFileName,
          category: categoryId,
          thumbnailImageUrl: thumbnailImageUrl,
          thumbfileName: thumbnailImageName,
        },
        isLoading: false,
      });
    });
  };

  handleInput = ({ target: { name, value } }) => {
    //handle inputs

    let { formFields } = this.state;

    formFields[name] = value;

    this.setState({
      formFields,
    });
  };

  handleFileInput = ({ target: { name, value } }) => {
    //handle inputs file
    let { formFields } = this.state;

    if (value !== undefined) {
      //uploading loader
      this.setState({ isImageUploading: true });

      formFields.fileType = value.type;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "video-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", value);

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          if (formFields.fileType.includes("video")) {
            formFields["video"][0] = data[0].fullUrl;
            formFields.fileName = data[0].fileName;
          } else {
            formFields["video"][0] = data[0].fileUrl;
            formFields.fileName = data[0].fileName;
          }
          this.setState({
            formFields,
            isImageUploading: false,
          });
        } else {
          formFields.videos = [];
          formFields.fileName = "";
          this.setState({
            formFields,
            isImageUploading: false,
          });
        }
      });
    }
  };

  onEditorChange = (editor) => {
    let { formFields } = this.state;
    formFields.description = editor.getData();
    this.setState({
      formFields,
    });
  };

  uploadSingleFile = (e) => {
    //upload image
    let file = e.target.files[0];
    let { formFields } = this.state;
    if (file !== undefined) {
      formFields.thumbnailImageUrl = "";
      this.setState({ formFields }, () => {
        formFields.thumbfileName = "Uploading ....";
        formFields.thumbnailImageUrl = file;

        const config = {
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            document.getElementById(
              "image-upload"
            ).innerText = `Uploading ${percentCompleted}%`;
          },
        };

        const formData = new FormData();
        formData.append("file", file);
        this.setState({ isFileUploading: true });

        this.props.fileUpload(formData, config).then((data) => {
          if (data !== undefined) {
            formFields.thumbnailImageUrl = data[0].fileUrl;
            formFields.thumbfileName = data[0].fileName;
            this.setState({
              formFields,
              isFileUploading: false,
            });
          } else {
            formFields.thumbnailImageUrl = "";
            formFields.thumbfileName = "";
            this.setState({
              formFields,
              isFileUploading: false,
            });
          }
        });
        this.setState({
          formFields,
          isFileUploaded: false,
        });
      });
    }
  };

  handleSubmit = () => {
    let { isImageUploading, formFields, isFileUploading } = this.state;

    if (this.validator.allValid()) {
      let { addTrendingVideo, updateVideo, match = {} } = this.props;

      if (!isImageUploading && !isFileUploading) {
        let {
          params: { id },
        } = match;

        let {
          title,
          description,
          video,
          publishDate,
          durationSeconds,
          durationHours,
          durationMinutes,
          fileName,
          category,
          thumbnailImageUrl,
          thumbfileName,
        } = formFields;

        let body = {
          categoryId: category,
          title: title,
          description: description,
          publishDate: moment(publishDate).format("YYYY-MM-DD"),
          durationHours: durationHours,
          durationMinutes: durationMinutes,
          durationSeconds: durationSeconds,
          trendingvideoFileUrl: video[0],
          trendingvideoFileName: fileName,
          thumbnailImageUrl,
          thumbnailImageName: thumbfileName,
        };
        if (!id) {
          //Add new video

          addTrendingVideo(body).then(() => {
            this.handleBack();
          });
        } else {
          //Update video

          body.trendingvideoId = parseInt(id);

          updateVideo(body).then(() => {
            this.handleBack();
          });
        }
      } else {
        Toast({ type: "error", message: "Please wait video is uploading" });
      }
    } else {
      if (formFields.thumbnailImageUrl == "") {
        this.setState({ isFileUploaded: true });
      }
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleBack = () => {
    history.goBack();
    this.validator.hideMessages();
    this.props.courseManagementTab(2);
  };

  handlePreview = () => {
    let { isImageUploading, formFields, isFileUploading } = this.state;

    if (this.validator.allValid()) {
      if (!isImageUploading && !isFileUploading) {
        this.togglePreview("ispreviewPopup");
      } else {
        Toast({ type: "error", message: "Please wait video is uploading" });
      }
    } else {
      if (formFields.thumbnailImageUrl == "") {
        this.setState({ isFileUploaded: true });
      }
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  togglePreview = (name) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  render() {
    let {
      formFields,
      categoryOptions,
      isImageUploading,
      isFileUploading,
      isFileUploaded,
      isLoading,
      ispreviewPopup,
    } = this.state;

    let {
      title,
      description,
      video,
      durationSeconds,
      durationHours,
      durationMinutes,
      fileType,
      category,
      thumbfileName,
      thumbnailImageUrl,
      publishDate,
    } = formFields;

    let { match = {} } = this.props;

    let {
      params: { id },
    } = match;

    return (
      <div className="add-video">
        <div
          className="d-flex cursor-pointer pt-2 mt-1 mb-4 back-area"
          onClick={() => this.handleBack()}
        >
          <i className="icon-arrow-left fs-24"></i>
          <h5 className="heading-20 pl-4">{id ? "Edit" : "Add"} new videos</h5>
        </div>

        {isLoading ? (
          <div className="add-video-form">
            <CommonPageLoader />
          </div>
        ) : (
          <>
            <div className="add-video-form">
              <h5 className="fs-19 text-black font-Gilroy-Bold line-height-22 mb-0 mt-2">
                {"Video details"}
              </h5>
              <div className="row">
                <div className="col-12">
                  <NormalInput
                    label="Title"
                    type="text"
                    onChange={(e) => this.handleInput(e)}
                    value={title}
                    placeholder="Enter title"
                    name="title"
                    required={true}
                  />
                  {this.validator.message("title", title, "required|max:100")}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <NormalEditor
                    label="Description"
                    data={description}
                    onChange={this.onEditorChange}
                    required={true}
                  />
                  {this.validator.message(
                    "description",
                    description,
                    "required|max:750"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="mt-4 py-2">
                    <div className="fs-17 text-black font-Gilroy pb-3">
                      Upload video
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <DragFileUpload
                      className={`file-uploader ${
                        video.length !== 0 ? "" : "no-img"
                      }`}
                      acceptFileFormat={["MP4", "mp4", "webm", "WEBM", "ogg"]}
                      handleFileUpload={([file]) =>
                        this.handleFileInput({
                          target: { name: "video", value: file },
                        })
                      }
                    >
                      {isImageUploading ? (
                        <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100">
                          <div id="video-upload"></div>
                        </div>
                      ) : (
                        <>
                          {video.length !== 0 ? (
                            fileType.includes("video") ? (
                              <div className="row">
                                <div
                                  className="justify-content-center align-items-center d-flex"
                                  onMouseEnter={() => this.refs.vidRef.play()}
                                  onMouseLeave={() => this.refs.vidRef.pause()}
                                >
                                  <video
                                    height="150"
                                    width="300"
                                    ref="vidRef"
                                    src={video[0]}
                                  ></video>
                                </div>
                              </div>
                            ) : (
                              <img src={displayImg(video[0])} />
                            )
                          ) : (
                            <div className="uploader-content text-center">
                              <img src={uploaderFile} alt="image" />
                              <span className="pt-2 mt-1">
                                Drag & drop to upload files
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    </DragFileUpload>
                    {this.validator.message("Video", video[0], "required")}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalSelect
                    label={"Category"}
                    placeholder="select"
                    value={category}
                    arrow={true}
                    name="category"
                    options={categoryOptions}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  {this.validator.message("category", category, "required")}
                </div>
                <div className="col-6">
                  <NormalDate
                    value={publishDate}
                    name="publishDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={true}
                    maxdate={false}
                    timeInput={false}
                    label={"Publication date"}
                    required={true}
                  />
                  {this.validator.message(
                    "publishDate",
                    publishDate,
                    "required"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-6 duration-area">
                  <div className="fs-16 text-black font-Gilroy pb-2">
                    Total duration{" "}
                    <span className="text-danger pl-2 fs-18">*</span>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <NormalTimeInput
                        type="number"
                        onChange={this.handleInput}
                        value={durationHours}
                        placeholder="00  Hrs"
                        name="durationHours"
                      />
                      {this.validator.message(
                        "durationHours",
                        durationHours,
                        "required|min:1|max:2|checkHours"
                      )}
                    </div>
                    <div className="col-4">
                      <NormalTimeInput
                        type="number"
                        onChange={this.handleInput}
                        value={durationMinutes}
                        placeholder="00  Min"
                        name="durationMinutes"
                      />
                      {this.validator.message(
                        "durationMinutes",
                        durationMinutes,
                        "required|min:1|max:2|checkMinutes"
                      )}
                    </div>
                    <div className="col-4">
                      <NormalTimeInput
                        type="number"
                        onChange={this.handleInput}
                        value={durationSeconds}
                        placeholder="00  Sec"
                        name="durationSeconds"
                      />
                      {this.validator.message(
                        "durationSeconds",
                        durationSeconds,
                        "required|min:1|max:2|checkSeconds"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-4 pt-2">
                  <div className="mb-2">
                    <div className="fs-17 text-black font-Gilroy pb-3">
                      Upload thumbnail image
                      <span className="text-danger pl-2 fs-18">*</span>
                    </div>
                    <div className="d-flex align-items-center w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className="h-100"
                          accept="image/*"
                          onChange={this.uploadSingleFile}
                        ></input>
                        {isFileUploading ? (
                          <div
                            id="image-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : thumbfileName == "" ? (
                          "Upload"
                        ) : (
                          getDescriptionData(thumbfileName, 20)
                        )}
                      </button>
                      {thumbnailImageUrl != "" && !isFileUploading ? (
                        <div className="ml-4">
                          <img
                            src={thumbnailImageUrl}
                            alt=""
                            className="upload-image-preview"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {isFileUploaded ? (
                      <span className="error-message text-danger fs-16 pb-3">
                        Image field is required
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center add-video-buttons">
              <div className="ml-auto d-flex">
                <NormalButton
                  label="Cancel"
                  outline={true}
                  className="px-4 mr-2 border-none"
                  onClick={() => history.goBack()}
                />
                <NormalButton
                  label={id ? "Update" : "Publish"}
                  mainbg={true}
                  className="px-4 mr-2"
                  onClick={this.handleSubmit.bind(this, true)}
                />
                <NormalButton
                  label={"Preview"}
                  outline={true}
                  className="px-4"
                  onClick={this.handlePreview}
                />
              </div>
            </div>
          </>
        )}
        <VideoPreviewPopup
          isOpen={ispreviewPopup}
          toggle={() => this.togglePreview("ispreviewPopup")}
          formFields={formFields}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addTrendingVideo,
      getCategoryList,
      fileUpload,
      getVideoDetail,
      updateVideo,
      courseManagementTab,
    },
    dispatch
  );
};

let component = AddvideoClass;

export const Addvideo = connect(null, mapDispatchToProps)(component);
