import React, { Component } from "react";
import { AllContent } from "../../../component/contentManagement";
import { connect } from "react-redux";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";
import { bindActionCreators } from "redux";
class ContentManagementClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <AllContent history={this.props.history} />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const ContentManagement = connect(
  null,
  mapDispatchToProps
)(ContentManagementClass);
