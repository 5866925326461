import React, { useState } from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import {
  DashboardHome,
  DashboardGraph,
  DashboardBusiness,
  DashboardTwo,
} from "component/pages";
import "./Dashboard.scss";

const headerDetails = [
  { title: "Summary" },
  { title: "Trend Graphs" },
  { title: "Business" },
  // { title: "Lorem2" },
];

const DashboardClass = () => {
  const [activeTab, setActiveTab] = useState("Summary");
  return (
    <div className="dashboard">
      <div className="common-navlink">
        <div className="filled-tabs">
          <div className="tabs-block">
            <Nav tabs>
              {headerDetails.map((item, index) => {
                return (
                  <NavItem>
                    <NavLink
                      key={index}
                      className={classnames({
                        active: activeTab === item?.title,
                      })}
                      onClick={() => {
                        setActiveTab(item?.title);
                      }}
                    >
                      {item?.title}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </div>
        </div>
      </div>
      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="Summary">
            <DashboardHome homeActiveTab={activeTab} />
          </TabPane>
          <TabPane tabId="Trend Graphs">
            <DashboardGraph homeActiveTab={activeTab} />
          </TabPane>
          <TabPane tabId="Business">
            <DashboardBusiness homeActiveTab={activeTab} />
          </TabPane>
          {/* <TabPane tabId="Lorem2">
            <DashboardTwo />
          </TabPane> */}
        </TabContent>
      </div>
    </div>
  );
};

let component = DashboardClass;
export const Dashboard = component;

// export class DashboardClass extends Component {
//   state = {
//     dashboardDetails: {},
//     acadamyDetail: {},
//     userDetails: {},
//     isLoading: false,
//     roboCallRequest: 0,
//   };

//   componentDidMount() {
//     let status = getSubAdmin();
//     if (status) {
//       logout();
//     }
//     this._getDashboardDetails();
//     this.roboCallRequestCount();
//   }

//   roboCallRequestCount = () => {
//     const { getRoboCallRequestCount } = this.props;
//     getRoboCallRequestCount().then(({ data: { requestRobocall } }) => {
//       this.setState({
//         roboCallRequest: requestRobocall,
//       });
//     });
//   };

//   _getDashboardDetails = () => {
//     let {
//       getDashboardDetails,
//       getDashboardDetailsforAcadamy,
//       getDashboardDetailsforuser,
//     } = this.props;
//     this.setState({ isLoading: true });

//     getDashboardDetails().then(({ data }) => {
//       this.setState({
//         dashboardDetails: data,
//       });
//       getDashboardDetailsforAcadamy().then(({ data }) => {
//         this.setState({
//           acadamyDetail: data,
//         });

//         getDashboardDetailsforuser().then(({ data }) => {
//           this.setState({
//             userDetails: data,
//             isLoading: false,
//           });
//         });
//       });
//     });
//   };

//   render() {
//     let {
//       dashboardDetails,
//       isLoading,
//       acadamyDetail,
//       userDetails,
//       roboCallRequest,
//     } = this.state;

//     return (
//       <div className="dashboard">
//         {isLoading ? (
//           <>
//             {/* Loader */}
//             <CommonPageLoader />
//           </>
//         ) : (
//           <>
//             <h4 className="fs-33 font-Gilroy-Bold line-height-30 text-green-18 mb-2 pb-1">
//               Hello Admin{" "}
//             </h4>
//             <div className="row">
//               <div className="col-md-9 col-xs-12">
//                 <div className="row">
//                   <div className="col-md-12">
//                     <CountInfoOverAll dashboardDetails={dashboardDetails} />
//                   </div>
//                   <div className="col-md-12">
//                     <TopPerfomance />
//                   </div>
//                   <div className="col-md-6">
//                     <RequestForms
//                       userDetails={userDetails}
//                       roboCallRequest={roboCallRequest}
//                     />
//                   </div>
//                   <div className="col-md-6">
//                     <EmployeeGender dashboardDetails={dashboardDetails} />
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-3">
//                 <AcademyCourse acadamyDetail={acadamyDetail} />
//               </div>
//             </div>
//           </>
//         )}
//       </div>
//     );
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     {
//       getDashboardDetails,
//       getDashboardDetailsforAcadamy,
//       getDashboardDetailsforuser,
//       getRoboCallRequestCount,
//     },
//     dispatch
//   );
// };

// let component = DashboardClass;

// export const Dashboard = connect(null, mapDispatchToProps)(component);
