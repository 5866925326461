import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
   Modal,
   ModalBody,
} from 'reactstrap';
import {
   getDescriptionData,
   letterAvatar,
} from 'service/helperFunctions';
import { getForumComments } from 'action/forumAct';
import { CommentCard } from './CommentCard';
import './forumsDetail.scss';
import moment from "moment"
export class ForumsDetailClass extends Component {
   constructor(props) {
      super(props);
      this.state = {
         readMore: false,
         isCommentsLoading: false,
         commentsList: [],
      };
   }

   handleGetComments = () => {
      this.setState({
         isCommentsLoading: true,
      });
      this.props
         .getForumComments(this.props.forumData.id, {
            limit: 20,
            page: 1,
         })
         .then(({ data }) => {
            this.setState({
               commentsList: data.results,
               isCommentsLoading: false,
            });
         })
         .catch(() => {
            // this.setState({ isFormLoder: false });
         });
   };

   toggleRead = () => {
      this.setState({
         readMore: !this.state.readMore,
      });
   };
   componentDidMount() {
      this.handleGetComments();
   }

   render() {
      let { show, detailModalToggle, forumData } = this.props;
      const {
         title,
         content,
         images,
         userDetails: { userName },
         createdAt,
         createdBy,
      } = forumData;
      const {
         readMore,
         isCommentsLoading,
         commentsList,
      } = this.state;

      return (
         <Modal isOpen={show} className='forums-detail'>
            <div className='modal-header'>
               <h5 className='modal-title mb-0'>Forum details</h5>
               <div>
                  <i className="icon-close fs-20 cursor-pointer" onClick={() => detailModalToggle()}></i>
               </div>
            </div>
            <ModalBody>
               <div className='row'>
                  <div className='col-md-12 user-profile'>
                     <div className='media'>
                        <img
                           className='mr-3'
                           src={letterAvatar(userName, 30)}
                           alt=''
                        />
                        <div className='media-body'>
                           <h5 className='mt-0'>
                              {userName} .{' '}
                              {`${moment(createdAt, "YYYY-MM-DD HH:mm:ss").toNow(true)} ago`}{' '}
                           </h5>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='content-detail'>
                  <div className='row '>
                     <div className='col-md-12 '>
                        <h4 className='content-title'>{title}</h4>
                     </div>
                     <div className='col-md-12 '>
                        <label className='content-sub'>
                           <span dangerouslySetInnerHTML={{
                              __html: !readMore ? getDescriptionData(content, 200) : content,
                           }} ></span>
                           {content.length > 200 ?
                              <span className='text-green cursor-pointer fw-700' onClick={this.toggleRead}  >
                                 {!readMore ? 'read more' : 'read less'}
                              </span>
                              : null}
                        </label>
                     </div>
                  </div>
                  <div className='row '>
                     <div className='col-md-12 '>
                        <img
                           className='mr-3 blog-img'
                           src={images.length > 0 ? images[0].url : '/images/forumDetails.svg'}
                           alt=''
                        />
                     </div>
                  </div>
               </div>
               {isCommentsLoading ? (
                  <div className='d-flex justify-content-center align-items-center py-3'>
                     <p className='fs-18 text-black-50 line-height-23 mb-0'>
                        Loading
                     </p>
                     <div className='spinner-border ml-2 spinner-border-sm'></div>
                  </div>
               ) : (
                     <div className='comments-section'>
                        <div className='row '>
                           <div className='col-md-12 comment-scroll'>
                              {commentsList.map((comment, index) => {
                                 return (
                                    <div key={index} className='row'>
                                       <CommentCard comment={comment} />
                                    </div>
                                 );
                              })}
                           </div>
                        </div>
                     </div>
                  )}
            </ModalBody>
         </Modal>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         getForumComments,
      },
      dispatch
   );
};

let component = ForumsDetailClass;

export const ForumsDetail = connect(null, mapDispatchToProps)(component);
